import React, { useState, useRef, useEffect, useCallback } from "react";
import { fabric } from "fabric-with-erasing";
import "bootstrap/dist/css/bootstrap.css";
import SiteNav from "./components/SiteNav";
import ItemSelection from "./components/ItemSelection";
// import AccessoriesSelection from "./components/accessories/AccessoriesSelection";
import ShapeSelection from "./components/ShapeSelection";
import ZoomAndPan from "./components/zoomAndPan/ZoomAndPan";
import UploadImage from "./components/uploadImage/UploadImage";
import { signInWithEmailAndPassword } from "firebase/auth"; // Import Firebase auth functions
import Auth from "./components/auth/Auth";
import Account from "./components/account/Account";
import CanvasPopup from "./components/canvasPopup/CanvasPopup";
import { useAuth } from "./base";
import ItemColors from "./components/ItemColors";
import CustomPresetColors from "./components/colors/CustomPresetColors";
import Grids from "./components/options/grids/Grids";
import ToolsSelection from "./components/tools/ToolsSelection";
import EraserDraw from "./components/eraserDraw/EraserDraw";
import Calibration from "./components/calibration/Calibration";
import CalibrationDisplay from "./components/calibration/CalibrationDisplay";
import TransumSelectionView from "./components/options/transums/TransumSelectionView";
import $ from "jquery";
import ItemSelectionView from "./components/itemSelectionView/ItemSelectionView";
import AccessoriesSelectionView from "./components/accessories/AccessoriesSelectionView";
import OptionsSelectionView from "./components/optionsSelectionView/OptionsSelectionView";
import DoorsSelectionView from "./components/doorsSelectionView/DoorsSelectionView";
import addTransum from "./otherJS/HandleTransums";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { availableLanguages, } from './i18nextConf';
import { customProps } from "./helpers/helpers";
import {
  saveCanvasState,
  undo,
  redo,
  removeAllObjects,
  removeAllObjects2,
  fadeObjects,
  changeAction,
  enableDraw,
  enableEraser
} from "./helpers/helpers";
import { setObjectPerspective, getRelatedObject } from './helpers/fabricPerspective';
import {
  unGroupItems,
  groupItems,
  resetZoom,
  panZoomOut,
  panZoomIn,
  goLeft,
  goRight,
  goUp,
  goDown,
  downloadpng,
  objectfliphorizontal,
  objectflipvertical,
  cutItem,
  copyItem,
  pasteItem,
  copyAndPasteItem,
} from "./helpers/helpers";
import sizeAndPosition from "./otherJS/doorsSizeAndPosition";
import { addDoors } from "./components/doors/handleDoors";
import { addGarageDoors } from "./components/doors/handleGarageDoors";
import { addWindows } from "./components/windows/handleWindows";
import Home from "./components/homepage/Home";
// import PerspectiveTransformEditor from "./components/perspectiveEditor/PerspectiveTransformEditor";
// import PerspectiveTransformEditor from "./components/perspectiveEditor/ModalWithThreeJS";
// import BackgroundImageEdit from "./components/backgroundImageEdit/BackgroundImageEDit";
// import ImageEditor from "./components/imageEditor/ImageEditor";
// import EditItems from "./components/editItems/EditItems";
import Resizer from 'react-image-file-resizer';
import {
  db,
  getStorage,
  ref,
  storage,
  uploadString,
  uploadBytes,
  getDownloadURL,
  auth,
  logout
} from "./base";
import {
  addDoc, collection, updateDoc, doc, getDoc, query,
  where, getDocs, arrayUnion
} from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import useContextMenu from "./hooks/useContextMenu";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import SaveIcon from "@mui/icons-material/Save";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import "./index.css";
import "./mediaqueries.css";
import Banner1 from "./components/banners/Banner1";
import NewsBanner from "./components/banners/NewsBanner";
//consent and terms
import CombinedTermsOfUse from "./components/legal/CombinedTermsOfUse";
import CookieConsent from "react-cookie-consent";
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import CombinedCookiePolicy from "./components/legal/CombinedCookiePolicy";
import ContentModal from './components/contentModal/ContentModal';
//email confirm
import EmailConfirmationSuccessModal from './components/emailConfirmation/EmailConfirmationSuccessModal';
//chat
import FacadeDesigner from './components/designer/FacadeDesigner';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import ContactFormModal from './ContactFormModal';
import { Aod } from "@mui/icons-material";
import CustomTooltip from './components/toolTips/CustomToolTip';
import AccessoriesSelection from "./components/accessories/AccessoriesSelection";
import addAccessoryImage from "./components/accessories/addAccessoryImage";
//AI selection
import { triggerDetection } from './components/AI/aiDetections';
import { CanvasTooltip } from './components/toolTips/CanvasToolTip'; // Adjust the import path as needed
import SecondaryNav from "./components/secondaryNav/SecondaryNav";
import ScrollToTopButton from "./components/homepage/ScrollToTopButton";
import { FaSpinner } from 'react-icons/fa';
import LoadingToast from "./components/toasts/LoadingToast";
import { handleAnalyticsEvent } from "./helpers/analyticsHandler";

import { colorList } from "./components/colors/colorHelper"
import UserNoticeModal from './components/userMessage/UserNoticeModal';  // Import the modal component
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';





let optionUrl = "";
// let itemColor = "rgba(0, 0, 0, 1)";

const App = () => {
  const { t, i18n } = useTranslation(["translation"]);

  const mainCanvasRef = useRef(null);
  const topBarRef = useRef(null);
  const menuRef = useRef(null);

  const currentUser = useAuth();
  const [canvas, setCanvas] = useState("");
  const [canvasProject, setCanvasProject] = useState("");
  const [upload, setUpload] = useState(false);
  const [uploadImageUrl, setUploadImageUrl] = useState(null);
  // const [auth, setAuth] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [login, setLogin] = useState(false);
  const [isLoginOut, setIsLoginOut] = useState(false);
  const [account, setAccount] = useState(false);
  const [isSelectedItem, setIsSelectedItem] = useState(false);
  const [typeName, setTypeName] = useState("");
  const [itemColor, setItemColor] = useState("rgba(0, 0, 0, 1)");
  const [canvasProjectImage, setCanvasProjectImage] = useState("");
  const [projectName, setProjectName] = useState("");
  const [imageName, setImageName] = useState("");
  const [newProjectCreationName, setNewProjectCreationName] = useState("");
  const [projectId, setProjectId] = useState(null);
  const [windowURL, setWindowURL] = useState(""); // window or door url
  const [windowGridType, setWindowGridType] = useState(""); // option type
  const [isSameType, setIsSameType] = useState(true);
  const [isMixMatched, setIsMixMatched] = useState(false);
  const [skewX, setSkewX] = useState(0);
  const [skewY, setSkewY] = useState(0);
  const [isSkewVisible, setIsSkewVisible] = useState(false);
  const [skewSliderLeft, setSkewSliderLeft] = useState("160px");
  const [skewSliderTop, setSkewSliderTop] = useState("65px");
  const [isObjSelected, setIsObjSelected] = useState(false);
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [isAccessoriesPanelVisible, setIsAccessoriesPanelVisible] = useState(false);
  const [isOptionsPanelVisible, setIsOptionsPanelVisible] = useState(false);
  const [isTransumPanelVisible, setIsTransumPanelVisible] = useState(false);
  const [isAvaillableDoorsPanelVisible, setIsAvaillableDoorsPanelVisible] =
    useState(false);
  const [itemToSearch, setItemToSearch] = useState("");
  const [isProjectList, setIsProjectList] = useState(false);

  const [isSelectedShape, setIsSelectedShape] = useState(false);
  const [glassToSearch, setGlassToSearch] = useState(""); //size of glass
  const [stringToSearch, setStringToSearch] = useState(""); //door name and type
  const [stringToSearchSide, setStringToSearchSide] = useState(""); //side name
  const [isItemAdded, setIsItemAdded] = useState(false);
  const [backgroundImageStorageUrl, setBackgroundImageStorageUrl] =
    useState("");
  const [canvasImageName, setCanvasImageName] = useState("");
  const [isTransum, setIsTransum] = useState(false);
  const [isLoaderFinish, setIsLoaderFinish] = useState(false);

  const [selectedObject, setSelectedObject] = useState(null);

  const [showEditPopup, setShowEditPopup] = useState(false);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState("");

  const [showLoader, setShowLoader] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  const [isDragging, setIsDragging] = useState(false);
  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);
  const [showCanvasPopupModal, setShowCanvasPopupModal] = useState(false);
  const [currentAction, setCurrentAction] = useState('select');
  const [brushWidth, setBrushWidth] = useState(10);
  const [isObjectSelected, setIsObjectSelected] = useState(false);
  const [hasCanvasChanged, setHasCanvasChanged] = useState(false);
  const [eraseMode, setEraseMode] = useState(false);
  const [modeType, setModeType] = useState("");
  const [buttonClass, setButtonClass] = useState('before-after-button btn btn-light');
  const [bgImageProps, setBgImageProps] = useState([]);
  const [bgImagePositionProps, setBgImagePositionProps] = useState([])
  const [projects, setProjects] = useState([]);
  const [newProjectImage, setNewProjectImage] = useState("");

  //calibration tool
  const [scaleFactor, setScaleFactor] = useState(1);
  const [projectScaleFactor, setProjectScaleFactor] = useState(null);
  const [knownLength, setKnownLength] = useState(10);
  const [dimension, setDimension] = useState('width');
  const [unit, setUnit] = useState('inches');
  const [showCalibrationOverlay, setShowCalibrationOverlay] = useState(false);
  const [pixelLength, setPixelLength] = useState(null);
  const [isCalibrationMode, setCalibrationMode] = useState(false);
  const [isCalibrating, setIsCalibrating] = useState(false);
  const [selectedObjectHeightReal, setSelectedObjectHeightReal] = useState(0);
  const [selectedObjectWidthReal, setSelectedObjectWidthReal] = useState(0);
  const [realDimensions, setRealDimensions] = useState({ width: 0, height: 0 });
  const [calibrationGroup, setCalibrationGroup] = useState(null);
  const [selectedObjectId, setSelectedObjectId] = useState(null);
  const [calibrationDisplay, setCalibrationDisplay] = useState(false);
  const [hasCalibration, setHasCalibration] = useState(false);
  //admin
  const [userRole, setUserRole] = useState(null);
  //banner
  const [userPlan, setUserPlan] = useState(null);
  //client custom logo
  const [userCompanyName, setUserCompanyName] = useState(null);
  //cookies
  const [showConsentBanner, setShowConsentBanner] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const location = useLocation();
  //email confirm
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  //chat
  const [showModal, setShowModal] = useState(false);
  //itemscolor eye dropper
  const [eyePickerActiveColor, setEyePickerActiveColor] = useState('');
  const bgImagePositionPropsRef = useRef(null);
  //contact form
  const [showContactForm, setShowContactForm] = useState(false);
  //help in canonical
  const canonicalUrl = `https://facadewebapp.com${location.pathname}`;
  //beta news
  const [isNewsVisible, setIsNewsVisible] = useState(true);
  //accessories
  const [selectedAccessory, setselectedAccessory] = useState('');
  const [isAccessoryTypeSelected, setIsAccessoryTypeSelected] = useState(false);
  const [accessoryType, setAccessoryType] = useState('');
  const [isAccessoriesSelectionPanelVisible, setIsAccessoriesSelectionPanelVisible] = useState(false);
  //ai detection
  const [isDimensionsVisible, setIsDimensionsVisible] = useState(true);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(true);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [toastId, setToastId] = useState(null);
  const [uploadComplete, setUploadComplete] = useState(false); // State to track upload completion
  const [editComplete, setEditComplete] = useState(false); // State to track upload completion
  //dVirtual designer


  const [isDesignerModalOpen, setDesignerModalOpen] = useState(false);
  const [isDetectionTriggered, setIsDetectionTriggered] = useState(false);
  const [designerTriggered, setDesignerTriggered] = useState(false);
  const [showNotice, setShowNotice] = useState(false);

  const isGuestUser = currentUser && currentUser.email.startsWith("guest_");
  const tooltipMessage = t('tooltip-message', {
    defaultValue: "This feature is only available for registered users.",
  });




  let modalTitle, modalContent;
  if (location.pathname === '/cookies') {
    modalTitle = t('Cookie Policy');
    modalContent = <CombinedCookiePolicy />;
  } else if (location.pathname === '/terms') {
    modalTitle = t('Terms of Use');
    modalContent = <CombinedTermsOfUse />;
  }

  useEffect(() => {

    if (currentUser && !localStorage.getItem('importantNoticeDismissed')) {

      setShowNotice(true);
    }
  }, [currentUser]);

  const handleCloseNotice = () => {
    setShowNotice(false);

    localStorage.setItem('importantNoticeDismissed', 'true');

  };

  useEffect(() => {
    if (location.pathname === '/cookies' || location.pathname === '/terms') {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    const userConsentCookie = document.cookie.split('; ').find(row => row.startsWith('user_consent='));
    setTermsAccepted(userConsentCookie)


  }, [termsAccepted]);

  useEffect(() => {
    // Parse the query parameters
    const queryParams = new URLSearchParams(location.search);

    // Assuming currentUser being true means the user is logged in
    if (currentUser) {
      // Determine if the user is navigating back from the email verification link
      const emailVerifiedParam = queryParams.get('emailVerified') === 'true';

      // Function to handle post-email-verification logic
      const handleEmailVerified = async () => {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();

          // Proceed only if the email hasn't been marked as confirmed in Firestore
          if (!userData.emailConfirmed && currentUser.emailVerified) {
            // Update Firestore to mark the email as confirmed
            await updateDoc(userDocRef, { emailConfirmed: true });

            // Send the welcome email now that the email is confirmed
            sendWelcomeEmail({
              email: currentUser.email,
              name: userData.userName,
            }, i18n.language);

            // Show confirmation modal or perform other UI updates as needed
            setShowConfirmationModal(true);
            setShowCanvasPopupModal(false);
          }
        } else {
          console.error('User details not found in Firestore');
        }
      };

      // Check if the user has verified their email via the link (emailVerifiedParam)
      if (emailVerifiedParam && currentUser.emailVerified) {
        handleEmailVerified();
      }
    }

    // Additional logic to handle 'login' query parameter for showing login modal, if necessary
    if (queryParams.get('login') === 'true') {
      setLogin(true);
      // No need to call setShowAuthModal(true) here since currentUser being true implies the user is logged in
    }
  }, [currentUser, location.search, i18n.language]);



  const sendWelcomeEmail = async (user, language) => {
    const payload = {
      userData: {
        email: user.email,
        name: user.name
      },
      language: language
    };

    // http://localhost:9999/.netlify/functions/sendWelcomeEmail /.netlify/functions/sendWelcomeEmail

    try {
      const response = await fetch('/.netlify/functions/sendWelcomeEmail', {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();

    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const originalRotationAngle = 0;

  let lastX = 0;
  let lastY = 0;
  let pausePanning = false;
  let zoomStartScale = 1;
  let rightDecorativeGlass;
  let rightSidelightDecorativeGlass;
  let leftSidelightDecorativeGlass;
  let rightLeftSidelightDecorativeGlassLeft;
  let rightLeftSidelightDecorativeGlassRight;



  const initCanvas = () => {
    // Create a new Fabric.js canvas instance
    const canvas = new fabric.Canvas("canv", {
      index: 0,
      state: [],
      // backgroundColor: "#333",
      stateaction: true,
      fireRightClick: false,
      selection: false
    });

    // Enable high-quality image smoothing
    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';

    return canvas;
  };

  let canvasHeight = canvas.height;
  let canvasWidth = canvas.width;

  // toast for consent
  const ConsentToast = () => (
    <div style={{ textAlign: 'center' }}>
      <div>{t('toast.message')}</div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
        <button style={{ marginRight: '10px' }} onClick={handleAccept}>
          {t('toast.accept')}
        </button>
        <button onClick={handleDecline}>
          {t('toast.decline')}
        </button>
      </div>
    </div>
  );

  useEffect(() => {
    // Check if the cookies are set at the start
    if (document.cookie.includes("user_consent_beta=true") && document.cookie.includes("user_consent=true")) {
      setShowConsentBanner(false);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Check for the presence of the user_consent cookie using the native browser API
      const userConsentCookie = document.cookie.split('; ').find(row => row.startsWith('user_consent='));
      if (!userConsentCookie) {
        toast(<ConsentToast />, {
          position: "bottom-center",
          autoClose: false,
          closeOnClick: false,
          pauseOnHover: true,
          closeButton: false,
          toastId: "consent-toast"
        });
      }
    }, 20000); // 20 seconds delay

    return () => clearTimeout(timer);
  }, []);


  //   const {
  //     anchorPoint,
  //     showMenu,
  // } = useContextMenu(menuRef, isDragging);
  useEffect(() => {
    const fetchUserRole = async () => {
      // const auth = getAuth();
      // const currentUser = auth.currentUser;

      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          setUserRole(userData.role);
          setUserPlan(userData.planType)
          setUserCompanyName(userData.companyName)
        }
      }

      if (!currentUser) { setIsAccountModalOpen(false) }
    };

    fetchUserRole();
  }, [currentUser]);

  useEffect(() => {
    // fixed the double canvas by conditionning creation of the canvas with the current user state
    if (currentUser) {
      // handleShowLoader();
      setIsLoaderFinish(false);
      setCanvas(initCanvas());
      // handleHideLoader(); // Move hideLoader() inside the timeout callback

    }
  }, [currentUser]);

  useEffect(() => {
    // handleShowLoader();
    if (!canvas) {
      handleHideLoader();
      return;
    }

    canvas.setDimensions({
      width: 1200,
      height: 1200,
    });
    canvasHeight = canvas.height;
    canvasWidth = canvas.width;
    initCanvasEvents(setIsDragging);
    initKeyboardEvents();
    if (projectName) setIsLoaderFinish(true);
    // handleHideLoader();

    return () => {
      document.removeEventListener("keyup", initKeyboardEvents);
    };
  }, [canvas]);

  useEffect(() => {
    if (canvas) {
      canvas.on("selection:created", checkForSeletedItems);
      canvas.on("selection:updated", checkForSeletedItems);
      canvas.on("selection:cleared", checkForSeletedItems);
    }

    return () => {
      if (canvas) {
        canvas.off("selection:created", checkForSeletedItems);
        canvas.off("selection:updated", checkForSeletedItems);
        canvas.off("selection:cleared", checkForSeletedItems);
      }
    };
  }, [canvas]);

  useEffect(() => {
    if (canvas) {

      updateColor(itemColor);
    }
  }, [itemColor, canvas]);

  useEffect(() => {
    if (!projectName) {
      setShowCanvasPopupModal(true);
    }
  }, [projectName]);

  useEffect(() => {
    if (canvas) {
      if (eraseMode) {
        canvas.forEachObject(function (obj) {
          obj.set({ opacity: 0.4 });
        });
        canvas.renderAll();
      } else {
        canvas.forEachObject(function (obj) {
          obj.set({ opacity: 1 });  // Reset to full opacity if eraseMode is turned off
        });
        canvas.renderAll();
      }
    }
  }, [canvas, eraseMode]);

  useEffect(() => {
    if (canvasProject) {
      const scaleFactor = canvasProject.scaleFactor || null;

      if (scaleFactor !== null) {
        setHasCalibration(true);
        setScaleFactor(scaleFactor);

      } else {
        setHasCalibration(false);
      }
    }
  }, [canvasProject]);


  useEffect(() => {
    if (canvas) {
      const handleSelection = (options) => {

        if (options.selected[0]) {
          let relevantId;

          if (options.selected[0].windowStackImageId) {
            relevantId = options.selected[0].windowStackImageId;
          } else if (options.selected[0].doorStackImageId) {
            relevantId = options.selected[0].doorStackImageId;
          }


          if (relevantId !== selectedObjectId) {
            setSelectedObjectId(relevantId);

            const selectedObject = options.selected[0];
            const actualWidth = selectedObject.width * selectedObject.scaleX;
            const actualHeight = selectedObject.height * selectedObject.scaleY;

            const realWidth = (selectedObject.width * selectedObject.scaleX) * scaleFactor;
            const realHeight = (selectedObject.height * selectedObject.scaleY) * scaleFactor;

            setRealDimensions({ width: realWidth.toFixed(2), height: realHeight.toFixed(2) });

          }
        }
      };

      canvas.on('selection:created', handleSelection);
      canvas.on('selection:updated', handleSelection);

      // Also handle the case where the selection is cleared
      canvas.on('selection:cleared', () => {
        setSelectedObjectId(null);
      });

      return () => {
        canvas.off('selection:created', handleSelection);
        canvas.off('selection:updated', handleSelection);
        canvas.off('selection:cleared');
      };
    }
  }, [selectedObjectId, canvas, scaleFactor]);


  useEffect(() => {
    if (calibrationGroup && isCalibrationMode) {

      const handleModified = () => {

        const newPixelLengthScaled = dimension === 'width'
          ? calibrationGroup.width * calibrationGroup.scaleX
          : calibrationGroup.height * calibrationGroup.scaleY;

        handlePixelLengthSet(newPixelLengthScaled);
      };


      calibrationGroup.on('modified', handleModified);
      calibrationGroup.on('selected', handleModified);
      calibrationGroup.on('upddated', handleModified);


      // Cleanup: remove the event listener when the component is unmounted or conditions change
      return () => {
        calibrationGroup.off('modified', handleModified);
        calibrationGroup.off('selected', handleModified);
        calibrationGroup.off('upddated', handleModified);
      };
    }
  }, [calibrationGroup, isCalibrationMode, dimension]);

  useEffect(() => {
    if (canvas) {

      const handleModified = (options) => {
        if (options.target) {

          const modifiedObject = options.target;
          const actualWidth = modifiedObject.width * modifiedObject.scaleX;
          const actualHeight = modifiedObject.height * modifiedObject.scaleY;

          const realWidth = (modifiedObject.width * modifiedObject.scaleX) * scaleFactor;
          const realHeight = (modifiedObject.height * modifiedObject.scaleY) * scaleFactor;

          // Update your React state to reflect the new dimensions
          setRealDimensions({ width: realWidth.toFixed(2), height: realHeight.toFixed(2) });
        }
      };

      canvas.on('object:modified', handleModified);

      return () => {
        canvas.off('object:modified', handleModified);
      };
    }
  }, [canvas, scaleFactor]);

  useEffect(() => {
    // Extract the language code from the URL path
    const languageCode = window.location.pathname.split('/')[1];

    // Check if the extracted language code is in the list of available languages
    if (availableLanguages.includes(languageCode)) {
      i18n.changeLanguage(languageCode);
    } else {
      // If not, fallback to browser's language setting
      const browserLang = i18n.language || window.navigator.language.split('-')[0];
      i18n.changeLanguage(browserLang);
    }
  }, [i18n, location.pathname]);



  const canvasContainerRef = useRef(null);

  const knownLengthRef = useRef(knownLength);

  useEffect(() => {
    knownLengthRef.current = knownLength;
  }, [knownLength]);

  useEffect(() => {
    if (showCalibrationOverlay || !showCalibrationOverlay) {
      function handleOverlayClosed() {
        if (canvas && calibrationGroup) {
          // Remove the calibration square tool and deselect it
          canvas.discardActiveObject();
          canvas.remove(calibrationGroup);
          canvas.requestRenderAll();
        }
      }

      // Function to toggle canvas object visibility
      const toggleCanvasObjectsVisibility = (isVisible) => {
        if (!canvas) return; // Guard clause in case canvas is not available
        canvas.discardActiveObject();
        const objects = canvas.getObjects();
        objects.forEach((obj) => {
          if (obj !== calibrationGroup) {
            obj.set('visible', isVisible);
          }
        });
        canvas.requestRenderAll();
      };


      if (showCalibrationOverlay) {
        toggleCanvasObjectsVisibility(false);

        // Add your Calibration tool here, if needed
        // toggleCalibrationMode()

      } else if (!showCalibrationOverlay) {
        toggleCanvasObjectsVisibility(true);

        // Remove the Calibration tool
        handleOverlayClosed();
      }

      // Listen to the custom 'overlayClosed' event
      window.addEventListener('overlayClosed', handleOverlayClosed);

      return () => {
        // Remove the event listener when the component unmounts
        window.removeEventListener('overlayClosed', handleOverlayClosed);
      };
    }
  }, [canvas, calibrationGroup, showCalibrationOverlay]);

  useEffect(() => {
    if (topBarRef.current) {
      topBarRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [projectName, isFocus]);


  const toggleOptionsPanel = () => {
    setIsOptionsPanelVisible((prevValue) => !prevValue);
  };



  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);


  const handleShowLoader = () => {
    setShowLoader(true);
    setIsLoaderFinish(false);
  };

  const handleHideLoader = () => {
    setTimeout(() => {
      setShowLoader(false);
    }, 1000);
    setIsLoaderFinish(true);
  };



  async function canvasBackground(url) {

    return new Promise((resolve, reject) => {
      if (url) {
        clearCanvas();
        fabric.Image.fromURL(url, (oImg) => {
          if (oImg) {
            scaleAndPositionImage(oImg);
            resolve(); // Resolve when image is loaded and canvas is updated
          } else {
            reject("Error loading image");
          }
        }, { crossOrigin: "anonymous" });
      } else {
        reject("No upload image URL provided");
      }
    });
  }


  //  scale canvas image for better fit
  function scaleAndPositionImage(bgImage) {
    // get the scale
    // it is the min of the 2 ratios
    let scale_factor = Math.min(
      canvas.width / bgImage.width,
      canvas.height / bgImage.height
    );

    // Lets get the new width and height based on the scale factor
    let newWidth = bgImage.width * scale_factor;
    let newHeight = bgImage.height * scale_factor;

    // get the top left position of the image
    // in order to center the image within the canvas
    let x = canvas.width / 2 - newWidth / 2;
    let y = 0;

    // setBgImageProps(prevProps => [
    //   ...prevProps,
    //   {
    //     x: x,
    //     y: y,
    //     newWidth: newWidth,
    //     newHeight: newHeight,
    //   }
    // ]);


    setBgImageProps({
      scaleX: scale_factor,
      scaleY: scale_factor,
      left: x,
      top: y,
      newWidth: newWidth,
      newHeight: newHeight,
    });


    // When drawing the image, we have to scale down the image
    // width and height in order to fit within the canvas

    canvas.setBackgroundImage(
      bgImage,
      canvas.renderAll.bind(canvas),
      {
        scaleY: scale_factor,
        scaleX: scale_factor,
        left: x,
        top: y,
        originX: "left", // Set the origin of transformation to the left
        originY: "top", // Set the origin of transformation to the top
      },
      {
        crossOrigin: "Anonymous",
      }
    );
  }

  fabric.Object.NUM_FRACTION_DIGITS = 8;

  useEffect(() => {
    function updateFabricSettings() {
      if (window.innerWidth <= 768) {
        fabric.Object.prototype.set({
          cornerSize: 30,
          cornerStyle: "circle",
          cornerHitTolerance: 10,
        });
      } else {
        // Optionally reset to default values for larger screens
      }
    }

    updateFabricSettings();

    window.addEventListener("resize", updateFabricSettings);

    // Cleanup
    return () => {
      window.removeEventListener("resize", updateFabricSettings);
    };
  }, []);

  // add a custom property
  fabric.Object.prototype.toObject = (function (toObject) {
    return function (propertiesToInclude) {
      propertiesToInclude = (propertiesToInclude || []).concat([
        "windowType",
        "optionType",
        "itemType"  // Add your new custom property here
      ]);
      return toObject.apply(this, [propertiesToInclude]);
    };
  })(fabric.Object.prototype.toObject);


  const handleActionChange = (action) => {
    if (action === "erase") {
      setEraseMode(true);
    }
    if (action === "undo") {
      setEraseMode(false);
      setModeType("regular")
    }

    setCurrentAction(action);
    changeAction(action, canvas, brushWidth); // Pass the width to changeAction
  };


  const handleWidthChange = (width) => {
    setBrushWidth(width); // Update the brush width state
    if (currentAction === 'erase') {
      enableEraser(canvas, width);
    } else if (currentAction === 'draw') {
      enableDraw(canvas, width);
    }
    // //saveCanvasState(canvas);
  };

  function extractGridTypeFromUrl(url) {
    // Decode URL to handle encoded characters like '%2F' 
    const decodedUrl = decodeURIComponent(url);

    // Use a regular expression to find the Grid Type
    const regex = /Grid(\d+)_GridOnly/;
    const match = decodedUrl.match(regex);

    // If a match is found, return it
    if (match && match[1]) {
      return 'Grid' + match[1];
    }

    // Otherwise, return a default or null
    return null;
  }

  // replace objects filler images like the optionUrl to grid or decorativeGlass image
  function setSrc(tmpObject, gridUrl) {

    var i = tmpObject;
    if (tmpObject.type === "polygon" && tmpObject.usePerspective) {
      i = tmpObject.originObject;
    }

    let gridOption = extractGridTypeFromUrl(gridUrl);
    if (i && i.item(1)) {
      i.item(1).set('windowGridType', gridOption);
    }

    const objectStackPosition1 = i._objects[1];
    const objectStackPosition2 = i._objects[2];
    const objectStackPosition3 = i._objects[3];
    const objectStackPosition4 = i._objects[4];
    const objectStackPosition5 = i._objects[5];
    const objectStackPosition6 = i._objects[6];
    const objectStackPosition7 = i._objects[7];
    const objectStackPosition8 = i._objects[8];
    const objectStackPosition9 = i._objects[9];
    const objectStackPosition10 = i._objects[10];
    const objectStackPosition11 = i._objects[11];
    const objectStackPosition12 = i._objects[12];

    // Removed `i.type === "group"`. For groups, it's because of this function call.
    if (isSameType) {
      canvas.getActiveObjects().forEach((tObject) => {
        var object = tObject;
        if (tObject.type === "polygon" && tObject.usePerspective) {
          object = tObject.originObject;
        }
        // we separate the types so there is no error when select all

        const selectedObjectStackPosition1 = object._objects[1];
        const selectedObjectStackPosition2 = object._objects[2];

        if (gridUrl.includes("NoGrid")) {
          fabric.util.loadImage(
            gridUrl,
            function (img) {
              let switchOption = img;
              objectStackPosition1.setElement(switchOption);
              setObjectPerspective(canvas);
              canvas.renderAll();

              selectedObjectStackPosition1.filters = [];
              selectedObjectStackPosition1.applyFilters();
              setObjectPerspective(canvas);
              canvas.renderAll();
            },
            null,
            "anonymous"
          );
        }

        if (
          object.type === "group" &&
          !i.windowType.includes("Door") &&
          !gridUrl.includes("NoGrid")
        ) {
          selectedObjectStackPosition1.filters = [];
          selectedObjectStackPosition1.applyFilters();
          setObjectPerspective(canvas);
          canvas.renderAll();

          var filter = new fabric.Image.filters.BlendColor({
            color: object.itemColor,
            mode: "multiply",
            alpha: 0.95,
          });
          selectedObjectStackPosition1.filters[0] = filter;
          selectedObjectStackPosition1.applyFilters();
          setObjectPerspective(canvas);
          canvas.renderAll();
        }
        if (
          object.type === "group" &&
          selectedObjectStackPosition1.optionType.includes("Door")
        ) {
          selectedObjectStackPosition1.set("cutoutSize", glassToSearch);
        } else {
          return;
        }
        setObjectPerspective(canvas);
        canvas.renderAll();
      });
    }
    // we switch decorative glass option

    if (!i.windowType.includes("Door")) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;

          objectStackPosition1.setElement(switchOption);
          setObjectPerspective(canvas);
          canvas.renderAll();
          // //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente4") &&
      i.itemUrl.includes("Double_Door") &&
      !i.itemUrl.includes("Right_Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption3 = img;

          objectStackPosition3.setElement(switchOption3);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });
          let switchOption5 = img;
          objectStackPosition5.setElement(switchOption5);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption6 = img;

          objectStackPosition6.setElement(switchOption6);

          objectStackPosition6.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption7 = img;

          objectStackPosition7.setElement(switchOption7);

          objectStackPosition7.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption8 = img;

          objectStackPosition8.setElement(switchOption8);

          objectStackPosition8.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          // //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente4") &&
      i.itemUrl.includes("Double_Door_Right_Left_SideLight") &&
      !i.itemUrl.includes("Full")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption3 = img;

          objectStackPosition3.setElement(switchOption3);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });
          let switchOption5 = img;
          objectStackPosition9.setElement(switchOption5);

          objectStackPosition9.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption6 = img;

          objectStackPosition6.setElement(switchOption6);

          objectStackPosition6.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption7 = img;

          objectStackPosition7.setElement(switchOption7);

          objectStackPosition7.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption8 = img;

          objectStackPosition8.setElement(switchOption8);

          objectStackPosition8.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          // //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassLeft,
        function (img) {
          let leftSidelight = img;

          objectStackPosition5.setElement(leftSidelight);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          // //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassRight,
        function (img) {
          let rightSidelight = img;

          objectStackPosition10.setElement(rightSidelight);

          objectStackPosition10.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          // //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente4") &&
      i.itemUrl.includes("Double_Door_Right_Left_SideLight") &&
      i.itemUrl.includes("Full")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption3 = img;

          objectStackPosition3.setElement(switchOption3);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });
          let switchOption5 = img;
          objectStackPosition5.setElement(switchOption5);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption6 = img;

          objectStackPosition6.setElement(switchOption6);

          objectStackPosition6.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption7 = img;

          objectStackPosition7.setElement(switchOption7);

          objectStackPosition7.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption8 = img;

          objectStackPosition8.setElement(switchOption8);

          objectStackPosition8.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          // //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente4") &&
      i.itemUrl.includes("Single_Door") &&
      !i.itemUrl.includes("Sidelight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption3 = img;

          objectStackPosition3.setElement(switchOption3);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          // //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente4") &&
      i.itemUrl.includes("Single_Door") &&
      i.itemUrl.includes("SidelightFull") &&
      !i.itemUrl.includes("Right_Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption3 = img;

          objectStackPosition3.setElement(switchOption3);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          // //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente4") &&
      !i.itemUrl.includes("Double_Door") &&
      !i.itemUrl.includes("Right_SideLight") &&
      !i.itemUrl.includes("Full") &&
      i.itemUrl.includes("Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });
          let switchOption3 = img;

          objectStackPosition4.setElement(switchOption3);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          // //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        leftSidelightDecorativeGlass,
        function (img) {
          let leftSidelight = img;

          objectStackPosition5.setElement(leftSidelight);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          // //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente4") &&
      !i.itemUrl.includes("Double_Door") &&
      i.itemUrl.includes("Right_SideLight") &&
      !i.itemUrl.includes("Full") &&
      !i.itemUrl.includes("Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });
          let switchOption3 = img;

          objectStackPosition4.setElement(switchOption3);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          // //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        rightSidelightDecorativeGlass,
        function (img) {
          let rightSidelight = img;

          objectStackPosition5.setElement(rightSidelight);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          // //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente5") &&
      !i.itemUrl.includes("Double_Door") &&
      i.itemUrl.includes("Right_SideLight") &&
      !i.itemUrl.includes("Full") &&
      !i.itemUrl.includes("Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });
          let switchOption3 = img;

          objectStackPosition4.setElement(switchOption3);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });
          let switchOption4 = img;

          objectStackPosition5.setElement(switchOption4);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          // //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        rightSidelightDecorativeGlass,
        function (img) {
          let rightSidelight = img;

          objectStackPosition6.setElement(rightSidelight);

          objectStackPosition6.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          // //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente5") &&
      !i.itemUrl.includes("Double_Door") &&
      !i.itemUrl.includes("Right_SideLight") &&
      !i.itemUrl.includes("Full") &&
      i.itemUrl.includes("Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });
          let switchOption3 = img;

          objectStackPosition4.setElement(switchOption3);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });
          let switchOption4 = img;

          objectStackPosition5.setElement(switchOption4);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          // //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        leftSidelightDecorativeGlass,
        function (img) {
          let leftSidelight = img;

          objectStackPosition6.setElement(leftSidelight);

          objectStackPosition6.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente5") &&
      i.itemUrl.includes("Double_Door") &&
      !i.itemUrl.includes("Right_Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption3 = img;

          objectStackPosition3.setElement(switchOption3);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption5 = img;

          objectStackPosition5.setElement(switchOption5);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption6 = img;
          objectStackPosition6.setElement(switchOption6);

          objectStackPosition6.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption7 = img;

          objectStackPosition7.setElement(switchOption7);

          objectStackPosition7.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption8 = img;

          objectStackPosition8.setElement(switchOption8);

          objectStackPosition8.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption9 = img;

          objectStackPosition9.setElement(switchOption9);

          objectStackPosition9.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption10 = img;

          objectStackPosition10.setElement(switchOption10);

          objectStackPosition10.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente5") &&
      i.itemUrl.includes("Double_Door_Right_Left_SideLight") &&
      !i.itemUrl.includes("Full")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption3 = img;

          objectStackPosition3.setElement(switchOption3);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption5 = img;

          objectStackPosition5.setElement(switchOption5);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption6 = img;
          objectStackPosition11.setElement(switchOption6);

          objectStackPosition11.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption7 = img;

          objectStackPosition7.setElement(switchOption7);

          objectStackPosition7.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption8 = img;

          objectStackPosition8.setElement(switchOption8);

          objectStackPosition8.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption9 = img;

          objectStackPosition9.setElement(switchOption9);

          objectStackPosition9.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption10 = img;

          objectStackPosition10.setElement(switchOption10);

          objectStackPosition10.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );

      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassLeft,
        function (img) {
          let leftSidelight = img;

          objectStackPosition6.setElement(leftSidelight);

          objectStackPosition6.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassRight,
        function (img) {
          let rightSidelight = img;

          objectStackPosition12.setElement(rightSidelight);

          objectStackPosition12.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente5") &&
      i.itemUrl.includes("Double_Door_Right_Left_SideLight") &&
      i.itemUrl.includes("Full")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption3 = img;

          objectStackPosition3.setElement(switchOption3);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption5 = img;

          objectStackPosition5.setElement(switchOption5);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption6 = img;
          objectStackPosition6.setElement(switchOption6);

          objectStackPosition6.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption7 = img;

          objectStackPosition7.setElement(switchOption7);

          objectStackPosition7.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption8 = img;

          objectStackPosition8.setElement(switchOption8);

          objectStackPosition8.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption9 = img;

          objectStackPosition9.setElement(switchOption9);

          objectStackPosition9.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption10 = img;

          objectStackPosition10.setElement(switchOption10);

          objectStackPosition10.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente5") &&
      i.itemUrl.includes("Single_Door")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption3 = img;

          objectStackPosition3.setElement(switchOption3);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption5 = img;

          objectStackPosition5.setElement(switchOption5);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente5") &&
      i.itemUrl.includes("Single_Door") &&
      i.itemUrl.includes("SidelightFull")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption3 = img;

          objectStackPosition3.setElement(switchOption3);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption5 = img;

          objectStackPosition5.setElement(switchOption5);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("3AGlassAt123") &&
      !i.itemUrl.includes("Double_Door") &&
      !i.itemUrl.includes("Right_SideLight") &&
      !i.itemUrl.includes("Left_SideLight") &&
      !i.itemUrl.includes("Full")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("3AGlassAt123") &&
      !i.itemUrl.includes("Double_Door") &&
      !i.itemUrl.includes("Right_SideLight") &&
      i.itemUrl.includes("Full") &&
      i.itemUrl.includes("Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("3AGlassAt123") &&
      !i.itemUrl.includes("Double_Door") &&
      i.itemUrl.includes("Right_SideLight") &&
      !i.itemUrl.includes("Full") &&
      !i.itemUrl.includes("Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        rightSidelightDecorativeGlass,
        function (img) {
          let rightSidelight = img;

          objectStackPosition4.setElement(rightSidelight);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("3AGlassAt123") &&
      !i.itemUrl.includes("Double_Door") &&
      i.itemUrl.includes("Right_SideLight") &&
      i.itemUrl.includes("Full") &&
      !i.itemUrl.includes("Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("3AGlassAt123") &&
      !i.itemUrl.includes("Double_Door") &&
      i.itemUrl.includes("Left_SideLight") &&
      !i.itemUrl.includes("Right_Left_SideLight") &&
      !i.itemUrl.includes("Full")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        leftSidelightDecorativeGlass,
        function (img) {
          let leftSidelight = img;

          objectStackPosition4.setElement(leftSidelight);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("3AGlassAt123") &&
      i.itemUrl.includes("Double_Door") &&
      !i.itemUrl.includes("Right_Left_SideLight") &&
      !i.itemUrl.includes("Sidelight_Full")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption5 = img;

          objectStackPosition5.setElement(switchOption5);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption6 = img;

          objectStackPosition6.setElement(switchOption6);

          objectStackPosition6.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("3AGlassAt123") &&
      i.itemUrl.includes("Single_Door") &&
      !i.itemUrl.includes("Right_Left_SideLight") &&
      i.itemUrl.includes("Sidelight_Full")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("3AGlassAt123") &&
      i.itemUrl.includes("Double_Door") &&
      i.itemUrl.includes("SidelightFull")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption5 = img;

          objectStackPosition5.setElement(switchOption5);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption6 = img;

          objectStackPosition6.setElement(switchOption6);

          objectStackPosition6.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );

      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassLeft,
        function (img) {
          let switchOption = img;
          objectStackPosition4.setElement(switchOption);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassRight,
        function (img) {
          let switchOption = img;
          objectStackPosition8.setElement(switchOption);

          objectStackPosition8.set({
            ownCaching: false,
            dirty: true,
          });
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("3AGlassAt123") &&
      !i.itemUrl.includes("Double_Door") &&
      i.itemUrl.includes("Right_Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          fabric.util.loadImage(
            rightLeftSidelightDecorativeGlassLeft,
            function (img) {
              let switchOption = img;
              objectStackPosition4.setElement(switchOption);

              objectStackPosition4.set({
                ownCaching: false,
                dirty: true,
              });
            },
            null,
            "anonymous"
          );
          setObjectPerspective(canvas);
          canvas.renderAll();

          fabric.util.loadImage(
            rightLeftSidelightDecorativeGlassRight,
            function (img) {
              let switchOption = img;
              objectStackPosition5.setElement(switchOption);

              objectStackPosition5.set({
                ownCaching: false,
                dirty: true,
              });

              setObjectPerspective(canvas);
              canvas.renderAll();
            },
            null,
            "anonymous"
          );

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("3AGlassAt123") &&
      i.itemUrl.includes("Double_Door") &&
      i.itemUrl.includes("Right_Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition7.setElement(switchOption4);

          objectStackPosition7.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption5 = img;

          objectStackPosition5.setElement(switchOption5);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption6 = img;

          objectStackPosition6.setElement(switchOption6);

          objectStackPosition6.set({
            ownCaching: false,
            dirty: true,
          });
        },
        null,
        "anonymous"
      );

      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassLeft,
        function (img) {
          let switchOption = img;
          objectStackPosition4.setElement(switchOption);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();

      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassRight,
        function (img) {
          let switchOption = img;
          objectStackPosition8.setElement(switchOption);

          objectStackPosition8.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      !objectStackPosition2.optionType &&
      objectStackPosition1.optionType.includes("Single_Door")
    ) {
      i.itemUrl.includes("3AGlassAt3");


      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;

          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes("Double_Door") &&
      !objectStackPosition1.optionType.includes(
        "Double_Door_Right_Left_SideLight"
      )
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });
          let switchOptionRight = img;

          objectStackPosition2.setElement(switchOptionRight);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes("Single_Door_Right_SideLight") &&
      !i.itemUrl.includes("NoGlass") &&
      !i.itemUrl.includes("3AGlassAt123")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;

          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          if (!i.itemUrl.includes("Full")) {
            fabric.util.loadImage(
              rightSidelightDecorativeGlass,
              function (img) {
                let rightSidelight = img;

                objectStackPosition2.setElement(rightSidelight);

                objectStackPosition2.set({
                  ownCaching: false,
                  dirty: true,
                });

                setObjectPerspective(canvas);
                canvas.renderAll();
                setIsObjSelected(true);
                //saveCanvasState(canvas);
              },
              null,
              "anonymous"
            );
          }
          setObjectPerspective(canvas);
          canvas.renderAll();
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes("Single_Door_Right_SideLight") &&
      !i.itemUrl.includes("Full") &&
      i.itemUrl.includes("NoGlass")
    ) {
      fabric.util.loadImage(
        rightSidelightDecorativeGlass,
        function (img) {
          let rightSidelight = img;

          objectStackPosition1.setElement(rightSidelight);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes("Single_Door_Left_SideLight") &&
      !i.itemUrl.includes("Full") &&
      !i.itemUrl.includes("NoGlass")
    ) {

      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;

          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          if (!i.itemUrl.includes("Full")) {
            fabric.util.loadImage(
              leftSidelightDecorativeGlass,
              function (img) {
                let leftSidelight = img;

                objectStackPosition2.setElement(leftSidelight);

                objectStackPosition2.set({
                  ownCaching: false,
                  dirty: true,
                });

                setObjectPerspective(canvas);
                canvas.renderAll();
                setIsObjSelected(true);
                //saveCanvasState(canvas);
              },
              null,
              "anonymous"
            );
          }
          setObjectPerspective(canvas);
          canvas.renderAll();
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes("Single_Door_Left_SideLight") &&
      !i.itemUrl.includes("Full") &&
      i.itemUrl.includes("NoGlass")
    ) {
      fabric.util.loadImage(
        leftSidelightDecorativeGlass,
        function (img) {
          let leftSidelight = img;

          objectStackPosition1.setElement(leftSidelight);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes(
        "Single_Door_Right_Left_SideLight"
      ) &&
      !i.itemUrl.includes("Full") &&
      !i.itemUrl.includes("NoGlass")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switcLeftSidelightOption = img;

          objectStackPosition1.setElement(switcLeftSidelightOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassLeft,
        function (img) {
          let switchDoorOptiont = img;

          objectStackPosition2.setElement(switchDoorOptiont);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();

          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassRight,
        function (img) {
          let switcRightSidelightOption = img;

          objectStackPosition3.setElement(switcRightSidelightOption);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();

          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setIsObjSelected(true);
      //saveCanvasState(canvas);

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes(
        "Single_Door_Right_Left_SideLight"
      ) &&
      !i.itemUrl.includes("Full") &&
      i.itemUrl.includes("NoGlass")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switcLeftSidelightOption = img;

          objectStackPosition1.setElement(switcLeftSidelightOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassRight,
        function (img) {
          let switcRightSidelightOption = img;

          objectStackPosition2.setElement(switcRightSidelightOption);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();

          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setIsObjSelected(true);
      //saveCanvasState(canvas);

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes(
        "Double_Door_Right_Left_SideLight"
      ) &&
      !i.itemUrl.includes("Full") &&
      !i.itemUrl.includes("NoGlass") &&
      !i.itemUrl.includes("FlatWith") &&
      !i.itemUrl.includes("With") &&
      !i.itemUrl.includes("Tao")
    ) {
      fabric.util.loadImage(
        rightDecorativeGlass,
        function (img) {
          let switcRightSideDoubleDoor = img;

          objectStackPosition3.setElement(switcRightSideDoubleDoor);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switcLeftSideDoubleDoor = img;

          objectStackPosition1.setElement(switcLeftSideDoubleDoor);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassLeft,
        function (img) {
          let switchRightSidelightOption = img;

          objectStackPosition2.setElement(switchRightSidelightOption);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();

          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassRight,
        function (img) {
          let switcRightSidelightOption = img;

          objectStackPosition4.setElement(switcRightSidelightOption);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();

          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setIsObjSelected(true);
      //saveCanvasState(canvas);

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes(
        "Double_Door_Right_Left_SideLight"
      ) &&
      i.itemUrl.includes("Full") &&
      !i.itemUrl.includes("NoGlass")
    ) {
      fabric.util.loadImage(
        rightDecorativeGlass,
        function (img) {
          let switcRightSideDoubleDoor = img;

          objectStackPosition2.setElement(switcRightSideDoubleDoor);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switcLeftSideDoubleDoor = img;

          objectStackPosition1.setElement(switcLeftSideDoubleDoor);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setIsObjSelected(true);
      //saveCanvasState(canvas);

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes(
        "Double_Door_Right_Left_SideLight"
      ) &&
      !i.itemUrl.includes("Full") &&
      (i.itemUrl.includes("NoGlass") ||
        i.itemUrl.includes("FlatWith") ||
        i.itemUrl.includes("With") ||
        i.itemUrl.includes("Tao"))
    ) {
      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassLeft,
        function (img) {
          let switchRightSidelightOption = img;

          objectStackPosition2.setElement(switchRightSidelightOption);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();

          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassRight,
        function (img) {
          let switcRightSidelightOption = img;

          objectStackPosition1.setElement(switcRightSidelightOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();

          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setIsObjSelected(true);
      //saveCanvasState(canvas);

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes(
        "Single_Door_Right_Left_SideLight"
      ) &&
      !i.itemUrl.includes("Full")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switcLeftSidelightOption = img;

          objectStackPosition1.setElement(switcLeftSidelightOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassLeft,
        function (img) {
          let switchDoorOptiont = img;

          objectStackPosition2.setElement(switchDoorOptiont);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();

      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassRight,
        function (img) {
          let switcRightSidelightOption = img;

          objectStackPosition3.setElement(switcRightSidelightOption);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setIsObjSelected(true);
      //saveCanvasState(canvas);

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes(
        "Double_Door_Right_Left_SideLight"
      ) &&
      i.itemUrl.includes("Full")
    ) {
      fabric.util.loadImage(
        rightDecorativeGlass,
        function (img) {
          let switcRightSideDoubleDoor = img;

          objectStackPosition2.setElement(switcRightSideDoubleDoor);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          ////saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switcLeftSideDoubleDoor = img;

          objectStackPosition1.setElement(switcLeftSideDoubleDoor);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          ////saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setIsObjSelected(true);
      ////saveCanvasState(canvas);

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else return;
    setObjectPerspective(canvas);
    canvas.discardActiveObject();
    canvas.renderAll();
    setIsSelectAll(false); // Adjust based on your state management
  }

  //We add option to selected group
  function addOption(gridUrl) {
    let gridOption = extractGridTypeFromUrl(gridUrl)
    setWindowGridType(gridOption);

    if (gridUrl.includes("NoGrid")) {
      optionUrl = gridUrl;

      canvas.getActiveObjects().forEach((object) => {
        if (object.type === "group" || object.type === "polygon") {
          setSrc(object, gridUrl);
        } else {
          return;
        }
      });
    }

    if (gridUrl.includes("Grid")) {
      optionUrl = gridUrl;
      canvas.getActiveObjects().forEach((object) => {
        if (object.type === "group")
          // const windowUrl = object._objects[2]._originalElement.currentSrc;
          setTypeName(object._objects[2].windowType);
        if (object.type === "polygon" && object.usePerspective)
          setTypeName(object.originObject._objects[2].windowType);

        canvas.renderAll();
        if (object.type === "group" || object.type === "polygon") {
          setSrc(object, gridUrl); // we go to this function to switch the image
          // object._objects[1].filters[0].color = object.itemColor;
          canvas.renderAll();
        } else {
          return;
        }
      });
    }
    if (gridUrl.includes("doors")) {
      optionUrl = gridUrl;
      rightDecorativeGlass = gridUrl;

      if (windowURL.includes("Sidelight1A")) {
        const regex = /326_.../;
        const replacementString = "118_948";
        const updatedURL = gridUrl.replace(regex, replacementString);
        rightSidelightDecorativeGlass = updatedURL;
        leftSidelightDecorativeGlass = updatedURL;
        rightLeftSidelightDecorativeGlassLeft = updatedURL;
        rightLeftSidelightDecorativeGlassRight = updatedURL;
      } else if (!windowURL.includes("SidelightFull")) {
        rightSidelightDecorativeGlass = gridUrl.replace("326_", "118_");
        rightLeftSidelightDecorativeGlassRight = gridUrl.replace("326", "118");
        leftSidelightDecorativeGlass = gridUrl.replace("326", "118");
        rightLeftSidelightDecorativeGlassLeft = gridUrl.replace("326", "118");
      }

      canvas.getActiveObjects().forEach((object) => {
        if (object.type === "group")
          // const windowUrl = object._objects[2]._originalElement.currentSrc;
          setTypeName(object._objects[2].windowType);
        if (object.type === "polygon" && object.usePerspective)
          setTypeName(object.originObject._objects[2].windowType);

        canvas.renderAll();
        if (object.type === "group" || object.type === "polygon") {
          if (!object.itemUrl.includes("Full")) {
            if (windowURL.includes("Sidelight1A")) {
              const regex = /326_.../;
              const replacementString = "118_948";
              const updatedURL = gridUrl.replace(regex, replacementString);
              rightSidelightDecorativeGlass = updatedURL;
            } else {
              rightSidelightDecorativeGlass = gridUrl.replace("326_", "118_");
              leftSidelightDecorativeGlass = gridUrl.replace("326", "118");
              rightLeftSidelightDecorativeGlassRight = gridUrl.replace(
                "326",
                "118"
              );
              rightLeftSidelightDecorativeGlassLeft = gridUrl.replace(
                "326",
                "118"
              );
            }
          }

          setSrc(object, gridUrl); // we go to this function to switch the image


          canvas.renderAll();
        } else {
          return;
        }
      });
      canvas.discardActiveObject();
      canvas.renderAll();
    }
    setIsSelectAll(false);
  }

  // We switch color
  function updateColor(colorChoice) {
    // Update windowGridOption


    // Unify to a single color format if needed
    const selectedColor = unifyColorFormat(colorChoice || itemColor);
    setItemColor(selectedColor);

    canvas.getActiveObjects().forEach(async (tmpObject) => {
      var object = tmpObject;
      if (tmpObject.type === "polygon" && tmpObject.usePerspective) {
        object = tmpObject.originObject;
      }
      object.itemColor = selectedColor;
      tmpObject.itemColor = selectedColor;

      if (object.type === "group") {
        const objectsInGroup = object.getObjects();

        if ((object.itemUrl && object.itemUrl.includes("Window")) || object.itemUrl.includes("GarageDoor")) {
          updateColorForObject(objectsInGroup[objectsInGroup.length - 1], selectedColor);

          // Retrieve windowGridType from the object at index 1
          const objectIndex1 = objectsInGroup[1];

          if (objectIndex1 && objectIndex1.windowGridType && objectIndex1.windowGridType.includes("Grid") && !objectIndex1.windowGridType.includes("NoGrid")) {
            updateColorForObject(objectIndex1, selectedColor);
          }
        }

        if (object.itemUrl && object.itemUrl.includes("Door")) {
          updateColorForObject(objectsInGroup[objectsInGroup.length - 3], selectedColor);
        }
      }

      if (tmpObject.itemType === 'accessory') {
        updateColorForObject(tmpObject, selectedColor);
      }

      if (object.type === "circle" || object.type === "rect" || object.type === "triangle") {
        object.set({
          fill: selectedColor,
          alpha: 0.9,
        });
      } else if (object.type === "path") {
        object.set({
          stroke: selectedColor,
        });
      }

      setObjectPerspective(canvas, tmpObject);
    });
    canvas.discardActiveObject();
    canvas.requestRenderAll();
    // //saveCanvasState(canvas);
    setIsSelectAll(false);
  }


  function updateColorForObject(obj, selectedColor) {
    if (!obj || !obj.filters) return;

    const hasBlendColorFilter = obj.filters.some(filter => filter instanceof fabric.Image.filters.BlendColor);

    if (hasBlendColorFilter) {
      // Clear and reapply filters
      obj.filters = [];
      obj.applyFilters();

      const filter = new fabric.Image.filters.BlendColor({
        color: selectedColor,
        mode: "multiply",
        alpha: 0.95,
      });
      obj.filters.push(filter);
      obj.applyFilters();
      canvas.requestRenderAll();
    }

  }

  function unifyColorFormat(color) {
    // Convert color to a single format (e.g., Hex)
    // Implementation here...
    return color;
  }



  const addItem = (window, tName, coords = null, isAICall = false, scaleFactor = null, realScaleFactor) => {

    // Default callback function placeholder
    const callBack = (properties) => {
      setIsItemAdded(true);
      //saveCanvasState(canvas);
      if (properties) {
        rightSidelightDecorativeGlass =
          properties.rightSidelightDecorativeGlass;
        leftSidelightDecorativeGlass = properties.leftSidelightDecorativeGlass;
        rightLeftSidelightDecorativeGlassLeft =
          properties.rightLeftSidelightDecorativeGlassLeft;
        rightLeftSidelightDecorativeGlassRight =
          properties.rightLeftSidelightDecorativeGlassRight;
      }
    };

    if (isSelectAll) {
      // Deselect any active selection or group
      canvas.discardActiveObject();
      canvas.requestRenderAll();

      // Loop through all objects on the canvas
      const allObjects = canvas.getObjects();
      allObjects.forEach(obj => {
        // Check if the object is a window that needs to be replaced
        if (obj.itemUrl && obj.itemUrl.includes("Window")) {
          // Calculate current properties based on object's data
          const newCoords = {
            left: obj.left,
            top: obj.top,
            scaleX: obj.scaleX,
            scaleY: obj.scaleY,
            width: obj.getScaledWidth(),
            height: obj.getScaledHeight(),
          };

          // Build properties for the new window
          const properties = buildProperties(window, tName, newCoords, isAICall, scaleFactor, realScaleFactor);

          // Remove the existing window object
          canvas.remove(obj);

          // Add the new window object
          addWindows(canvas, properties, callBack);
        }
      });

      // Ensure the canvas is updated
      canvas.requestRenderAll();

      // Turn off isSelectAll flag after processing
      setIsSelectAll(false);
    } else {
      // Handle a single item or AI-triggered addition
      let newCoords = null;
      const currentSelectedObject = getSelectedObject(); // Retrieve the currently selected object

      if (currentSelectedObject) {
        // Extract and prepare coordinates from the selected object
        newCoords = {
          left: currentSelectedObject.left,
          top: currentSelectedObject.top,
          scaleX: currentSelectedObject.scaleX,
          scaleY: currentSelectedObject.scaleY,
          width: currentSelectedObject.getScaledWidth(),
          height: currentSelectedObject.getScaledHeight(),
        };

        // Remove the currently selected object from the canvas
        canvas.remove(currentSelectedObject);
      }

      // Determine final coordinates to use (from AI or current selected object)
      const finalCoords = coords || newCoords;

      // Construct properties for the new item, using finalCoords
      const properties = buildProperties(window, tName, finalCoords, isAICall, scaleFactor, realScaleFactor);

      // Add the new window item to the canvas
      if (window.includes("Door") && !window.includes("GarageDoor")) {
        addDoors(canvas, properties, callBack, realScaleFactor);
      } else if (window.includes("Window")) {
        addWindows(canvas, properties, callBack, realScaleFactor);
      } else if (window.includes("Garage")) {
        addGarageDoors(canvas, properties, callBack);
      }

      canvas.renderAll();
    }
  };

  function buildProperties(window, tName, coords, isAICall, scaleFactor, realScaleFactor, designerTriggered) {
    // Construct and return the properties object based on provided parameters
    return {
      window: window,
      tName: tName,
      isDimensionsVisible: isDimensionsVisible,
      typeName: tName,
      windowURL: windowURL,
      windowGridType: windowGridType,
      optionUrl: optionUrl,
      glassToSearch: glassToSearch,
      stringToSearch: stringToSearch,
      stringToSearchSide: stringToSearchSide,
      rightDecorativeGlass: rightDecorativeGlass,
      rightSidelightDecorativeGlass: rightSidelightDecorativeGlass,
      leftSidelightDecorativeGlass: leftSidelightDecorativeGlass,
      rightLeftSidelightDecorativeGlassLeft:
        rightLeftSidelightDecorativeGlassLeft,
      rightLeftSidelightDecorativeGlassRight:
        rightLeftSidelightDecorativeGlassRight,
      designerTriggered,
      // Include coords if provided
      ...coords,
      isAICall, // Include the AI flag directly
      scaleFactor,
      realScaleFactor
      // More properties can be added as necessary
    };
  }


  function getSelectedObject() {
    return canvas.getActiveObject();
  }

  function initCanvasEvents(setIsDragging) {
    canvas.off("mouse:down");
    canvas.off("mouse:up");
    canvas.off("object:modified");
    canvas.off("object:moved");
    canvas.off("mouse:wheel");
    canvas.off("touch:gesture");
    canvas.off("object:selected");
    canvas.off("selection:cleared");
    canvas.off("selection:created");
    canvas.off("selection:updated");
    canvas.off("touch:drag");
    canvas.on({
      "touch:gesture": function (e) {
        if (e.e.touches && e.e.touches.length == 2) {
          pausePanning = true;
          var point = new fabric.Point(e.self.x, e.self.y);
          if (e.self.state === "start") {
            zoomStartScale = canvas.getZoom();
          }
          var delta = zoomStartScale * e.self.scale;
          canvas.zoomToPoint(point, delta);
          pausePanning = false;
        }
      },

      "object:added": (e) => {
        setHasCanvasChanged(true);
        saveCanvasState(canvas, hasCanvasChanged);
      },

      "object:removed": (e) => {
        setHasCanvasChanged(true);
        saveCanvasState(canvas, hasCanvasChanged);
      },


      "object:selected": function (e) {
        pausePanning = true;
        updateSkew();
        updateObjectSelected();
      },
      "selection:created": function (e) {
        const activeObject = canvas.getActiveObject();
        setSelectedObject(activeObject); // Set the selected object state


        if (e.selected && e.selected.length > 0) {
          const activeObject = e.selected[0];
          setSelectedObject(activeObject);
        } else {
          console.log("No target found in selection:created event.");
        }

        setIsObjectSelected(true);
        updateSkew();
        setObjectPerspective(canvas);
      },
      "selection:updated": function () {
        updateSkew();
        updateObjectSelected();
        setObjectPerspective(canvas);
      },
      "selection:cleared": function () {
        pausePanning = false;
        updateSkew();
        setSelectedObject(null);
        setIsObjectSelected(false);
        updateObjectSelected();
        if (!setIsObjSelected) {
          setIsOptionsPanelVisible(false);
        }
      },

      "touch:drag": function (e) {
        if (
          pausePanning === false &&
          undefined !== e.e.layerX &&
          undefined !== e.e.layerY
        ) {
          var currentX = e.e.layerX;
          var currentY = e.e.layerY;
          var xChange = currentX - lastX;
          var yChange = currentY - lastY;

          if (
            Math.abs(currentX - lastX) <= 50 &&
            Math.abs(currentY - lastY) <= 50
          ) {
            var delta = new fabric.Point(xChange, yChange);
            canvas.relativePan(delta);
          }

          lastX = e.e.layerX;
          lastY = e.e.layerY;
        }
      },
      "mouse:down": function (e) {

        setIsObjSelected(true);

        //  bringAccessoriesToFront(canvas);

        var aObj = canvas.getActiveObject();
        if (aObj) {
          // Logic for when an object is selected
          aObj.offsetX = e.e.offsetX;
          aObj.offsetY = e.e.offsetY;

          setIsSelectedItem(true);
          setIsItemAdded(true);
        } else {
          // Logic for when no object is selected
          setIsMixMatched(false);
          setIsSelectedItem(false);
          // $(".sizeBadge").remove(); // Uncomment if needed
          return;
        }

        if (e.target && e.target.type !== "group") {
          if (e.target.type === "polygon" && e.target.usePerspective && e.target.originObject.type === "group") {
            setTypeName(e.target.originObject.windowType);
            setWindowURL(e.target.originObject.itemUrl);
            setIsSelectedItem(true);
            e.target.opacity = 0.4;
            canvas.requestRenderAll();
            return;
          }
          // Additional logic for specific types of objects
          setIsSelectedItem(false);
          setIsOptionsPanelVisible(false);
          setIsSelectedShape(true);
          return;
        }

        // Further logic if needed
        if (e.target) {
          setTypeName(e.target.windowType);
          setWindowURL(e.target.itemUrl);
          setIsSelectedItem(true);
          e.target.opacity = 0.4;
        }

        canvas.requestRenderAll();
      },

      "mouse:up": function (e) {

        if (e.target) {
          e.target.opacity = 1;
          canvas.requestRenderAll();
        }
        setHasCanvasChanged(true);
        saveCanvasState(canvas, hasCanvasChanged);

      },

      "object:moved": function (e) {
        // setHasCanvasChanged(true);
        // saveCanvasState(canvas, hasCanvasChanged); 
        e.target.opacity = 0.4;
        // bringAccessoriesToFront(canvas);
      },
      "object:moving": function (e) {

        setIsDragging(true);
      },
      "object:modified": function (e) {
        setHasCanvasChanged(true);

        setIsDragging(false);
        // saveCanvasState(canvas, hasCanvasChanged);
      },

      // "mouse:wheel": function (opt) {
      //   //https://codepen.io/MarsAndBack/pen/MWKzYPe
      //   //saveCanvasState(canvas, canvas.getZoom());
      //   var delta = opt.e.deltaY;
      //   var zoom = canvas.getZoom();
      //   zoom *= 0.999 ** delta;
      //   if (zoom > 20) zoom = 20;
      //   if (zoom < 0.01) zoom = 0.01;
      //   canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      //   opt.e.preventDefault();
      //   opt.e.stopPropagation();
      // },
    });
  }

  function initKeyboardEvents() {
    document.addEventListener("keyup", (e) => {
      e.preventDefault(); // Let's stop this event.
      e.stopPropagation(); // Really this time.

      if (e.key || e.metaKey) {
        if (e.key === "Delete" || e.key === "Backspace") {
          setIsAvaillableDoorsPanelVisible(false);
          setIsPanelVisible(false);
          // removeObject();
        }
        if (e.ctrlKey && e.key === "z") {
          handleUndo();

        }
        if (e.ctrlKey && e.key === "y") {
          handleRedo()

        }
      }
    });
  }


  function removeObject() {
    var activeObject = canvas.getActiveObject();
    if (!activeObject) return;
    if (activeObject.type === "activeSelection") {
      activeObject.forEachObject(function (object) {
        canvas.remove(object);
        if (canvas.getActiveObject() < 1) {
          setIsItemAdded(false);
        }
      });
    } else {
      canvas.remove(activeObject);
      if (canvas.getActiveObject() < 1) {
        setIsItemAdded(false);
      }
    }
  }

  function checkForSeletedItems() {
    var aObj = canvas.getActiveObject();
    var isSelect = false;
    var typeName = null;
    if (aObj) {
      isSelect = true;
      typeName = aObj.windowType;
    }
    setIsSelectedItem(isSelect);
    setTypeName(typeName);


    return isSelect;

  }

  function checkSelectedObjectType() {
    var aObj = canvas.getActiveObject();
    let windowTypes = [];
    var ttName = null;
    var isMix = false;
    var isSame = true;
    var type = "";
    if (aObj && aObj.type === "polygon") {
      aObj = aObj.originObject;
    }
    if (aObj && aObj.type === "group") {
      if (aObj._objects && aObj._objects[2]) {
        var tName = aObj._objects[2].windowType;
        if (ttName && tName !== ttName) {
          // we check to seeif all items added on canvas are the same type, important for otions
          isSame = false;
        }
        type = tName;
        ttName = tName;
      }
      if (aObj._objects && !windowTypes.includes(aObj._objects[2].windowType)) {
        windowTypes.push(aObj._objects[2].windowType);
      }
      if (windowTypes.length > 1) {
        isMix = true;
      } else if (windowTypes.length === 1) {
        type = windowTypes[0];
      }
    } else if (aObj && aObj.type === "activeSelection") {
      aObj.getObjects().forEach(function (o) {
        if (o._objects && o._objects[2]) {
          var tName = o._objects[2].windowType;
          if (ttName && tName !== ttName) {
            // we check to seeif all items added on canvas are the same type, important for otions
            isSame = false;
          }
          type = tName;
          ttName = tName;
        }
        if (o._objects && !windowTypes.includes(o._objects[2].windowType)) {
          windowTypes.push(o._objects[2].windowType);
        }
      });
      if (windowTypes.length > 1) {
        isMix = true;
      } else if (windowTypes.length === 1) {
        type = windowTypes[0];
      }
    }
    setIsMixMatched(isMix);
    setIsSameType(isSame);
    setTypeName(type);

    return {
      isMix: isMix,
      isSame: isSame,
      type: type,
    };
  }

  function handleProjectName(pName) {
    setProjectName(pName);
  }

  async function toJson() {
    setIsProjectList(false);

    let latestBgProps = null;
    if (bgImageProps && bgImageProps.length > 0) {
      latestBgProps = bgImageProps[bgImageProps.length - 1];
      canvas['backgroundImageProps'] = latestBgProps;
    } else {
      latestBgProps = canvas['backgroundImageProps'];
    }

    // Generate PNG data URL from canvas
    let dataURL = canvas.toDataURL({
      format: 'png',
      multiplier: 1,
    });

    if (latestBgProps) {
      const { x, y, newWidth, newHeight } = latestBgProps;
      dataURL = canvas.toDataURL({
        format: 'png',
        left: x,
        top: y,
        width: newWidth,
        height: newHeight,
        multiplier: 1,
      });
    }

    const userId = currentUser.uid;
    const originalFilename = `${projectName.replaceAll(" ", "_")}_${userId}.png`;

    try {
      const storage = getStorage();

      // Storage ref for original treated image
      const treatedStorageRef = ref(
        storage,
        `usersProjectsImages/${userId}/canvasImages/canvasImagesTreated/${originalFilename}`
      );
      await uploadString(treatedStorageRef, dataURL, 'data_url');
      const originalDownloadURL = await getDownloadURL(treatedStorageRef);

      // Resize and upload PNG image
      const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
      };
      const file = dataURItoBlob(dataURL);
      let resizedDataURL = await new Promise((resolve, reject) => {
        Resizer.imageFileResizer(
          file,
          800,
          800,
          'PNG',
          100,
          0,
          (uri) => {
            resolve(uri);
          },
          'base64'
        );
      });

      const resizedFilename = `${projectName.replaceAll(" ", "_")}_${userId}_resized.png`;
      const resizedStorageRef = ref(
        storage,
        `usersProjectsImages/${userId}/canvasImages/canvasImagesResized/${resizedFilename}`
      );
      await uploadString(resizedStorageRef, resizedDataURL, 'data_url');
      const resizedDownloadURL = await getDownloadURL(resizedStorageRef);

      // Generate and upload WebP image
      let webPDataURL = canvas.toDataURL({
        format: 'webp',
        quality: 0.8,  // Adjust quality for WebP here
        multiplier: 1,
      });
      const webPFilename = `${projectName.replaceAll(" ", "_")}_${userId}_webp.webp`;
      const webPStorageRef = ref(
        storage,
        `usersProjectsImages/${userId}/canvasImages/canvasImagesWebP/${webPFilename}`
      );
      await uploadString(webPStorageRef, webPDataURL, 'data_url');
      const webPDownloadURL = await getDownloadURL(webPStorageRef);

      // Return an array of URLs for use in other functions
      return {
        originalTreated: originalDownloadURL,
        resized: resizedDownloadURL,
        webP: webPDownloadURL
      };

    } catch (error) {
      console.error("Error generating download URL or uploading:", error);
      // Optionally handle errors or state updates here
    }

    canvas.renderAll();
  }








  function toggleDimensionsOnCanvas(isVisible) {
    const objects = canvas.getObjects();

    objects.forEach((obj) => {
      if (obj.isDimension) { // Check the custom attribute
        obj.visible = isVisible;
      }
    });

    canvas.requestRenderAll();
  }



  const toggleDimensionsVisibility = () => {
    const newVisibility = !isDimensionsVisible;
    setIsDimensionsVisible(newVisibility); // Update state
    toggleDimensionVisibilityOnCanvas(canvas, !isDimensionsVisible);
    // Toggle visibility of all dimension objects on the canvas
    toggleDimensionsOnCanvas(newVisibility); // Use the new state immediately

  };

  const toggleDimensionVisibilityOnCanvas = (canvas, isVisible) => {
    canvas.getObjects().forEach((obj) => {
      if (obj.isDimension) {  // Check if the object has the isDimension property
        obj.set('visible', isVisible);  // Set visibility based on the checkbox state
      }
    });
    canvas.renderAll();  // Re-render the canvas to reflect visibility changes
  };


  const detectAndApplyColors = async (proposedColors) => {
    try {

      if (!isDetectionTriggered) {
        // console.log("Starting AI detection...");

        await triggerAIDetectionForOpenedProject(proposedColors);

        // console.log("AI detection complete.");
        setIsDetectionTriggered(true);
      } else {
        console.log("Detection already triggered, skipping detection.");
      }

      // console.log("Saving the project...");
      await saveUpdate();

      // console.log("Project saved successfully. Rendering canvas...");
      canvas.renderAll();  // Ensure that all objects are rendered on the canvas

      // Check if objects are on the canvas
      const objects = canvas.getObjects();

      if (objects.length === 0) {
        console.error("No objects detected on the canvas. Colors will not be applied.");
        return;
      }

      applyColorsToCanvasItems(proposedColors);

    } catch (error) {
      console.error('Error during detection and color application:', error);
    } finally {
      setDesignerTriggered(true); // Set the flag to true
      // Hide dimensions before starting the AI detection and color application
      toggleDimensionsOnCanvas(false);

    }
  };




  const triggerAIDetectionWithAnalytics = () => {
    // Log the event
    handleAnalyticsEvent('AIDetectionClick', 'AI Detection', 'AI Detection Triggered');

    // Call the original AI detection function
    triggerAIDetectionForOpenedProject();
  };

  async function triggerAIDetectionForOpenedProject(proposedColors) {
    // console.log("proposed colors in trigger", proposedColors)

    handleShowLoader();

    function constructImageUrl(userId, projectName) {
      const encodedUserId = encodeURIComponent(userId);
      const baseNameUnderscore = projectName.replaceAll(" ", "_");
      const encodedProjectNameOriginal = encodeURIComponent(`${baseNameUnderscore}_${userId}`);

      const imagePathOriginal = `usersProjectsImages/${encodedUserId}/canvasImages/${encodedProjectNameOriginal}`;
      const originalImageUrl = `https://firebasestorage.googleapis.com/v0/b/facadewebapp.appspot.com/o/${encodeURIComponent(imagePathOriginal)}?alt=media`;

      return originalImageUrl;
    }

    const imageURL = constructImageUrl(currentUser.uid, projectName);

    // console.log('Triggering AI detection for opened project...');

    try {
      await canvasBackground(imageURL); // Set the background image on the canvas
      await triggerDetection(
        addItem,
        setTypeName,
        setWindowURL,
        canvas,
        imageURL,
        projectScaleFactor,
        setProjectScaleFactor
      );
      // console.log('Detection complete, applying colors...');
      handleHideLoader();
      setIsDetectionTriggered(true);
    } catch (error) {
      console.error("Error setting up the canvas background:", error);
      console.error("Error during AI detection:", error);
      handleHideLoader();
    }
  }

  const cleanItemKey = (key) => {
    return key.replace(/<br>/g, '').trim().toLowerCase();
  };


  const applyColorsToCanvasItems = (proposedColors) => {
    // Ensure the canvas object is available
    if (!canvas) {
      console.error("Canvas is not available.");
      return;
    }

    const objects = canvas.getObjects();
    if (objects.length === 0) {
      console.error("No objects detected on the canvas.");
      return;
    } else {
      console.log("We found Objects on canvas");
    }

    objects.forEach(obj => {
      if (obj.type === 'group') {
        obj._objects.forEach(subObj => {
          if (subObj.itemType === 'window' || subObj.itemType === 'door' || subObj.itemType === 'garageDoor') {
            let colorName = '';

            // Determine the color name based on the item type
            if (subObj.itemType === 'window' && proposedColors['Windows']) {
              colorName = proposedColors['Windows'][0];
            } else if (subObj.itemType === 'door' && proposedColors['Door']) {
              colorName = proposedColors['Door'][0];
            } else if (subObj.itemType === 'garageDoor' && proposedColors['Garage Door']) {
              colorName = proposedColors['Garage Door'][0];
            }

            if (colorName) {
              const rgbValue = getColorByName(colorName);

              if (rgbValue) {

                const filter = new fabric.Image.filters.BlendColor({
                  color: rgbValue,
                  mode: "multiply",
                  alpha: 0.95,
                });

                // Clear existing filters first
                subObj.filters = [];

                // Add the filter and apply it
                subObj.filters.push(filter);
                subObj.applyFilters();

              } else {
                console.error(`RGB value not found for color: ${colorName}`);
              }
            } else {
              console.warn(`Color name not found for itemType: ${subObj.itemType}`);
            }
          }
        });
      }
    });

    // Render the canvas to reflect the changes
    canvas.renderAll();

    // Deselect any active objects
    canvas.discardActiveObject();

    // Render the canvas again to reflect the deselection
    canvas.renderAll();
  };









  const getColorByName = (name) => {
    if (!name) return '#ffffff';

    // Convert the name to lowercase
    let formattedName = name.toLowerCase();

    // Handle spaces and capitalize words after the first word
    formattedName = formattedName.split(' ').map((word, index) => {
      if (index === 0) return word;
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join('');

    // Normalize accents and special characters in French (optional but recommended)
    formattedName = formattedName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    // Now search for the formatted name in the color list
    const colorObj = colorList.find(color => color.name.toLowerCase() === formattedName.toLowerCase());

    return colorObj ? colorObj.color : '#ffffff';
  };



  function handleImageUploadSuccess(url) {

    saveNewProject(url);

  }

  async function saveNewProject(url) {
    await canvasBackground(url);

    const imageUrls = await toJson();  // This now returns an object with all URLs

    // console.log("background image links", imageUrls);

    setNewProjectImage(url);
    handleHideLoader();

    const collectionRef = collection(db, "Projects");
    const payload = {
      projectName: newProjectCreationName.replaceAll(" ", "_"),
      userId: currentUser.uid,
      imageUrls,  // Use the object with all URLs here
      imageName: `${newProjectCreationName.replaceAll(" ", "_") + "_" + currentUser.uid}`,
      projectJson: JSON.stringify(canvas.toJSON()), // Serialize the updated canvas state
      scaleFactor: scaleFactor,
      projectScaleFactor: projectScaleFactor,
      savedTimestamp: null
    };

    try {
      const docRef = await addDoc(collectionRef, payload);
      const projectId = docRef.id;

      // Update user's projectIds array
      const userDocRef = doc(db, "users", currentUser.uid);
      await updateDoc(userDocRef, {
        projectIds: arrayUnion(projectId)  // Add the new project ID to the array
      });

      setHasCanvasChanged(true);
      saveCanvasState(canvas, hasCanvasChanged);

    } catch (error) {
      console.error("Error creating new project:", error);
    } finally {
      setUploadComplete(true);
    }
  }



  const resetNewProjectImage = () => {
    setNewProjectImage('');
  };



  function loadProject(proj, scaleFact) {
    if (proj) {
      setIsLoaderFinish(false);
      setCanvasProject(proj);
      setIsSameType(true);

      try {
        // Load project JSON onto the canvas
        canvas.loadFromJSON(proj, () => {
          let objects = canvas.getObjects();
          objects.forEach((obj) => {
            if (obj.type === "polygon" && obj.usePerspective) {
              obj.originObject = getRelatedObject(canvas, obj);
              obj.objectCaching = false;
            }
          });

          // Check and apply the background image properties
          if (proj.backgroundImageProps) {
            const { x, y, newWidth, newHeight, src } = proj.backgroundImageProps;
            fabric.Image.fromURL(src, (img) => {
              img.set({
                left: x,
                top: y,
                width: newWidth,
                height: newHeight,
                selectable: false,
              });
              canvas.setBackgroundImage(img);
              canvas.renderAll();

              // Toggle dimensions visibility after the background image is set
              toggleDimensionVisibilityOnCanvas(canvas, isDimensionsVisible);
            });
          } else {
            canvas.renderAll();

            // Toggle dimensions visibility if no background image is set
            toggleDimensionVisibilityOnCanvas(canvas, isDimensionsVisible);
          }
        });
      } catch (error) {
        console.error("Failed to load project:", error);
      }

      // Load scale factor
      if (scaleFact !== undefined) {
        setScaleFactor(scaleFact);
      }

      setHasCanvasChanged(false);
    } else {
      console.error("Invalid proj object:", proj);
    }
  }


  // console.log("project name ",projectName)
  // console.log("project id ",projectId)
  // console.log("projects ",projects)


  function clearCanvas() {
    canvas.clear();
    setCalibrationDisplay(false)
    canvas.renderAll();
  }

  // clear canvas
  function deleteAll() {
    if (canvas.getObjects().length === 0) {
      toast.warn(t("app-noobjectoncanvas"));
      return;
    }

    if (window.confirm(t("app-deleteallmessage"))) {
      //saveCanvasState(canvas);
      canvas.remove(...canvas.getObjects());
      optionUrl = "";
      setIsSelectedItem(false);
      setIsSelectedShape(false);
      setIsOptionsPanelVisible(false);
      canvas.discardActiveObject();
      canvas.requestRenderAll();
      setIsOptionsPanelVisible(false);
      setIsAvaillableDoorsPanelVisible(false);
      setIsPanelVisible(false);
      setIsItemAdded(false);
      setIsSelectedItem(false);
    }
  }

  function selectAll() {
    setIsSelectAll(true);
    setIsOptionsPanelVisible(false);

    // Filter to select only objects that are visible, not dimension objects, and have a windowType including 'Window'
    const objectsToSelect = canvas.getObjects().filter(obj =>
      obj.visible && !obj.isDimension && obj.itemUrl && obj.itemUrl.includes("Window")
    );

    if (objectsToSelect.length > 0) {
      setIsSelectedItem(true);
      canvas.discardActiveObject();
      var sel = new fabric.ActiveSelection(objectsToSelect, {
        canvas: canvas,
      });

      canvas.setActiveObject(sel);
      canvas.requestRenderAll();
    } else {
      // Optionally handle the case where no objects meet the criteria
      console.log("No 'Window' type objects found or selected.");
      setIsSelectAll(true);
      return
    }
  }


  const deselectAllItems = () => {
    if (canvas) {
      canvas.discardActiveObject().renderAll();
    }
  };

  function removeObjects() {
    setIsSelectedItem(false);
    setIsAvaillableDoorsPanelVisible(false);
    removeAllObjects(canvas);
    if (canvas.getActiveObject() < 1) setIsItemAdded(false);
  }

  function updateObjectSelected() {
    var aObj = canvas.getActiveObject();
    if (aObj) {
      setIsObjSelected(true);
    } else {
      setIsObjSelected(false);
    }
  }

  function updateSkew() {
    var aObj = canvas.getActiveObject();
    if (aObj && aObj.type != "activeSelection") {
      setIsSkewVisible(true);
      setSkewX(aObj.get("skewX"));
      setSkewY(aObj.get("skewY"));
      // var absCoords = aObj.calcTransformMatrix();
      // aObj.setCoords();
      // setSkewSliderLeft(aObj.left + 'px');
      // setSkewSliderTop(aObj.top + 'px');
    } else {
      setIsSkewVisible(false);
    }
  }

  function handlePanel(itemToSearch) {

    if (itemToSearch === "Accessories") {
      handleAccessoriesPanel()
    } else {

      setItemToSearch(itemToSearch);
      setIsAvaillableDoorsPanelVisible(false);
      setIsOptionsPanelVisible(false);
      setIsPanelVisible(true);
    }
  }

  function handleAccessoriesPanel() {

    setIsAvaillableDoorsPanelVisible(false);
    setIsOptionsPanelVisible(false);
    setIsPanelVisible(false);
    setIsAccessoriesPanelVisible(true)

  }

  function handleAccessoriesSelectionPanel() {

    setIsAvaillableDoorsPanelVisible(false);
    setIsOptionsPanelVisible(false);
    setIsPanelVisible(false);
    setIsAccessoriesSelectionPanelVisible(true)

  }

  function handleOptionsPanel(itemToSearch) {
    setIsPanelVisible(false);
    if (itemToSearch === "transum") {
      setIsTransum(true);
      setIsOptionsPanelVisible(true);
      setIsAvaillableDoorsPanelVisible(false);
    } else setIsTransumPanelVisible(false);
    setIsOptionsPanelVisible(true);
    // setIsAvaillableDoorsPanelVisible(false);
  }

  function handleDoorsView(itemToSearch, URLToSave) {
    setTypeName(itemToSearch);

    setWindowURL(URLToSave);

    setIsAvaillableDoorsPanelVisible(false);
    setIsPanelVisible(false);
    setIsTransumPanelVisible(false);
    setIsOptionsPanelVisible(false);
    setIsAvaillableDoorsPanelVisible(true);
  }

  useEffect(() => {
    const fetchProjects = async () => {
      if (currentUser) {
        // console.log("uid ", currentUser.uid)
        const projectsQuerySnapshot = await getDocs(
          query(collection(db, "Projects"), where("userId", "==", currentUser.uid))
        );
        const projectsData = projectsQuerySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }));
        setProjects(projectsData);

      }
    };

    fetchProjects().then(() => {
      if (uploadComplete) {
        setUploadComplete(false);  // Reset uploadComplete after fetching projects
      }
    });

  }, [currentUser, uploadComplete, editComplete, isUpdateMode]);

  const handleProjectUpdate = async (projectName, canvasProject, scaleFactor, projectScaleFactor) => {
    const idFromName = projects.find((na) => na.projectName === projectName.replaceAll(" ", "_"));
    if (!idFromName) {
      console.error("Project not found:", projectName);
      toast.update(toastId, {
        render: "Project not found!",
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
      setIsUpdateMode(false);
      toast.dismiss(toastId);
      return;
    }

    const id = idFromName.id;

    try {
      // Check if the current user is a guest
      const isGuest = currentUser.email.includes('guest');

      // Set initial project JSON and background properties
      let latestBgProps = null;
      if (canvas['backgroundImageProps']) {
        latestBgProps = canvas['backgroundImageProps'];
      }

      // Generate image data URL from canvas
      let dataURL;
      if (latestBgProps) {
        const { x, y, newWidth, newHeight } = latestBgProps;
        dataURL = canvas.toDataURL({
          format: 'png',
          left: x,
          top: y,
          width: newWidth,
          height: newHeight,
          multiplier: 1,
        });
      } else {
        dataURL = canvas.toDataURL({
          format: 'png',
          multiplier: 1,
        });
      }

      if (!isGuest) {
        // Generate a unique filename for the original image
        const userId = currentUser.uid;
        const originalFilename = `${projectName.replaceAll(" ", "_")}_${userId}.png`;

        // Upload canvas image to Firebase Storage in canvasImagesTreated
        const storage = getStorage();
        const treatedStorageRef = ref(
          storage,
          `usersProjectsImages/${userId}/canvasImages/canvasImagesTreated/${originalFilename}`
        );
        await uploadString(treatedStorageRef, dataURL, 'data_url');
        const downloadURL = await getDownloadURL(treatedStorageRef);

        // Resize the image using react-image-file-resizer
        const dataURItoBlob = (dataURI) => {
          const byteString = atob(dataURI.split(',')[1]);
          const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          return new Blob([ab], { type: mimeString });
        };
        const file = dataURItoBlob(dataURL);

        let resizedDataURL = await new Promise((resolve, reject) => {
          Resizer.imageFileResizer(
            file,
            800,
            800,
            'PNG',
            100,
            0,
            (uri) => {
              resolve(uri);
            },
            'base64'
          );
        });

        const resizedFilename = `${projectName.replaceAll(" ", "_")}_${userId}_resized.png`;

        const resizedStorageRef = ref(
          storage,
          `usersProjectsImages/${userId}/canvasImages/canvasImagesResized/${resizedFilename}`
        );
        await uploadString(resizedStorageRef, resizedDataURL, 'data_url');

        // Update the project JSON with the image URL and background image properties
        const updatedProjectJson = canvas.toJSON([...customProps, 'backgroundImageProps']);
        updatedProjectJson.canvasProjectImage = downloadURL ? downloadURL : "MISSING_IMAGE_URL";

        setCanvasProject(updatedProjectJson);

        // Update project in Firestore
        await updateDoc(doc(db, "Projects", id), {
          projectName: projectName.replaceAll(" ", "_"),
          userId: currentUser?.uid,
          imageURL: downloadURL,
          projectJson: JSON.stringify(canvas.toJSON(customProps)),
          scaleFactor: scaleFactor,
          projectScaleFactor: projectScaleFactor,
          savedTimestamp: new Date().toISOString(),
        });

        toast.update(toastId, {
          render: "Project updated successfully!",
          type: toast.TYPE.SUCCESS,
          autoClose: 5000
        });
      } else {
        // If the user is a guest, just update the project JSON without uploading images
        const updatedProjectJson = canvas.toJSON([...customProps, 'backgroundImageProps']);
        updatedProjectJson.canvasProjectImage = "GUEST_SESSION_NO_IMAGE";

        setCanvasProject(updatedProjectJson);

        // Update project in Firestore without the image URLs
        await updateDoc(doc(db, "Projects", id), {
          projectName: projectName.replaceAll(" ", "_"),
          userId: currentUser?.uid,
          projectJson: JSON.stringify(canvas.toJSON(customProps)),
          scaleFactor: scaleFactor,
          projectScaleFactor: projectScaleFactor,
          savedTimestamp: new Date().toISOString(),
        });

        toast.update(toastId, {
          render: "Project updated successfully for guest session!",
          type: toast.TYPE.SUCCESS,
          autoClose: 5000
        });
      }

    } catch (error) {
      toast.update(toastId, {
        render: "Project update failed!",
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
      console.error("Error updating project:", error);
    } finally {
      setIsUpdateMode(true);
      toast.dismiss(toastId);
      setToastId(null);
    }
  };


  const handleGuestLogin = async () => {
    try {
      handleAnalyticsEvent('guestCliked', 'TryAsGuest', 'Try as a guest Triggered');
      const email = "guest_20240827T173821059Z@facadewebapp.com";
      const password = "TempPassword!123"; // Predefined guest credentials

      await signInWithEmailAndPassword(auth, email, password);

      // Set openAccount to true
      setAccount(true); // Ensure this is passed from the parent App component

    } catch (error) {
      console.error("Guest Login Error:", error);
      alert(t("auth-loginError"));
    }
  };



  const saveUpdate = async () => {

    const id = toast.info(<LoadingToast />, { autoClose: false });
    setToastId(id);

    try {


      await handleProjectUpdate(
        projectName,
        canvasProject,
        canvasProjectImage,
        scaleFactor,
        projectScaleFactor
      );

      // console.log("project name :", projectName)

    } catch (error) {
      toast.update(id, {
        render: "Project update failed!",
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
      console.error("Error updating project:", error);
    } finally {
      setIsAccountModalOpen(false);
      setIsUpdateMode(false);
      setUploadComplete(false);
      setEditComplete(true);
      toast.dismiss(toastId);
      setToastId(null);
    }
  };





  async function handleLogOut() {
    setProjectName("");
    try {
      await logout();
      toJson();
      setIsLoginOut(true)
      setShowAuthModal(false);
      setIsAccountModalOpen(false); // Close the modal on successful logout
    } catch {
      alert(t("auth-error"));
    }
    setUserRole("")
    clearCanvas();
  }

  function toDefault() {
    setUploadImageUrl("");
    deleteAll();
    canvasBackground();
    setShowAuthModal(true);
    setProjectName("");
  }

  function cutobjs() {
    setIsSelectedItem(false);
    setIsAvaillableDoorsPanelVisible(false);
    setIsPanelVisible(false);
    setIsOptionsPanelVisible(false);
    cutItem(canvas);
    if (canvas.getActiveObject() < 1) {
      setIsItemAdded(false);
    }
  }

  const handleRedo = () => {
    setHasCanvasChanged(true);
    redo(canvas, hasCanvasChanged);
  };

  // Example event for undo
  const handleUndo = () => {
    setHasCanvasChanged(true);
    undo(canvas, hasCanvasChanged);
  };

  const [perspective, setPerspective] = useState(false);

  const changePerspective = () => {
    canvas.isPerspective = !perspective;
    setPerspective(!perspective);
    setObjectPerspective(canvas, null, true);
  }

  //deselect for eraser mode
  function deselectItem() {
    if (canvas && canvas.getActiveObject()) {
      canvas.getActiveObject().set({ opacity: 1 });
      canvas.discardActiveObject();
      canvas.renderAll();
    }
  }

  const handleColorChange = (newColor) => {

    enableDraw(canvas, brushWidth, newColor);  // Pass the new color to your function
  };

  const handleFadeObjects = () => {


    // Change the button class to indicate that something is happening
    setButtonClass('before-after-button btn btn-warning');

    fadeObjects(canvas, () => {

      // Restore the original button class after fade operation
      setButtonClass('before-after-button btn btn-light');
    });


  };
  //calibration button
  const handleDropdownChange = (eventKey) => {
    if (eventKey === "removeDisplay") {
      setCalibrationDisplay(!calibrationDisplay)
      setShowCalibrationOverlay(false);
    }
    if (eventKey === 'toggleCalibration') {
      setShowCalibrationOverlay(!showCalibrationOverlay);
      setIsCalibrating(true)
      setCalibrationDisplay(false)
      if (!hasCalibration) {
        toggleCalibrationMode()
      }
    }
  };

  const toggleCalibrationMode = () => {
    setCalibrationMode(!isCalibrationMode);

    if (!isCalibrationMode) {
      // Create a square shape
      let rect = new fabric.Rect({
        height: 150,
        width: 150,
        fill: 'transparent',
        stroke: 'yellow',
        strokeWidth: 3,
        originX: 'center',
        originY: 'center',  // centering for group
      });

      // Create a text shape
      let text = new fabric.Text('Calibration', {
        fontSize: 20,
        originX: 'center',
        originY: 'center', // centering for group
        fill: "#4caf50",
      });

      // Create a group and add the square and text shapes to it
      let calibrationGroup = new fabric.Group([rect, text], {
        left: canvas.width / 2,
        top: 100,
        typeName: "calibration",
      });

      setCalibrationGroup(calibrationGroup);

      canvas.add(calibrationGroup);
      canvas.bringToFront(calibrationGroup);
      canvas.requestRenderAll();
    }
  };

  const deselectActiveObject = () => {
    if (canvas) { // Assuming fabricCanvas is the variable that holds your Fabric.js canvas instance in App
      canvas.discardActiveObject().requestRenderAll();
    }
  };


  // Function to set pixel length (called from Calibration component)

  const handlePixelLengthSet = (pixelLength) => {

    if (scaleFactor === 1) {
      const newScaleFactor = knownLengthRef.current / pixelLength;
      setScaleFactor(newScaleFactor);

      const newRealDimensions = {
        width: (pixelLength * newScaleFactor).toFixed(2),
        height: (pixelLength * newScaleFactor).toFixed(2), // or whatever calculation is appropriate
      };

      setRealDimensions(newRealDimensions);

    }
  };

  // Set cookies using the native browser API

  const handleAccept = () => {
    ;
    document.cookie = "user_consent_beta=true; path=/; max-age=2592000";
    document.cookie = "user_consent=true; path=/; max-age=2592000";
    setModalOpen(false);
    toast.dismiss("consent-toast");
    setTermsAccepted(true)
    setShowConsentBanner(false);  // Hide the banner
  };

  const handleDecline = () => {

    document.cookie = "user_consent_beta=false; path=/; max-age=2592000";
    document.cookie = "user_consent=false; path=/; max-age=2592000";
    setModalOpen(false);
    toast.dismiss("consent-toast");
    setShowConsentBanner(false);  // Hide the banner
  };

  function showConnectionRequiredToast(t) {
    toast.warning(t("assistant.toastMessage.connectionRequired"), {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  // Function to set all objects to be non-selectable
  function setObjectsSelectable(selectable) {
    canvas.forEachObject(function (obj) {
      obj.selectable = selectable;
    });
    canvas.discardActiveObject(); // Deselect any selected objects
    canvas.requestRenderAll();
  }


  function drawGrid(canvas, cellSize) {
    // Clear any existing grid and text first
    const existingLines = canvas.getObjects('line');
    const existingTexts = canvas.getObjects('text');
    canvas.remove(...existingLines, ...existingTexts);

    for (let i = 0; i <= (canvas.width / cellSize); i++) {
      // Vertical lines
      const verticalLine = new fabric.Line([i * cellSize, 0, i * cellSize, canvas.height], {
        stroke: '#ccc',
        selectable: false,
        evented: false,
        type: 'line'
      });
      canvas.add(verticalLine);

      // Horizontal lines
      const horizontalLine = new fabric.Line([0, i * cellSize, canvas.width, i * cellSize], {
        stroke: '#ccc',
        selectable: false,
        evented: false,
        type: 'line'
      });
      canvas.add(horizontalLine);

      // Coordinate labels
      const xLabel = new fabric.Text(i * cellSize + '', {
        left: i * cellSize,
        top: 0,
        fontSize: 24,
        fill: 'yellow',
        selectable: false,
        evented: false,
      });
      canvas.add(xLabel);

      const yLabel = new fabric.Text(i * cellSize + '', {
        left: 0,
        top: i * cellSize,
        fontSize: 24,
        fill: 'yellow',
        selectable: false,
        evented: false,
      });
      canvas.add(yLabel);
    }

    canvas.renderAll();
  }

  function rgbaToHex(rgba) {
    var parts = rgba.match(/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+)\)$/);
    delete (parts[0]);
    for (var i = 1; i <= 3; ++i) {
      parts[i] = parseInt(parts[i]).toString(16);
      if (parts[i].length === 1) parts[i] = '0' + parts[i];
    }
    return '#' + parts.join('').toUpperCase(); // "#RRGGBB"
  }

  const onEyedropperColorChange = (color) => {
    setEyePickerActiveColor(color); // Assuming this is your state update function
    // Any other logic you need when the color changes
  };

  const handleOpenContactForm = () => {
    setShowContactForm(true);
  };

  const toggleNewsVisibility = () => {
    setIsNewsVisible(!isNewsVisible);
  };

  const handleAddAccessory = (accessory, props) => {
    setIsAccessoryTypeSelected(false)


    addAccessoryImage(accessory, { ...props, canvas }, itemColor);
  };

  return (
    <div className="container-fluid p-0">

      <HelmetProvider>
        <Helmet>

          <title>{t('title')}</title>
          <link rel="canonical" href={canonicalUrl} />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
          <meta name="description" content={t('description')} />

          <link rel="alternate" hreflang="en" href="https://facadewebapp.com" />

          <link rel="alternate" hreflang="fr" href="https://facadewebapp.com/fr" />

          <link rel="alternate" hreflang="x-default" href="https://facadewebapp.com" />

        </Helmet>
      </HelmetProvider>

      <div className="App siteMainBackgroundColor">
        <header className="header">
          <ToastContainer position="bottom-left" />

          {showAuthModal && (
            <Auth
              closeSignup={setShowAuthModal}
              toJson={toJson}
              clearCanvas={clearCanvas}
              loginSet={login}
              setLogin={setLogin}
              isLoginOut={isLoginOut}
              setIsLoginOut={setIsLoginOut}
              setProjectName={setProjectName}
              setCloseAccount={setAccount}
              closeUpload={setUpload}
              setUserRole={setUserRole}
              termsAccepted={termsAccepted}
              handleAccept={handleAccept}
              handleDecline={handleDecline}
              sendWelcomeEmail={sendWelcomeEmail}
              handleLogOut={handleLogOut}
            />
          )}
          {account && (
            <Account
              closeAccount={setAccount}
              imageURL={setUploadImageUrl}
              canvasProjectImage={canvasProjectImage}
              canvasProject={canvasProject}
              loadProject={loadProject}
              handleProjectName={handleProjectName}
              projectName={projectName}
              setProjectName={setProjectName}
              newProjectCreationName={newProjectCreationName}
              setNewProjectCreationName={setNewProjectCreationName}
              setIsProjectList={setIsProjectList}
              isProjectList={isProjectList}
              downloadpng={downloadpng}
              canvas={canvas}
              uploadImageUrl={uploadImageUrl}
              backgroundImageStorageUrl={backgroundImageStorageUrl} // url to storage
              canvasImageName={canvasImageName}
              setBackgroundImageStorageUrl={setBackgroundImageStorageUrl}
              toJson={toJson}
              onShowLoader={handleShowLoader}
              onHideLoader={handleHideLoader}
              saveNewProject={saveNewProject}
              isLoaderFinish={isLoaderFinish}
              setIsLoaderFinish={setIsLoaderFinish}
              clearCanvas={clearCanvas}
              scaleFactor={scaleFactor}
              setScaleFactor={setScaleFactor}
              projectScaleFactor={projectScaleFactor}
              setProjectId={setProjectId}
              isUpdateMode={isUpdateMode}
              setIsUpdateMode={setIsUpdateMode}
              toastId={toastId}
              projects={projects} // Pass projects to Account
              setEditComplete={setEditComplete}
            />
          )}
          {upload && (
            <UploadImage
              imageURL={setUploadImageUrl}
              setProjectName={setProjectName}
              closeUpload={setUpload}
              closeAccount={setAccount}
              setNewProjectCreationName={setNewProjectCreationName}
              setBackgroundImageStorageUrl={setBackgroundImageStorageUrl}
              setCanvasImageName={setCanvasImageName}
              clearCanvas={clearCanvas}
              showLoader={handleShowLoader}
              hideLoader={handleHideLoader}
              toJson={toJson}
              onImageUploadSuccess={handleImageUploadSuccess}
              setImageName={setImageName}
            />
          )}
          <SiteNav
            closeUpload={setUpload}
            closeSignup={setShowAuthModal}
            setLogin={setLogin}
            setIsLoginOut={setIsLoginOut}
            closeAccount={setAccount}
            toJson={toJson}
            setIsProjectList={setIsProjectList}
            projectName={projectName}
            upload={upload}
            account={account}
            showCanvasPopupModal={showCanvasPopupModal}
            userRole={userRole}
            setShowModal={setShowModal}
            handleClose={handleClose}
            showConnectionRequiredToast={showConnectionRequiredToast}
            // termsAccepted={termsAccepted}
            handleOpenContactForm={handleOpenContactForm}
            triggerAIDetectionForOpenedProject={triggerAIDetectionForOpenedProject}
            isDimensionsVisible={isDimensionsVisible}
            toggleDimensionsVisibility={toggleDimensionsVisibility}
            handleLogOut={handleLogOut}
            setIsAccountModalOpen={setIsAccountModalOpen}
            isAccountModalOpen={isAccountModalOpen}
            setDesignerModalOpen={setDesignerModalOpen}
            handleAnalyticsEvent={handleAnalyticsEvent} // Pass the analytics function
            handleGuestLogin={handleGuestLogin}


          />

          <UserNoticeModal show={showNotice} handleCloseNotice={handleCloseNotice} />

          {/* <EditItems canvas={canvas} targetObject={selectedObject} /> */}
          <SecondaryNav />
        </header>
        <div>



          <Routes>
            <Route path="/cookies" element={null} />
            <Route path="/terms" element={null} />


            <Route path="/" element={!projectName && <Home setAccount={() => setAccount(true)} handleAnalyticsEvent={handleAnalyticsEvent} handleGuestLogin={handleGuestLogin} />} />

            <Route path="/fr" element={!projectName && <Home setAccount={() => setAccount(true)} handleAnalyticsEvent={handleAnalyticsEvent} handleGuestLogin={handleGuestLogin} />} />

          </Routes>


          <ContentModal
            show={isModalOpen}
            title={modalTitle}
            content={modalContent}
            setModalOpen={setModalOpen}
            handleAccept={handleAccept}
            handleDecline={handleDecline}
          />

          <EmailConfirmationSuccessModal
            show={showConfirmationModal}
            onClose={() => setShowConfirmationModal(false)}
            setShowCanvasPopupModal={setShowCanvasPopupModal}
          />

          {/* Your main app contents */}
          {showConsentBanner && (
            <CookieConsent
              location="bottom"
              buttonText={t("consent.accept")}
              cookieName="user_consent_beta"
              style={{ background: "#2B373B" }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              onAccept={handleAccept}
            >
              {t("consent.betaConsentText")}
              <span className="legalLinks">
                <Link to="/cookies">{t("consent.betaConsent")}</Link>
                <Link to="/terms">{t("consent.betaTerms")}</Link>
              </span>
            </CookieConsent>
          )}


          {/* Rest of your main app contents */}

          {/* {!currentUser && <Home closeSignup={setShowAuthModal} />} */}

          <div className="mainContainer">


            {<div className="mainContainerLeft">
              {/* {projectName && <div className="news-banner-container">
                <h2 className="news-title" onClick={toggleNewsVisibility}>
                  <img src={process.env.PUBLIC_URL + '/svg-and-icons/megaphoneIcon.svg'} alt="Megaphone Icon" className="icon" />
                  {t("betanews")}
                </h2>
                <div className="news-banner">
                  {isNewsVisible && projectName && <NewsBanner />}
                </div>
              </div>} */}
              {currentUser && projectName && !isCalibrating && (
                <div ref={topBarRef} className="topBar">

                  {/* <button
                    className="openProject btn btn-primary border-6 border-dark"
                    type="button"
                    size="sm"
                    title={t("save-project")}
                    name="saveProject"
                    // disabled={currentUser.email.startsWith("guest_")}  // Disable for guest users
                    onClick={saveUpdate}
                  >
                    <SaveIcon />
                    <span className="button-text p-1">
                      {!projectName ? "" : t("app-saveThisProject")}
                      {projectName.replaceAll("_", " ") ||
                        t("app-startOrOpenProject")}
                    </span>
                  </button> */}

                  <Tooltip
                    title={tooltipMessage}
                    position="top"
                    trigger="mouseenter"
                    disabled={!isGuestUser}
                  >
                    <button
                      className="openProject btn btn-primary border-6 border-dark"
                      type="button"
                      size="sm"
                      title={t("save-project")}
                      name="saveProject"
                      disabled={currentUser.email.startsWith("guest_")}  // Disable for guest users
                      onClick={saveUpdate}
                    >
                      <SaveIcon />
                      <span className="button-text p-1">
                        {!projectName ? "" : t("app-saveThisProject")}
                        {projectName.replaceAll("_", " ") ||
                          t("app-startOrOpenProject")}
                      </span>
                    </button>
                  </Tooltip>


                  {/* <span style={{ padding: "7px 10px" }}>
                    <CustomTooltip content="Hover me!" videoSrc="/gifs_app/perspective-tool.webm">
                      <Form.Check
                        checked={perspective}
                        type={'checkbox'}
                        label={`Perspective`}
                        id={`check-perspective`}
                        onChange={changePerspective}
                      />
                    </CustomTooltip>
                  </span> */}

                  <div className="undoRedoSmall">
                    <button
                      title={t("app-undo")}
                      className="actionBtn"
                      onClick={handleUndo}
                    >
                      <div className="undoIcon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z" />
                        </svg>
                      </div>
                    </button>
                    <button
                      title={t("app-redo")}
                      className="actionBtn"
                      onClick={handleRedo}
                    >
                      <div className="redoIcon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M18.4 10.6C16.55 8.99 14.15 8 11.5 8c-4.65 0-8.58 3.03-9.96 7.22L3.9 16c1.05-3.19 4.05-5.5 7.6-5.5 1.95 0 3.73.72 5.12 1.88L13 16h9V7l-3.6 3.6z" />
                        </svg>
                      </div>
                    </button>
                  </div>

                  {projectName && (
                    <div className="undoRedo ms-auto">
                      <button
                        title={t("app-undo")}
                        className="btn btn-primary me-3 border-6 border-dark"
                        onClick={handleUndo}
                      >
                        <UndoIcon />
                        <span className="button-text p-1">{t("app-undo")}</span>
                      </button>
                      <button
                        title={t("app-redo")}
                        className="btn btn-primary me-3 border-6 border-dark"
                        onClick={handleRedo}
                      >
                        <RedoIcon />
                        <span className="button-text p-1">{t("app-redo")}</span>
                      </button>
                    </div>
                  )}

                  <div className="zoomButton undoRedo ms-auto ">
                    <Button
                      title={t("app-zoom")}
                      onClick={() => setIsZoomModalOpen(true)}
                    >
                      <ZoomInIcon />
                      <span className="button-text  p-1">Zoom</span>
                    </Button>
                  </div>
                </div>
              )}
              {showCalibrationOverlay && (<div className="calibrationContainer">
                <Calibration
                  onKnownLengthChange={setKnownLength}
                  onDimensionToggle={setDimension}
                  onUnitToggle={setUnit}
                  setIsCalibrating={setIsCalibrating}
                  setCalibrationDisplay={setCalibrationDisplay}
                  setCalibrationMode={setCalibrationMode}
                  deselectActiveObject={deselectActiveObject} // to deselect the Calibration Tool
                  setShowCalibrationOverlay={setShowCalibrationOverlay}
                />

              </div>)}

              {(currentAction === 'erase' || currentAction === 'draw') && !isCalibrating && currentAction !== "null" && (
                <div className="eraserDrawContainer">
                  <EraserDraw handleColorChange={handleColorChange} onWidthChange={handleWidthChange} currentWidth={brushWidth} handleActionChange={handleActionChange} mode={modeType} />
                </div>
              )}

              {currentUser && projectName && !isCalibrating && (

                <div className="items" style={{ visibility: (modeType === 'draw' || modeType === 'erase') ? 'hidden' : 'visible' }}>
                  <div className="itemGroup"  >
                    {/* <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                    <div id="colorIndicator" style={{ width: '50px', height: '50px', border: '1px solid black', display: 'inline-block', marginBottom: '10px' }}></div>
                    <p id="coordsDisplay" style={{ marginTop: '5px' }}>{t("Coordinates")}</p>
                  </div> */}
                    <ItemSelection
                      handlePanel={handlePanel}
                      typeName={typeName}
                    />
                    {projectName &&
                      <ItemColors
                        updateColor={updateColor}
                        pickerColor={eyePickerActiveColor}
                        onColorChange={handleColorChange}

                      />
                    }
                    {isSelectedItem && isItemAdded && (
                      <Grids
                        isSelectedItem={isSelectedItem}
                        handleOptionsPanel={handleOptionsPanel}
                        addOption={addOption}
                        typeName={typeName}
                        windowURL={windowURL}
                        isSameType={isSameType}
                        isMixMatched={isMixMatched}
                        checkForSeletedItems={checkForSeletedItems}
                        checkSelectedObjectType={checkSelectedObjectType}
                        toggleOptionsPanel={toggleOptionsPanel}
                        isInCalibrationMode={isCalibrating}
                      />
                    )}

                    <ShapeSelection
                      canvas={canvas}
                      itemColor={itemColor}
                      setIsOptionsPanelVisible={setIsOptionsPanelVisible}
                      setIsItemAdded={setIsItemAdded}
                    />
                  </div>

                  <div className="actionGroup">
                    {projectName && (
                      <ToolsSelection
                        changeAction={(e) => handleActionChange(e)}
                        isObjectSelected={isObjectSelected}
                        copyItem={() => copyItem(canvas)}
                        selectAll={() => selectAll()}
                        cutItem={() => cutItem(canvas)}
                        removeAllObjects2={() => removeAllObjects2(canvas)}
                        pasteItem={() => pasteItem(canvas)}
                        copyAndPasteItem={() => copyAndPasteItem(canvas)}
                        flipX={() => objectfliphorizontal(canvas)}
                        flipY={() => objectflipvertical(canvas)}
                        drawGrid={() => drawGrid(canvas, 50)}
                        ungroupItems={() => unGroupItems(canvas)}
                        setModeType={setModeType}
                        setEraseMode={setEraseMode}
                        changePerspective={() => changePerspective()}
                      />
                    )}
                    {projectName && <div className={"calibration"}>
                      <DropdownButton
                        title={
                          <img
                            className='addItemsButton'
                            src="./icons/compast.png"
                            height="40px"
                            alt="Items"
                            title='Calibration'
                            style={{ filter: 'invert(100%)' }}
                          />
                        }
                        className="btn mt-1 btn-block pb-0 m-0"
                        variant="secondary"
                        menuVariant="dark"
                        size="sm"
                        drop="end"
                        autoClose="true"
                        onSelect={handleDropdownChange}
                      >
                        <Dropdown.Item eventKey="removeDisplay">{
                          !calibrationDisplay ? t("app-turnoncalibration") :
                            (scaleFactor === 1 ? t("app-calibrationdisplaynull") : t("app-turnoffcalibration"))
                        }
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="toggleCalibration">
                          {scaleFactor < 1 ? t("app-recalibration") : (showCalibrationOverlay ? t("app-hidecalibration") : t("app-showcalibration"))}
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>}
                  </div>
                </div>
              )}

              <div className="selectionView">
                {isPanelVisible && (
                  <div className="itemSelectionView">
                    <ItemSelectionView
                      itemToSearch={itemToSearch}
                      addItem={addItem}
                      typeName={typeName}
                      setIsPanelVisible={setIsPanelVisible}
                      handleDoorsView={handleDoorsView}
                      canvasHeight={canvasHeight}
                    />
                  </div>
                )}

                {isAccessoriesPanelVisible && (
                  <div className="itemSelectionView">
                    <AccessoriesSelectionView
                      setIsAccessoryTypeSelected={setIsAccessoryTypeSelected}
                      setIsPanelVisible={setIsPanelVisible}
                      setAccessoryType={setAccessoryType}
                      setIsAccessoriesPanelVisible={setIsAccessoriesPanelVisible}
                    />
                  </div>
                )}

                {isAccessoryTypeSelected && (
                  <div className="itemSelectionView">
                    <AccessoriesSelection
                      accessoryType={accessoryType}
                      addItem={handleAddAccessory}
                      setIsAccessoriesSelectionPanelVisible={setIsAccessoriesSelectionPanelVisible}
                      setIsAccessoriesPanelVisible={setIsAccessoriesPanelVisible}
                      setIsAccessoryTypeSelected={setIsAccessoryTypeSelected}
                    />
                  </div>
                )}

                {isOptionsPanelVisible && (
                  <div className="optionsSelectionView">
                    <OptionsSelectionView
                      isTransum={isTransum}
                      setIsTransum={setIsTransum}
                      addOption={addOption}
                      addTransum={addTransum}
                      windowURL={windowURL}
                      typeName={typeName}
                      setGlassToSearch={setGlassToSearch}
                      setStringToSearch={setStringToSearch}
                      setStringToSearchSide={setStringToSearchSide}
                      setIsOptionsPanelVisible={setIsOptionsPanelVisible}
                    />
                  </div>
                )}
                {isTransumPanelVisible && (
                  <div className="transumSelectionView">
                    <TransumSelectionView
                      addOption={addOption}
                      windowURL={windowURL}
                      typeName={typeName}
                      setGlassToSearch={setGlassToSearch}
                      setIsOptionsPanelVisible={setIsOptionsPanelVisible}
                    />
                  </div>
                )}

                {isAvaillableDoorsPanelVisible && (
                  <div className="doorsSelectionView">
                    <DoorsSelectionView
                      addItem={addItem}
                      windowURL={windowURL}
                      typeName={typeName}
                      itemToSearch={itemToSearch}
                      setIsOptionsPanelVisible={setIsOptionsPanelVisible}
                      setIsPanelVisible={setIsPanelVisible}
                      setIsAvaillableDoorsPanelVisible={
                        setIsAvaillableDoorsPanelVisible
                      }
                      setIsAccessoriesPanelVisible={setIsAccessoriesPanelVisible}
                    />
                  </div>
                )}
              </div>

              {showLoader && <div className="pre-loader"></div>}

              <div className="canvasAndBannersParent" style={{ display: projectName ? 'block' : 'none' }}>


                {currentUser && (

                  <div ref={canvasContainerRef} className={showCalibrationOverlay ? "canvasContainer showCalibrationOverlay" : "canvasContainer"}>

                    <canvas ref={mainCanvasRef} id="canv" />
                    {/* <CanvasTooltip typeName={typeName} /> */}
                    


                    <ContactFormModal show={showContactForm} handleClose={() => setShowContactForm(false)} />
                    {projectName && <button className={buttonClass} title={t("app-beforeAfter")}
                      onClick={handleFadeObjects}><CompareArrowsIcon className="d-md-none" /><span className="before-after-label">{t("app-beforeAfter")}</span> </button>}
                    {isGuestUser && (
                      <div className="guest-mode-title">
                        {t("app-guestMode")}
                      </div>
                    )}

                    {projectName && (
                      <div>
                        <button className="ai-Detect-button-AI btn btn-light" onClick={triggerAIDetectionWithAnalytics}>{t("ai-detect")}</button>
                        <div className="ai-Measurement-button">
                          <input
                            type="checkbox"
                            id="toggleDimensions"
                            checked={isDimensionsVisible}
                            onChange={toggleDimensionsVisibility}
                          />
                          <label htmlFor="toggleDimensions"> Dimensions</label>
                        </div>

                      </div>
                    )}

                    {calibrationDisplay && (
                      <CalibrationDisplay width={realDimensions.width} height={realDimensions.height} unit={unit} />
                    )}

                    {!projectName && currentUser && currentUser.emailVerified && (
                      <CanvasPopup
                        setUpload={setUpload}
                        setAccount={setAccount}
                        setShowCanvasPopupModal={setShowCanvasPopupModal}
                        showCanvasPopupModal={showCanvasPopupModal}
                      />
                    )}

                    <div>


                      {/* <Button onClick={handleOpen}>Open Facade Designer</Button> */}

                      {/* <FacadeDesigner showModal={showModal} handleClose={handleClose} projectId={projectId} projects={projects} newProjectImage={newProjectImage} resetNewProjectImage={resetNewProjectImage} userRole={userRole} /> */}
                      <FacadeDesigner
                        projects={projects}
                        projectId={projectId}
                        newProjectImage={newProjectImage}
                        resetNewProjectImage={resetNewProjectImage}
                        userRole={userRole}
                        isDesignerModalOpen={isDesignerModalOpen}
                        setDesignerModalOpen={setDesignerModalOpen}
                        handleAnalyticsEvent={handleAnalyticsEvent} // Pass the analytics function
                        applyColorsToCanvasItems={applyColorsToCanvasItems}
                        triggerAIDetectionForOpenedProject={triggerAIDetectionForOpenedProject}
                        canvas={canvas} // Pass the canvas as a prop
                        saveUpdate={saveUpdate}
                        isDetectionTriggered={isDetectionTriggered}
                        setIsDetectionTriggered={setIsDetectionTriggered}
                        detectAndApplyColors={detectAndApplyColors}

                      />

                    </div>



                    {projectName && (
                      <div className="zoom-buttons">
                        <div className="zoomout-wrap">
                          <ZoomAndPan
                            show={isZoomModalOpen}
                            onHide={() => setIsZoomModalOpen(false)}
                            canvas={canvas}
                            projectName={projectName}
                            canvasWidth={canvasWidth}
                            canvasHeight={canvasHeight}
                          />



                        </div>

                      </div>

                    )}

                  </div>
                  

                )}
                {/* <div className="bannersContainer"> */}
                {/* Sticky Banner under the canvas */}
                {/* <div className="sticky-banner">
                
                  {userPlan === "Free" && projectName && <Banner1 onContactTextClick={handleOpenContactForm} />}

                </div> */}

              </div>
            </div>}
          </div>
        </div>
      </div>
      <Routes>
        <Route path="/cookies" element={null} />
        <Route path="/terms" element={null} />


        <Route path="/" element={<Home setAccount={() => setAccount(true)} handleAnalyticsEvent={handleAnalyticsEvent} handleGuestLogin={handleGuestLogin} />} />

            <Route path="/fr" element={<Home setAccount={() => setAccount(true)} handleAnalyticsEvent={handleAnalyticsEvent} handleGuestLogin={handleGuestLogin} />} />

      </Routes>


      <ScrollToTopButton />
    </div>
  );
};

export default App;
