// src/colorHelper.js
export const colorList = [
    { color: "rgba(0, 0, 0, 1)", name: "black" },
    { color: "rgba(255, 255, 255, 1)", name: "white" },
    { color: "rgba(225, 233, 236, 1)", name: "iceWhite" },
    { color: "rgba(255, 243, 200, 1)", name: "sandstone" },
    { color: "rgba(187, 174, 155, 1)", name: "cashmere" },
    { color: "rgba(251, 239, 181, 1)", name: "maize" },
    { color: "rgba(219, 199, 164, 1)", name: "antiqueIvory" },
    { color: "rgba(216, 181, 140, 1)", name: "sandalwood" },
    { color: "rgba(248, 231, 157, 1)", name: "almond" },
    { color: "rgba(216, 180, 120, 1)", name: "wicker" },
    { color: "rgba(215, 183, 116, 1)", name: "canyonClay" },
    { color: "rgba(191, 155, 107, 1)", name: "harvestWheat" },
    { color: "rgba(183, 146, 102, 1)", name: "pebble" },
    { color: "rgba(216, 163, 95, 1)", name: "brownstone" },
    { color: "rgba(187, 165, 144, 1)", name: "tan" },
    { color: "rgba(147, 129, 107, 1)", name: "darkDrift" },
    { color: "rgba(139, 113, 96, 1)", name: "saddleBrown" },
    { color: "rgba(136, 71, 51, 1)", name: "venetianRed" },
    { color: "rgba(128, 0, 32, 1)", name: "burgundy" },
    { color: "rgba(166, 47, 32, 1)", name: "autumnRed" },
    { color: "rgba(196, 30, 58, 1)", name: "classicRed" },
    { color: "rgba(156, 49, 54, 1)", name: "brickRed" },
    { color: "rgba(166, 172, 158, 1)", name: "sage" },
    { color: "rgba(79, 101, 99, 1)", name: "ivyGreen" },
    { color: "rgba(93, 106, 89, 1)", name: "moonlitMoss" },
    { color: "rgba(2, 66, 14, 1)", name: "forestGreen" },
    { color: "rgba(94, 114, 125, 1)", name: "wedgewoodBlue" },
    { color: "rgba(103, 113, 122, 1)", name: "midnightSurf" },
    { color: "rgba(32, 49, 67, 1)", name: "oldWorldBlue" },
    { color: "rgba(167, 169, 168, 1)", name: "doverGray" },
    { color: "rgba(133, 126, 118, 1)", name: "storm" },
    { color: "rgba(118, 102, 76, 1)", name: "slate" },
    { color: "rgba(90, 87, 80, 1)", name: "windsweptSmoke" },
    { color: "rgba(158, 85, 17, 1)", name: "aluCopper" },
    { color: "rgba(77, 45, 24, 1)", name: "nutmeg" },
    { color: "rgba(71, 40, 29, 1)", name: "chestnutBrown" },
    { color: "rgba(75, 54, 32, 1)", name: "commercialBrown" },
    { color: "rgba(118, 40, 37, 1)", name: "burgundy" },
    { color: "rgba(103, 97, 85, 1)", name: "fineSand" },
    { color: "rgba(58, 50, 48, 1)", name: "antiqueBrown" },
    { color: "rgba(131, 122, 105, 1)", name: "windowBronze" },
    { color: "rgba(185, 179, 158, 1)", name: "aluminium" },
    { color: "rgba(63, 59, 47, 1)", name: "ironOre" },
    { color: "rgba(83, 89, 85, 1)", name: "graphite" },
    { color: "rgba(102, 84, 60, 1)", name: "espresso" },
    { color: "rgba(126, 128, 89, 1)", name: "mountainArbor" },
    { color: "rgba(116, 135, 150, 1)", name: "rockwellBlue" },
    { color: "rgba(56, 94, 115, 1)", name: "coastalBlue" },
    { color: "rgba(100, 100, 100, 1)", name: "anthracite" }
  ];
  