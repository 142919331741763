import firebase from "firebase/compat/app";
import { useState, useEffect } from "react";
// import { getAnalytics } from "firebase/analytics";
import "firebase/compat/storage"
import {createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, GoogleAuthProvider, sendEmailVerification ,signInWithPopup} from "firebase/auth"
import { getAuth } from "firebase/auth"
import { sendPasswordResetEmail as firebaseSendPasswordResetEmail } from "firebase/auth";
import 'firebase/compat/firestore';
import { getFirestore, setDoc } from "firebase/firestore";
import { getStorage, ref, listAll, deleteObject, uploadString, uploadBytes, getDownloadURL } from "firebase/storage";
import i18n from "./i18nextConf";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  authDomain: "facadewebapp.firebaseapp.com",
  projectId: "facadewebapp",
  storageBucket: "facadewebapp.appspot.com",
  messagingSenderId: "922098604172",
  appId: "1:922098604172:web:63582ed27866fc40ad43a9",
  measurementId: "G-NGH80G7BMD"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();
const auth = getAuth();
if (i18n.isInitialized) {
 
  auth.languageCode = i18n.language;
} else {
  i18n.on('initialized', () => {
  
    auth.languageCode = i18n.language;
  });
}
const db = getFirestore();

function signup(email, password){
  return createUserWithEmailAndPassword(auth, email, password);
  
}

function login(email, password){
  return signInWithEmailAndPassword(auth, email, password);
  
}

export async function googleSignIn() {
  const provider = new GoogleAuthProvider();
  await signInWithPopup(auth, provider);
}

export const sendPasswordResetEmail = (email) => {
  return firebaseSendPasswordResetEmail(auth, email, {
    url: "https://facadewebapp.com/?login=true",
  });
};

export const sendVerificationEmail = (actionCodeSettings) => {
  const currentUser = auth.currentUser;

  if (currentUser) {
    return sendEmailVerification(currentUser, actionCodeSettings)
      .then(() => {
        console.log('Verification email sent.');
      })
      .catch((error) => {
        console.error('Error sending verification email:', error);
      });
  } else {
    return Promise.reject(new Error("No user is signed in."));
  }
};


export {listAll, setDoc, ref, getStorage, storage, auth, signup, login, db, getDownloadURL, deleteObject, uploadString, uploadBytes,  firebase as default};
// const analytics = getAnalytics(app);

export function logout(){
return signOut(auth);
}

// custom hook

export function useAuth() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user && (user.emailVerified || user.email.startsWith('guest_'))) {
        // The user is signed in and has either verified their email or is a guest user
        setCurrentUser(user);
      } else {
        // The user is either not signed in or hasn't verified their email
        setCurrentUser(null);
      }
    });

    // Cleanup subscription on unmount
    return unsubscribe;
  }, []);

  return currentUser;
}
