import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useTranslation, Trans } from "react-i18next";
import { signInWithEmailAndPassword } from "firebase/auth"; // Import Firebase auth functions
import { auth } from "../../base";
import "../homepage/home.css";
import FeatureSection from './FeatureSection';
import Contact from "./Contact";
import PricingSection from "../../plans/PricingSection";
import WelcomeVideoModal from "./WelcomeVideoModal";  // Import the modal component

function Home(props) {
  const { i18n, t } = useTranslation();
  const [key, setKey] = useState(0);
  const [videoSources, setVideoSources] = useState({ webm: "", mp4: "" });
  const [showWelcomeVideo, setShowWelcomeVideo] = useState(false); // Track whether to show WelcomeVideoModal

  // Determine the correct video sources based on the language
  const getMediaSource = (language) => {
    const mediaPaths = {
      en: {
        webm: "/site-images-videos/aidetections_Naration_En.webm",
        mp4: "/site-images-videos/aidetections_Naration_En.mp4"
      },
      fr: {
        webm: "/site-images-videos/aidetections_Naration_Fr.webm",
        mp4: "/site-images-videos/aidetections_Naration_Fr.mp4"
      }
    };

    const currentLanguage = language.startsWith('fr') ? 'fr' : 'en';
    return mediaPaths[currentLanguage];
  };

  // Check if the user has seen the welcome video
 // Check if the user has seen the welcome video for the current language
 useEffect(() => {
  const currentLanguage = i18n.language.startsWith('fr') ? 'fr' : 'en';
  const hasSeenVideo = localStorage.getItem(`hasSeenVideo_${currentLanguage}`);

  if (hasSeenVideo !== 'true') {
    // If video hasn't been seen for the current language, show it and mark it as seen
    setShowWelcomeVideo(true);
    localStorage.setItem(`hasSeenVideo_${currentLanguage}`, 'true');  // Mark as seen for the current language
  } else {
    // Otherwise, hide the welcome video and show the fallback video
    setShowWelcomeVideo(false);
  }

  const sources = getMediaSource(i18n.language);
  setVideoSources(sources);
}, [i18n.language]);  // Run this effect every time the language changes

  useEffect(() => {
    // Trigger a re-render when the language changes
    setKey(prevKey => prevKey + 1);
  }, [i18n.language]);

  // const handleGuestLogin = async () => {
  //   try {
  //     props.handleAnalyticsEvent('click', 'TryAsGuest', 'Try as a guest Triggered');
  //     const email = "guest_20240827T173821059Z@facadewebapp.com";
  //     const password = "TempPassword!123"; // Predefined guest credentials

  //     await signInWithEmailAndPassword(auth, email, password);

  //     // Set openAccount to true
  //     props.setAccount(true); // Ensure this is passed from the parent App component

  //   } catch (error) {
  //     console.error("Guest Login Error:", error);
  //     alert(t("auth-loginError"));
  //   }
  // };

  return (
    <div>
      <header className="masthead pb-4">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 text-center">
              <h2 className="text-white pt-3">
                <Trans>{t('masthead.transformYourHome')}</Trans>
              </h2>
              <h1 className="text-light pt-4">
                {t('masthead.dontJustImagine')}
              </h1>
              <a
                href="#guest"
                className="btn btn-outline-light mt-3"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default link behavior
                  props.handleGuestLogin(); // Call the guest login function
                }}
              >
                {t('masthead.tryAsGuest')}
              </a>
            </div>

            {/* Conditionally render WelcomeVideoModal or the old video */}
            <div className="col-md-5">
              {showWelcomeVideo ? (
                <WelcomeVideoModal isInline={true} /> 
              ) : (
                <video key={videoSources.webm} autoPlay muted loop className="feature-video">
                  <source src={videoSources.webm} type="video/webm" />
                  <source src={videoSources.mp4} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </div>
        </div>
      </header>
      <section>
        <div className="container">
          <div key={key}>
            {/* Additional Feature Sections */}
            <FeatureSection
              titleKey="feature2.title"
              descriptionKey="feature2.description"
              galleryItems="doors"
              reverse={true}
            />

            <FeatureSection
              titleKey="feature5.title"
              descriptionKey="feature5.description"
              galleryItems="allDoors"
            />

            <FeatureSection
              titleKey="feature4.title"
              descriptionKey="feature4.description"
              galleryItems="doorsOptions"
              altKey="homepage.testimonials.altText"
              reverse={true}
            />

            <FeatureSection
              titleKey="feature6.title"
              descriptionKey="feature6.description"
              galleryItems="windows"
            />
            <FeatureSection
              titleKey="feature7.title"
              descriptionKey="feature7.description"
              galleryItems="windowsOptions"
              reverse={true}
            />

            <FeatureSection
              titleKey="feature3.title"
              descriptionKey="feature3.description"
              mediaKey="feature3.mediaPath"
              altKey="feature3.title"
              isVideo={true}
              videoWidth="40%"
              videoHeight="auto"
            />

            <div id="howitworks">
            <h1 className="pt-4">
                {t('homepage.how_it_works.title')}
              </h1>
             <FeatureSection
              titleKey="homepage.how_it_works.title"
              descriptionKeys={[
                "homepage.how_it_works.steps.step1",
                "homepage.how_it_works.steps.step2",
                "homepage.how_it_works.steps.step3",
                "homepage.how_it_works.steps.step4",
                "homepage.how_it_works.steps.step5",
                "homepage.how_it_works.steps.step6",
                "homepage.how_it_works.steps.step7",
                "homepage.how_it_works.steps.step8",
                "homepage.how_it_works.steps.step9",
              ]}
              closeSignup={props.closeSignup}
              handleGuestLogin={props.handleGuestLogin}
              mediaKey="feature1.mediaPath"
              altKey="homepage.how_it_works.title"
              isVideo={true}
              videoWidth="100%"  // Adjusted here to fill the container width controlled by CSS
              videoHeight="auto"
              showIcons={true}
              className="feature-video"  // Ensure this is applied if using custom video tag
            />
            </div>
          </div>
        </div>
      </section>
      <section id="contact">
        <Contact />
        <section id="designerBot">
        {/* <Contact /> */}
      </section>
      </section>
    </div>
  );
}

export default Home;
