import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import pricingPlans from './pricingPlans';
import "./pricingSection.css";

function PricingSection() {
    const { t } = useTranslation();
    const [billingCycle, setBillingCycle] = useState('monthly');  // Can be 'monthly' or 'annual'
  
    return (
      <div className="pricing-section">
        <div className="billing-cycle-switch">
          <button onClick={() => setBillingCycle('monthly')} className={billingCycle === 'monthly' ? 'active' : ''}>{t('monthly')}</button>
          <button onClick={() => setBillingCycle('annual')} className={billingCycle === 'annual' ? 'active' : ''}>{t('annual')}</button>
        </div>
        <div className="pricing-plans">
          {pricingPlans.map(plan => (
            <div key={plan.id} className="plan">
              <h3>{t(plan.name)}</h3>
              <h4>${billingCycle === 'monthly' ? plan.monthlyPrice : plan.annualPrice} / {t(billingCycle)}</h4>
              <ul>
                {plan.features.map((feature, index) => (
                  <li key={index}>{t(feature)}</li>
                ))}
              </ul>
              <button>{t('choose_plan')}</button>
            </div>
          ))}
        </div>
      </div>
    );
  }

export default PricingSection;


