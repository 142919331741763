import React from 'react';
import { Tooltip } from 'react-tippy';


export const CanvasTooltip = ({ typeName }) => {
    return (
      <Tooltip
        // Tooltip props...
        title={typeName}
        open={Boolean(typeName)}
        // any other props you need
      >
        <div style={{ visibility: 'hidden' }}>Hover me</div>
      </Tooltip>
    );
  };
export default CanvasTooltip;