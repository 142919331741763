import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './FeatureSection.css';
import Gallery from './Gallery';
import CollapsibleGallery from './CollapsibleGallery';
import windowsOptionsArray from "../../components/itemSelectionView/inventory/windowsOptionsArray";
import novatechDecorativeGlassArray from "../../components/itemSelectionView/inventory/novatechDecorativeGlassArray";
import doorsArray from "../../components/itemSelectionView/inventory/doorsArray";
import allDoorsArray from "../../components/itemSelectionView/inventory/allModelsDoorsArray";
import windowsArray from "../../components/itemSelectionView/inventory/windowsArray";
import garageDoorsArray from "../../components/itemSelectionView/inventory/garageDoorsArray";
import StepIcon from './StepIcon';
import CustomVideoModal from '../videoModal/CustomVideoModal';
import { getMediaType } from "../../helpers/helpers";
import Spinner from 'react-bootstrap/Spinner';

const getGalleryItems = (identifier) => {
    const galleryArrays = {
        windowsOptions: windowsOptionsArray,
        doorsOptions: novatechDecorativeGlassArray,
        garageDoors: garageDoorsArray,
        windows: windowsArray,
        doors: doorsArray,
        allDoors: allDoorsArray,
    };

    let items = galleryArrays[identifier] || [];

    if (identifier === 'doorsOptions') {
        items = items.filter(item => item.url.includes('326_948') && !item.url.includes("NoDecGlass"));
    }

    if (identifier === 'allDoors') {
        items = items.filter(item => item.url.includes('Single') && !item.url.includes("SideLight") && !item.url.includes("6ft") && !item.url.includes("8ft"));
    }

    if (identifier === 'windowsOptions') {
        items = items.filter(item => item.url.includes('Grid') && !item.url.includes("NoGrid"));
    }

    return items;
};

const FeatureSection = ({ closeSignup, handleGuestLogin, titleKey,descriptionKey, descriptionKeys, mediaKey, altKey, useCollapsibleGallery, galleryItems, reverse, isVideo, videoWidth, videoHeight, showIcons = false }) => {
    const baseVideoData = [
        { key: 'step1', src: '/howToVideos/video_step1.webm', thumbnail: '/howToVideos/thumbnails/thumb_step1.png' },
        { key: 'step2', src: '/howToVideos/video_step2.webm', thumbnail: '/howToVideos/thumbnails/thumb_step2.png' },
        { key: 'step3', src: '/howToVideos/video_step3.webm', thumbnail: '/howToVideos/thumbnails/thumb_step3.png' },
        { key: 'step4', src: '/howToVideos/video_step4.webm', thumbnail: '/howToVideos/thumbnails/thumb_step4.png' },
        { key: 'step5', src: '/howToVideos/video_step5.webm', thumbnail: '/howToVideos/thumbnails/thumb_step5.png' },
        { key: 'step6', src: '/howToVideos/video_step6.webm', thumbnail: '/howToVideos/thumbnails/thumb_step6.png' },
        { key: 'step7', src: '/howToVideos/video_step7.webm', thumbnail: '/howToVideos/thumbnails/thumb_step7.png' },
        { key: 'step8', src: '/howToVideos/video_step8.webm', thumbnail: '/howToVideos/thumbnails/thumb_step8.png' },
    ];
    const items = getGalleryItems(galleryItems);

    const { t, i18n } = useTranslation();

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    const howItWorksVideos = baseVideoData.map(video => {
        const src = video.src.replace('.webm', `_${i18n.language}.webm`);
        const mp4Src = src.replace('.webm', '.mp4');
        return {
            src: isSafari ? mp4Src : src,
            title: t(`step_titles.${video.key}`),
            thumbnail: video.thumbnail
        };
    });

    const [isLoading, setIsLoading] = useState(true);

    const handleVideoLoad = () => {
        setIsLoading(false);
    };

    const renderDescriptions = () => {
        if (Array.isArray(descriptionKeys)) {
            return (
                <div className="steps-container">
                    {descriptionKeys.map((key, index) => (
                        <div className="step" key={index}>
                            {/* Check if the current index is for the last step which requires special CTA */}
                            {index === descriptionKeys.length - 1 ? (
                                <div className="step-content" style={{ width: '100%' }}>
                                    <button
                                        className="tryApp btn-accent mt-1"
                                        onClick={(e) => {
                                            e.preventDefault(); // prevent the default navigation behavior
                                            handleGuestLogin();
                                        }}
                                    >
                                        {t('masthead.tryForFree')}
                                    </button>
                                </div>
                            ) : (
                                <div className="step-content">
                                    <div className="step-title">
                                        {t(`step_titles.step${index + 1}`)}
                                    </div>
                                    {t(key).split(/ -Voyez comment-| -See how-/).map((part, idx, parts) =>
                                        idx < parts.length - 1 ? (
                                            <React.Fragment key={idx}>
                                                <span>{part}</span>
                                                <br /> {/* Ensure the line break is a JSX element */}
                                                <CustomVideoModal
                                                    videos={howItWorksVideos}
                                                    initialVideoIndex={index}
                                                    title={t(`step_titles.step${index + 1}`)}
                                                >
                                                    <span className="step-link">
                                                        {t(key).includes(' -Voyez comment-') ? 'Voyez comment' : 'See how'}
                                                    </span>
                                                </CustomVideoModal>
                                            </React.Fragment>
                                        ) : <span key={idx}>{part}</span>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            );
        }
        // If it's not an array, assume it's a single step or content needs simple handling
        return (
            <div className={`feature-section ${reverse ? 'reverse' : ''}`}>
              {/* <div className="feature-media">
                {isVideo ? (
                  <video width={videoWidth} height={videoHeight} controls>
                    <source src={t(mediaKey)} type="video/mp4" />
                  </video>
                ) : (
                  <img src={t(mediaKey)} alt={t(titleKey)} />
                )}
              </div> */}
              <div className="feature-content">
                <h2>{t(titleKey)}</h2>
                <p>{t(descriptionKey)}</p>
              </div>
            </div>
          );
    };



    const renderMedia = () => {
        if (isVideo) {
            const videoSources = t(mediaKey, { returnObjects: true });

            const selectedVideoPath = isSafari ? videoSources.fallback : videoSources.default;
            // const videoType = getMediaType(selectedVideoPath);

            return (
                <>
                    {isLoading && <Spinner animation="border" variant="primary" />}
                    <video
                        className="img-fluid feature-video"
                        autoPlay
                        loop
                        muted
                        playsInline
                        controls
                        onCanPlayThrough={handleVideoLoad}
                        style={{ width: videoWidth, height: videoHeight, display: isLoading ? 'none' : 'block' }}
                    >
                        <source src={selectedVideoPath} type={isSafari ? "video/mp4" : "video/webm"} />
                        Your browser does not support the video tag.
                    </video>
                </>
            );
        } else {
            const imagePath = t(mediaKey);
            return <img src={imagePath} alt={t(altKey)} className="img-fluid feature-image" />;
        }
    };

    const renderGallery = () => {
        if (useCollapsibleGallery) {
            return <CollapsibleGallery items={items} titleKey={titleKey} />;
        }
        return <Gallery items={items} titleKey={titleKey} />;
    };

    return (
        <div className={`row mb-4 ${reverse ? 'flex-row-reverse' : ''}`}>
            <div className="col-md-6">
                {!galleryItems && !isVideo && <h2 className="section-title">{t(titleKey)}</h2>}
                {renderDescriptions()}
            </div>
            <div className="col-md-6">
                {galleryItems ? renderGallery() : renderMedia()}
            </div>
        </div>
    );
};

export default FeatureSection;
