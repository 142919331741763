import { fabric } from "fabric-with-erasing";
import { v1 as uuid } from "uuid";
import sizeAndPosition from "../../otherJS/doorsSizeAndPosition";
import sidesSizeAndPosition from "../../otherJS/sidesSizeAndPosition";
import windowBackground from "../../images/backgroundDouble.png";
import { dfItemColor, saveCanvasState } from "../../helpers/helpers";
import itemsBackgroundsArray from "../../inventaire/itemsBackgroundsArray";
import handlesAndSilsArray from "../../inventaire/handlesAndSilsArray";
import { getPsptFabricObj } from "../../helpers/fabricPerspective";

let glassAt1;
let glassAt2;
let glassAt3;
let glassAt4;
let glassAt1DD;
let glassAt2DD;
let glassAt3DD;
let glassAt4DD;
let glassAt5DD;
let rightSidelightDecorativeGlass1;
let rightSidelightDecorativeGlass2;
let windowImage;
let handleImage;
let silImage;
let optionImage;
let silUrl;
let handleUrl;
let backgroundUrl = windowBackground;

export function addDoors(canvas, properties, callbackFn, realScaleFactor) {
  var tName = properties.tName;
  var typeName = properties.typeName;
  var url = properties.window;
  var windowURL = windowURL;
  var optionUrl = optionUrl;
  var glassToSearch = glassToSearch;
  // var stringToSearch = stringToSearch;
  // var stringToSearchSide = stringToSearchSide;
  var rightDecorativeGlass = rightDecorativeGlass;
  var window = properties.window;
  var rightSidelightDecorativeGlass = properties.rightSidelightDecorativeGlass;
  var leftSidelightDecorativeGlass = properties.leftSidelightDecorativeGlass;
  var rightLeftSidelightDecorativeGlassLeft =
    properties.rightLeftSidelightDecorativeGlassLeft;
  var rightLeftSidelightDecorativeGlassRight =
    properties.rightLeftSidelightDecorativeGlassRight;

  var retProps = {
    rightSidelightDecorativeGlass: rightSidelightDecorativeGlass,
    leftSidelightDecorativeGlass: leftSidelightDecorativeGlass,
    rightLeftSidelightDecorativeGlassLeft:
      rightLeftSidelightDecorativeGlassLeft,
    rightLeftSidelightDecorativeGlassRight:
      rightLeftSidelightDecorativeGlassRight,
  };

  findBackground(tName);


  var doorsCutOut = window.lastIndexOf("/");
  var stringToSearch = window.substr(doorsCutOut + 1).slice(0, -4) + (properties.isAICall ? 'AI' : '');


  // Global variable to store the window (door) image's scale factors
  let windowScale = { scaleX: 1, scaleY: 1 };

  function applyScaleIfNeeded(image, properties, naturalWidth, naturalHeight, isWindowImage) {
    if (properties.isAICall) {
      // AI detection is on, calculate and apply scale factor
      const scaleX = properties.width / naturalWidth;
      const scaleY = properties.height / naturalHeight;

      // If it's the window (door) image, store the scale factors globally
      if (isWindowImage) {
        windowScale = { scaleX, scaleY };
      }

      image.scaleX = scaleX;
      image.scaleY = scaleY;
    }
  }

  // Apply the stored window (door) image's scale factors to the optionImage (decorative glass)
  function scaleOptionImage(optionImage) {
   
      optionImage.scaleX = windowScale.scaleX;
      optionImage.scaleY = windowScale.scaleY;
    
  }


  let promises = [];

  let noGlassTypes = [
    "NoGlass",
    "Oso",
    "Gendarme",
    "Era",
    "Linea",
    "Vog",
    "Flat",
    "Plaza",
    "Fantasia",
    "Tao"

  ];


  // let noGlassTypes = [
  //   "Oso",
  //   "Linea",
  //   "Vog",
  //  ];

  let includesNoGlass = noGlassTypes.some(type => {
    let regex = new RegExp(type + "(?!Glass)", "i");
    return regex.test(window);
  });



  var sideCutOut = window.lastIndexOf("S");
  var stringToSearchSide = window.substr(sideCutOut).slice(0, -4);
  
  // Append "AI" if isAICall is true
  if (properties.isAICall) {
      stringToSearchSide += "AI";
  }


  let singleDoorType =
    (typeName.includes("Single_Door") ||
      window.includes("Single_Door")) &&
      !window.includes("SideLight") &&
      !window.includes("Corriente") &&
      !window.includes("3AGlassAt123")
      ? true
      : false;
  let cutoutGlassAt2 =
    window.includes("3AGlassAt123") && !typeName.includes("SideLight")
      ? true
      : false;
  let DoublecutoutGlassAt2 =
    window.includes("3AGlassAt123") &&
      !typeName.includes("SideLight") &&
      typeName.includes("Double")
      ? true
      : false;
  let cutoutGlassAt3 =
    window.includes("3AGlassAt123") && !typeName.includes("SideLight")
      ? true
      : false;
  let DoublecutoutGlassAt3 =
    window.includes("3AGlassAt123") &&
      !typeName.includes("SideLight") &&
      typeName.includes("Double")
      ? true
      : false;
  let DoublecutoutGlassCorriente4At1 =
    window.includes("Corriente4") &&
      !typeName.includes("SideLight") &&
      window.includes("Double")
      ? true
      : false;
  let DoublecutoutGlassCorriente5At1 =
    window.includes("Corriente5") &&
      !typeName.includes("SideLight") &&
      window.includes("Double")
      ? true
      : false;
  let DoublecutoutGlassCorriente4At2 =
    window.includes("Corriente4") &&
      !typeName.includes("SideLight") &&
      window.includes("Double")
      ? true
      : false;
  let DoublecutoutGlassCorriente5At2 =
    window.includes("Corriente5") &&
      !typeName.includes("SideLight") &&
      window.includes("Double")
      ? true
      : false;
  let DoublecutoutGlassCorriente4At3 =
    window.includes("Corriente4") &&
      !typeName.includes("SideLight") &&
      window.includes("Double")
      ? true
      : false;
  let DoublecutoutGlassCorriente5At3 =
    window.includes("Corriente5") &&
      !typeName.includes("SideLight") &&
      window.includes("Double")
      ? true
      : false;
  let DoublecutoutGlassCorriente5At4 =
    window.includes("Corriente5") &&
      !typeName.includes("SideLight") &&
      window.includes("Double")
      ? true
      : false;
  let DoublecutoutGlassCorriente4 =
    window.includes("Corriente4") &&
      !typeName.includes("SideLight") &&
      window.includes("Double")
      ? true
      : false;
  let DoublecutoutGlassCorriente5 =
    window.includes("Corriente5") &&
      !typeName.includes("SideLight") &&
      window.includes("Double")
      ? true
      : false;
  let cutoutGlassCorriente4At1 =
    window.includes("Corriente4") && !typeName.includes("SideLight")
      ? true
      : false;
  let cutoutGlassCorriente5At1 =
    window.includes("Corriente5") && !typeName.includes("SideLight")
      ? true
      : false;
  let cutoutGlassCorriente4At2 =
    window.includes("Corriente4") && !typeName.includes("SideLight")
      ? true
      : false;
  let cutoutGlassCorriente5At2 =
    window.includes("Corriente5") && !typeName.includes("SideLight")
      ? true
      : false;
  let cutoutGlassCorriente4At3 =
    window.includes("Corriente4") && !typeName.includes("SideLight")
      ? true
      : false;
  let cutoutGlassCorriente5At3 =
    window.includes("Corriente5") && !typeName.includes("SideLight")
      ? true
      : false;
  let cutoutGlassCorriente5At4 =
    window.includes("Corriente5") && !typeName.includes("SideLight")
      ? true
      : false;
  let cutoutGlassCorriente4 =
    window.includes("Corriente4") && !typeName.includes("SideLight")
      ? true
      : false;
  let cutoutGlassCorriente5 =
    window.includes("Corriente5") && !typeName.includes("SideLight")
      ? true
      : false;
  let cutoutGlassCorriente4At1SSL =
    window.includes("Corriente4") && typeName.includes("SideLight")
      ? true
      : false;
  let cutoutGlassCorriente5At1SSL =
    window.includes("Corriente5") && typeName.includes("SideLight")
      ? true
      : false;
  let cutoutGlassCorriente4At2SSL =
    window.includes("Corriente4") && typeName.includes("SideLight")
      ? true
      : false;
  let cutoutGlassCorriente5At2SSL =
    window.includes("Corriente5") && typeName.includes("SideLight")
      ? true
      : false;
  let cutoutGlassCorriente4At3SSL =
    window.includes("Corriente4") && typeName.includes("SideLight")
      ? true
      : false;
  let cutoutGlassCorriente5At3SSL =
    window.includes("Corriente5") && typeName.includes("SideLight")
      ? true
      : false;
  let cutoutGlassCorriente5At4SSL =
    window.includes("Corriente5") && typeName.includes("SideLight")
      ? true
      : false;
  let cutoutGlassCorriente4SSL =
    window.includes("Corriente4") && typeName.includes("SideLight")
      ? true
      : false;
  let cutoutGlassCorriente5SSL =
    window.includes("Corriente5") && typeName.includes("SideLight")
      ? true
      : false;
  let cutoutGlassCorriente4At1DSL =
    window.includes("Corriente4") && typeName.includes("Double_Door_Right_Left_SideLight")
      ? true
      : false;
  let cutoutGlassCorriente5At1DSL =
    window.includes("Corriente5") && typeName.includes("Double_Door_Right_Left_SideLight")
      ? true
      : false;
  let cutoutGlassCorriente4At2DSL =
    window.includes("Corriente4") && typeName.includes("Double_Door_Right_Left_SideLight")
      ? true
      : false;
  let cutoutGlassCorriente5At2DSL =
    window.includes("Corriente5") && typeName.includes("Double_Door_Right_Left_SideLight")
      ? true
      : false;
  let cutoutGlassCorriente4At3DSL =
    window.includes("Corriente4") && typeName.includes("Double_Door_Right_Left_SideLight")
      ? true
      : false;
  let cutoutGlassCorriente5At3DSL =
    window.includes("Corriente5") && typeName.includes("Double_Door_Right_Left_SideLight")
      ? true
      : false;
  let cutoutGlassCorriente5At4DSL =
    window.includes("Corriente5") && typeName.includes("Double_Door_Right_Left_SideLight")
      ? true
      : false;
  let cutoutGlassCorriente4DSL =
    window.includes("Corriente4") && typeName.includes("Double_Door_Right_Left_SideLight")
      ? true
      : false;
  let cutoutGlassCorriente5DSL =
    window.includes("Corriente5") && typeName.includes("Double_Door_Right_Left_SideLight")
      ? true
      : false;
  let cutoutGlassAt1 =
    window.includes("3AGlassAt123") && !typeName.includes("SideLight")
      ? true
      : false;
  let DoublecutoutGlassAt1 =
    window.includes("3AGlassAt123") &&
      !typeName.includes("SideLight") &&
      typeName.includes("Double")
      ? true
      : false;
  let DoublecutoutGlassSidelightAt1 =
    window.includes("3AGlassAt123") &&
      typeName.includes("SideLight") &&
      typeName.includes("Double")
      ? true
      : false;
  let DoublecutoutGlassSidelightAt2 =
    window.includes("3AGlassAt123") &&
      typeName.includes("SideLight") &&
      typeName.includes("Double")
      ? true
      : false;
  let DoublecutoutGlassSidelightAt3 =
    window.includes("3AGlassAt123") &&
      typeName.includes("SideLight") &&
      typeName.includes("Double")
      ? true
      : false;
  let singleDoorRightSidelight =
    typeName.includes("Single_Door_Right") &&
      !window.includes("3AGlassAt123") &&
      !typeName.includes("Single_Door_Right_Left") &&
      !window.includes("Full")
      ? true
      : false;
  let singleDoorRightSidelight3AGlassAt123 =
    typeName.includes("Single_Door_Right") &&
      window.includes("3AGlassAt123") &&
      !typeName.includes("Single_Door_Right_Left") &&
      !window.includes("Full")
      ? true
      : false;

  let singleDoorRightSidelight3AGlassAt123at1 =
    typeName.includes("Single_Door_Right") &&
      window.includes("3AGlassAt123") &&
      !typeName.includes("Single_Door_Right_Left") &&
      !window.includes("Full")
      ? true
      : false;
  let singleDoorRightSidelight3AGlassAt123at2 =
    typeName.includes("Single_Door_Right") &&
      window.includes("3AGlassAt123") &&
      !typeName.includes("Single_Door_Right_Left") &&
      !window.includes("Full")
      ? true
      : false;
  let singleDoorLeftSidelight3AGlassAt123 =
    typeName.includes("Single_Door_Left") &&
      window.includes("3AGlassAt123") &&
      !typeName.includes("Single_Door_Right_Left") &&
      !window.includes("Full")
      ? true
      : false;
  let singleDoorLeftSidelight3AGlassAt123at1 =
    typeName.includes("Single_Door_Left") &&
      window.includes("3AGlassAt123") &&
      !typeName.includes("Single_Door_Right_Left") &&
      !window.includes("Full")
      ? true
      : false;
  let singleDoorLeftSidelight3AGlassAt123at2 =
    typeName.includes("Single_Door_Left") &&
      window.includes("3AGlassAt123") &&
      !typeName.includes("Single_Door_Right_Left") &&
      !window.includes("Full")
      ? true
      : false;
  let singleDoorLeftSidelight =
    window.includes("Single_Door_Left") && !window.includes("Full")
      ? true
      : false;
  let singleDoorRightLeftSidelightLeft =
    window.includes("Single_Door_Right_Left") && !window.includes("Full")
      ? true
      : false;
  let singleDoorRightLeftSidelightRight =
    window.includes("Single_Door_Right_Left") && !window.includes("Full")
      ? true
      : false;
  let singleDoorRightLeftSidelightLeftFull =
    typeName.includes("Single_Door_Right_Left") && window.includes("Full")
      ? true
      : false;
  let singleDoorRightLeftSidelightRightFull =
    typeName.includes("Single_Door_Right_Left") && window.includes("Full")
      ? true
      : false;
  let fullSidelightRight =
    window.includes("Full") && typeName.includes("Right_SideLight") && !window.includes("3AGlassAt123")
      ? true
      : false;
  let fullSidelightRightAt123 =
    window.includes("Full") && typeName.includes("Right_SideLight") && window.includes("3AGlassAt123")
      ? true
      : false;
  let fullSidelightLeft =
    window.includes("Full") && typeName.includes("Left_SideLight") && !typeName.includes("Right_Left_SideLight") && !window.includes("3AGlassAt123")
      ? true
      : false;
  let fullSidelightLeftAt123 =
    window.includes("Full") && typeName.includes("Left_SideLight") && !typeName.includes("Right_Left_SideLight") && window.includes("3AGlassAt123")
      ? true
      : false;
  let doubleDoorTypeLeft =
    typeName.includes("Double_Door") && !window.includes("Full") ? true : false;
  let doubleDoorTypeRight =
    typeName.includes("Double_Door") && !window.includes("Full") && !typeName.includes("Double_Door_Right_Left") ? true : false;
  let doubleDoorTypeLeftSidelightRL =
    typeName.includes("Double_Door_Right_Left") && !window.includes("Full")
      ? true
      : false;
  let doubleDoorTypeRightSidelightRL =
    typeName.includes("Double_Door_Right_Left") &&
      !window.includes("Full") &&
      !window.includes("3AGlassAt123")
      ? true
      : false;
  let doubleDoorTypeSidelightFull =
    typeName.includes("Double_Door_Right_Left") && window.includes("Full")
      ? true
      : false;
  let singleSidelightRLDoorTypeSidelightFull =
    typeName.includes("Single_Door_Right_Left") && window.includes("Full")
      ? true
      : false;
  let singleSidelightRLDoorTypeSidelight =
    window.includes("Single_Door_Right_Left") && !window.includes("Full")
      ? true
      : false;
  let singleSidelightRLDoorTypeSidelightFull3AGlassAt123 =
    typeName.includes("Single_Door_Right_Left") && window.includes("Full") && window.includes("3AGlassAt123")
      ? true
      : false;
  let singleSidelightRLDoorTypeSidelight3AGlassAt123 =
    typeName.includes("Single_Door_Right_Left") && !window.includes("Full") && window.includes("3AGlassAt123")
      ? true
      : false;
  let singleSidelightRLDoorTypeSidelightFull3AGlassAt123Glass1 =
    typeName.includes("Single_Door_Right_Left") && window.includes("Full") && window.includes("3AGlassAt123")
      ? true
      : false;
  let singleSidelightRLDoorTypeSidelight3AGlassAt123Glass1 =
    typeName.includes("Single_Door_Right_Left") && !window.includes("Full") && window.includes("3AGlassAt123")
      ? true
      : false;
  let singleSidelightRLDoorTypeSidelightFull3AGlassAt123Glass2 =
    typeName.includes("Single_Door_Right_Left") && window.includes("Full") && window.includes("3AGlassAt123")
      ? true
      : false;
  let singleSidelightRLDoorTypeSidelight3AGlassAt123Glass2 =
    typeName.includes("Single_Door_Right_Left") && !window.includes("Full") && window.includes("3AGlassAt123")
      ? true
      : false;
  let rightSidelight =
    typeName.includes("Door_Right_SideLight") && !window.includes("Full")
      ? true
      : false;
  let leftSidelight =
    typeName.includes("Door_Left_SideLight") && !window.includes("Full")
      ? true
      : false;
  let rightLeftDoor =
    typeName.includes("Right_Left_SideLight") && !window.includes("Full")
      ? true
      : false;
  let rightLeftSidelightRight =
    typeName.includes("Right_Left_SideLight") &&
      !window.includes("Full") &&
      !window.includes("Double")
      ? true
      : false;
  let doubleRightLeftSidelightLeft =
    typeName.includes("Double_Door_Right_Left_SideLight") &&
      !window.includes("Full")
      ? true
      : false;
  let doubleRightLeftSidelightRight =
    typeName.includes("Double_Door_Right_Left_SideLight") &&
      !window.includes("Full")
      ? true
      : false;
  let rightLeftSidelightLeft =
    typeName.includes("Right_Left_SideLight") &&
      !window.includes("Full") &&
      !window.includes("Double")
      ? true
      : false;
  let rightFullSidelightRight =
    window.includes("Full") && typeName.includes("Right_Left_SideLight")
      ? true
      : false;
  let leftFullSidelightLeft =
    window.includes("Full") && typeName.includes("Right_Left_SideLight")
      ? true
      : false;
  let doubleRightFullSidelightRight =
    typeName.includes("Double_Door") &&
      window.includes("Full") &&
      typeName.includes("Right_Left_SideLight")
      ? true
      : false;
  let doubleLeftFullSidelightLeft =
    typeName.includes("Double_Door") &&
      window.includes("Full") &&
      typeName.includes("Right_Left_SideLight")
      ? true
      : false;
  let doubleRightSidelight =
    typeName.includes("Double_Door") &&
    typeName.includes("Right_SideLight") &&
    !window.includes("Full");
  let doubleLeftSidelight =
    typeName.includes("Double_Door") &&
    typeName.includes("Left_SideLight") &&
    !window.includes("Full");

    const aiDetectedDimensions = { isAICall: properties.isAICall, aiWidth: properties.width, aiHeight: properties.height, aiTop: properties.top, aiLeft: properties.left, scaleX: properties.scaleX, scaleY: properties.scaleY, offsetX: properties.offsetX, offsetY: properties.offsetY }; // Example AI-detected dimensions
    const glassProperties = sizeAndPosition(stringToSearch, aiDetectedDimensions);
    
    const sideGlassProperties = sidesSizeAndPosition(stringToSearchSide, aiDetectedDimensions, window);

  // add the handle on doors
  if (tName.includes("Door")) {
    findHandle(tName);
    findSil(tName, window);


    let loadHandleImage = new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        handleUrl,
        function (oImg) {

          handleImage = oImg;

          handleImage.set({
            name:"handleImage",
            crossOrigin: "anonymous",
          });

          if (!properties.isAICall) {
            canvas.centerObject(handleImage);
          }
          resolve(handleImage);
        },
        {
          crossOrigin: "anonymous",
        }
      );
    });

    promises.push(loadHandleImage);

    let loadSilImage = new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        silUrl,
        function (oImg) {

          silImage = oImg;

          silImage.set({
            name:"silImage",
            crossOrigin: "anonymous",
          });

          if (!properties.isAICall) {
            canvas.centerObject(silImage);
          }
          resolve(silImage);
        },
        {
          crossOrigin: "anonymous",
        }
      );
    });

    promises.push(loadSilImage);

    glassAt1DD = backgroundUrl;

    // for no1 cutout in door type 3A
    let loadGlassAt1ImageDD = new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        glassAt1DD,
        function (oImg) {

          glassAt1DD = oImg;

          glassAt1DD.set({
            glassAt1DDId: uuid(),
            optionType: tName,
            crossOrigin: "anonymous",
            windowType: tName,

            ...(DoublecutoutGlassAt1 // 
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(DoublecutoutGlassSidelightAt1 // the second glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top1,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),

            ...(DoublecutoutGlassCorriente4 // the second from top glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(DoublecutoutGlassCorriente5 // the second from top glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(cutoutGlassCorriente4DSL // the second from top glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(cutoutGlassCorriente5DSL // the second from top glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
          });

          canvas.uniformScaling = false;

          resolve(glassAt1DD);
        },
        {
          crossOrigin: "anonymous",
        }
      );
    });

    promises.push(loadGlassAt1ImageDD);

    glassAt2DD = backgroundUrl;

    // for no1 cutout in door type 3A
    let loadGlassAt2ImageDD = new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        glassAt2DD,
        function (oImg) {

          glassAt2DD = oImg;

          glassAt2DD.set({
            glassAt2DDId: uuid(),
            optionType: tName,
            crossOrigin: "anonymous",
            windowType: tName,

            ...(DoublecutoutGlassAt2 // the right side of the double door
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top1,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(DoublecutoutGlassSidelightAt2 // the right side of the double door
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top1,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(DoublecutoutGlassCorriente5At1 // the second from top glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top1,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(DoublecutoutGlassCorriente4At1 // the second from top glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top1,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(cutoutGlassCorriente5At1DSL // the second from top glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top1,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(cutoutGlassCorriente4At1DSL // the second from top glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top1,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
          });

          canvas.uniformScaling = false;

          resolve(glassAt2DD);
        },
        {
          crossOrigin: "anonymous",
        }
      );
    });

    promises.push(loadGlassAt2ImageDD);
    glassAt3DD = backgroundUrl;

    // for no1 cutout in door type 3A
    let loadGlassAt3ImageDD = new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        glassAt3DD,
        function (oImg) {

          glassAt3DD = oImg;

          glassAt3DD.set({
            glassAt3DDId: uuid(),
            optionType: tName,
            crossOrigin: "anonymous",
            windowType: tName,

            ...(DoublecutoutGlassAt3 // the right side of the double door
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top2,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(DoublecutoutGlassSidelightAt3 // the right side of the double door
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top2,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(DoublecutoutGlassCorriente5At2 // the second from top glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top2,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(DoublecutoutGlassCorriente4At2 // the second from top glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top2,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(cutoutGlassCorriente5At2DSL // the second from top glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top2,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(cutoutGlassCorriente4At2DSL // the second from top glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top2,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
          });

          canvas.uniformScaling = false;

          resolve(glassAt3DD);
        },
        {
          crossOrigin: "anonymous",
        }
      );
    });

    promises.push(loadGlassAt3ImageDD);

    glassAt4DD = backgroundUrl;

    let loadGlassAt4ImageDD = new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        glassAt4DD,
        function (oImg) {

          glassAt4DD = oImg;

          glassAt4DD.set({
            glassAt4DDId: uuid(),
            optionType: tName,
            crossOrigin: "anonymous",
            windowType: tName,

            ...(DoublecutoutGlassCorriente4At3 // the second from top glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top3,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),

            ...(DoublecutoutGlassCorriente5At3 // the second from top glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top3,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(cutoutGlassCorriente4At3DSL // the second from top glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top3,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),

            ...(cutoutGlassCorriente5At3DSL // the second from top glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top3,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
          });

          canvas.uniformScaling = false;

          resolve(glassAt4DD);
        },
        {
          crossOrigin: "anonymous",
        }
      );
    });

    promises.push(loadGlassAt4ImageDD);

    glassAt5DD = backgroundUrl;

    let loadGlassAt5ImageDD = new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        glassAt5DD,
        function (oImg) {

          glassAt5DD = oImg;

          glassAt5DD.set({
            glassAt5DDId: uuid(),
            optionType: tName,
            crossOrigin: "anonymous",
            windowType: tName,

            ...(DoublecutoutGlassCorriente5At4 // the second from top glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top4,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(cutoutGlassCorriente5At4DSL // the second from top glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top4,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
          });

          canvas.uniformScaling = false;

          resolve(glassAt5DD);
        },
        {
          crossOrigin: "anonymous",
        }
      );
    });

    promises.push(loadGlassAt5ImageDD);

    glassAt1 = backgroundUrl;

    // for no1 cutout in door type 3A
    let loadGlassAt1Image = new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        glassAt1,
        function (oImg) {

          glassAt1 = oImg;

          glassAt1.set({
            glassAt1Id: uuid(),
            optionType: tName,
            crossOrigin: "anonymous",
            windowType: tName,

            ...(cutoutGlassCorriente4At1 // the second from top glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top1,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(cutoutGlassCorriente4At1SSL // the second from top glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top1,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(cutoutGlassCorriente5At1 // the second from top glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top1,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(cutoutGlassCorriente5At1SSL // the second from top glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top1,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
          });

          canvas.uniformScaling = false;

          resolve(glassAt1);
        },
        {
          crossOrigin: "anonymous",
        }
      );
    });

    promises.push(loadGlassAt1Image);

    glassAt2 = backgroundUrl;

    // for no 2 cutout in door type 3A
    let loadGlassAt2Image = new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        glassAt2,
        function (oImg) {

          glassAt2 = oImg;

          glassAt2.set({
            glassAt2Id: uuid(),
            optionType: tName,
            crossOrigin: "anonymous",
            windowType: tName,

            ...(cutoutGlassAt2 // the right side of the double door
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top1,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(fullSidelightRightAt123 // door for right sidelight full 123
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top1,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(fullSidelightLeftAt123 // door for right sidelight full 123
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top1,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(singleSidelightRLDoorTypeSidelightFull3AGlassAt123Glass1 // single door right left sidelight full
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top1,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(singleSidelightRLDoorTypeSidelight3AGlassAt123Glass1 // single door right left sidelight
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top1,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(DoublecutoutGlassSidelightAt2 // the right side of the double door
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top1,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(singleDoorRightSidelight3AGlassAt123at1 // the right side of the double door
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top1,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(singleDoorLeftSidelight3AGlassAt123at1 // the right side of the double door
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top1,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),

            ...(cutoutGlassCorriente4At2 // the middle glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top2,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(cutoutGlassCorriente4At2SSL // the middle glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top2,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(cutoutGlassCorriente5At2 // the middle glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top2,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(cutoutGlassCorriente5At2SSL // the middle glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top2,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
          });

          canvas.uniformScaling = false;

          resolve(glassAt2);
        },
        {
          crossOrigin: "anonymous",
        }
      );
    });

    promises.push(loadGlassAt2Image);

    glassAt3 = backgroundUrl;

    // for no1 cutout in door type 3A
    let loadGlassAt3Image = new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        glassAt3,
        function (oImg) {

          glassAt3 = oImg;

          glassAt3.set({
            glassAt3Id: uuid(),
            optionType: tName,
            crossOrigin: "anonymous",
            windowType: tName,

            ...(cutoutGlassCorriente4At3 // the second from bottom glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top3,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(cutoutGlassCorriente4At3SSL // the second from bottom glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top3,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(cutoutGlassCorriente5At3 // the second from bottom glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top3,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(cutoutGlassCorriente5At3SSL // the second from bottom glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top3,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(cutoutGlassAt3 // the bottom glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top2,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(fullSidelightRightAt123 // door for right sidelight full 123
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top2,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(fullSidelightLeftAt123 // door for right sidelight full 123
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top2,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(singleSidelightRLDoorTypeSidelightFull3AGlassAt123Glass2 // single door right left sidelight full
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top2,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(singleSidelightRLDoorTypeSidelight3AGlassAt123Glass2 // single door right left sidelight
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top2,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(DoublecutoutGlassSidelightAt3 // the right side of the double door
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top2,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(singleDoorRightSidelight3AGlassAt123at2 // the right side of the double door
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top2,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(singleDoorLeftSidelight3AGlassAt123at2 // the right side of the double door
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top2,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
          });

          canvas.uniformScaling = false;

          resolve(glassAt3);
        },
        {
          crossOrigin: "anonymous",
        }
      );
    });

    promises.push(loadGlassAt3Image);

    glassAt4 = backgroundUrl;

    let loadGlassAt4Image = new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        glassAt4,
        function (oImg) {

          glassAt4 = oImg;

          glassAt4.set({
            glassAt4Id: uuid(),
            optionType: tName,
            crossOrigin: "anonymous",
            windowType: tName,

            ...(cutoutGlassCorriente5At4 // the second from bottom glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top4,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(cutoutGlassCorriente5At4SSL // the second from bottom glass
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).left,
                top: sizeAndPosition(stringToSearch).top4,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
          });

          canvas.uniformScaling = false;

          resolve(glassAt4);
        },
        {
          crossOrigin: "anonymous",
        }
      );
    });

    promises.push(loadGlassAt4Image);

    rightDecorativeGlass = backgroundUrl;

    // for double doors

    let loadRightDecorativeGlassImage = new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        rightDecorativeGlass,
        function (oImg) {

          rightDecorativeGlass = oImg;

          rightDecorativeGlass.set({
            rightDecorativeGlassId: uuid(),
            optionType: tName,
            crossOrigin: "anonymous",
            windowType: tName,

            ...(doubleDoorTypeRight // the right side of the double door
              ? {
                height: glassProperties.height,
                left: glassProperties.DLeft,
                top: glassProperties.top,
                width: glassProperties.width,
              }
              : {}),
            ...(DoublecutoutGlassAt1 // the right side of the double door
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DLeft,
                top: sizeAndPosition(stringToSearch).top,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(doubleDoorTypeRightSidelightRL // the right side of the double door
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DDRLLeft,
                top: sizeAndPosition(stringToSearch).top,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
            ...(doubleDoorTypeSidelightFull // the right side of the double door
              ? {
                height: sizeAndPosition(stringToSearch).height,
                left: sizeAndPosition(stringToSearch).DDRLLeft,
                top: sizeAndPosition(stringToSearch).top,
                width: sizeAndPosition(stringToSearch).width,
              }
              : {}),
          });

          canvas.uniformScaling = false;

          resolve(rightDecorativeGlass);
        },
        {
          crossOrigin: "anonymous",
        }
      );
    });

    promises.push(loadRightDecorativeGlassImage);

    rightLeftSidelightDecorativeGlassLeft = backgroundUrl;

    let loadRightLeftSidelightDecorativeGlassLeftImage = new Promise(
      (resolve, reject) => {
        fabric.Image.fromURL(
          rightLeftSidelightDecorativeGlassLeft,
          function (oImg) {

            rightLeftSidelightDecorativeGlassLeft = oImg;

            rightLeftSidelightDecorativeGlassLeft.set({
              rightLeftSidelightDecorativeGlassLeftId: uuid(),
              optionType: tName,
              crossOrigin: "anonymous",
              windowType: tName,
              sidelightPosition: "left",

              ...(singleDoorRightLeftSidelightLeft
                ? {
                  height: sideGlassProperties.RLheightSL,
                  left: sideGlassProperties.RLleftSL,
                  top: sideGlassProperties.RLtopSL,
                  width: sideGlassProperties.RLwidthSL,
                }
                : {}),
              ...(doubleRightLeftSidelightLeft
                ? {
                  height:
                    sidesSizeAndPosition(stringToSearchSide).DDRLheightSL,
                  left: sidesSizeAndPosition(stringToSearchSide).DDRLleftSL,
                  top: sidesSizeAndPosition(stringToSearchSide).DDRLtopSL,
                  width: sidesSizeAndPosition(stringToSearchSide).DDRLwidthSL,
                }
                : {}),
            });

            canvas.uniformScaling = false;

            resolve(rightLeftSidelightDecorativeGlassLeft);
          },
          {
            crossOrigin: "anonymous",
          }
        );
      }
    );

    promises.push(loadRightLeftSidelightDecorativeGlassLeftImage);

    leftSidelightDecorativeGlass = backgroundUrl;

    let loadLeftSidelightDecorativeGlassImage = new Promise(
      (resolve, reject) => {
        fabric.Image.fromURL(
          leftSidelightDecorativeGlass,
          function (oImg) {

            leftSidelightDecorativeGlass = oImg;

            leftSidelightDecorativeGlass.set({
              leftSidelightDecorativeGlassId: uuid(),
              optionType: tName,
              crossOrigin: "anonymous",
              windowType: tName,
              sidelightPosition: "left",

              ...(singleDoorLeftSidelight && !window.includes("Sidelight1A")
                ? {
                  height: sidesSizeAndPosition(stringToSearchSide).heightSL,
                  left: sidesSizeAndPosition(stringToSearchSide).leftSL,
                  top: sidesSizeAndPosition(stringToSearchSide).topSL,
                  width: sidesSizeAndPosition(stringToSearchSide).widthSL,
                }
                : {}),
              ...(leftSidelight && window.includes("Sidelight1A")
                ? {
                  height: sideGlassProperties.heightSL,
                  left: sideGlassProperties.leftSL,
                  top: sideGlassProperties.topSL,
                  width: sideGlassProperties.widthSL,
                }
                : {}),
            });

            canvas.uniformScaling = false;

            resolve(leftSidelightDecorativeGlass);
          },
          {
            crossOrigin: "anonymous",
          }
        );
      }
    );

    promises.push(loadLeftSidelightDecorativeGlassImage);

    rightLeftSidelightDecorativeGlassRight = backgroundUrl;

    let loadRightLeftSidelightDecorativeGlassRightImage = new Promise(
      (resolve, reject) => {
        fabric.Image.fromURL(
          rightLeftSidelightDecorativeGlassRight,
          function (oImg) {

            rightLeftSidelightDecorativeGlassRight = oImg;

            rightLeftSidelightDecorativeGlassRight.set({
              rightLeftSidelightDecorativeGlassRightId: uuid(),
              optionType: tName,
              crossOrigin: "anonymous",
              windowType: tName,
              sidelightPosition: "left",
              //   originX: "center",
              //   originY: "center",

              ...(singleDoorRightLeftSidelightRight
                ? {
                  height: sideGlassProperties.RLheightSR,
                  left: sideGlassProperties.RLleftSR,
                  top: sideGlassProperties.RLtopSR,
                  width: sideGlassProperties.RLwidthSR,
                }
                : {}),
              ...(doubleRightLeftSidelightRight
                ? {
                  height:
                    sidesSizeAndPosition(stringToSearchSide).DDRLheightSR,
                  left: sidesSizeAndPosition(stringToSearchSide).DDRLleftSR,
                  top: sidesSizeAndPosition(stringToSearchSide).DDRLtopSR,
                  width: sidesSizeAndPosition(stringToSearchSide).DDRLwidthSR,
                }
                : {}),
            });

            canvas.uniformScaling = false;

            resolve(rightLeftSidelightDecorativeGlassRight);
          },
          {
            crossOrigin: "anonymous",
          }
        );
      }
    );

    promises.push(loadRightLeftSidelightDecorativeGlassRightImage);

    rightSidelightDecorativeGlass = backgroundUrl;

    let loadRightSidelightDecorativeGlassImage = new Promise(
      (resolve, reject) => {
        fabric.Image.fromURL(
          rightSidelightDecorativeGlass,
          function (oImg) {

            rightSidelightDecorativeGlass = oImg;

            rightSidelightDecorativeGlass.set({
              rightSidelightDecorativeGlassId: uuid(),
              optionType: tName,
              crossOrigin: "anonymous",
              windowType: tName,
              sidelightPosition: "right",

              ...(rightSidelight && !window.includes("Sidelight1A")
                ? {
                  height: sidesSizeAndPosition(stringToSearchSide).heightSR,
                  left: sidesSizeAndPosition(stringToSearchSide).leftSR,
                  top: sidesSizeAndPosition(stringToSearchSide).topSR,
                  width: sidesSizeAndPosition(stringToSearchSide).widthSR,
                }
                : {}),
              ...(rightSidelight && window.includes("Sidelight1A")
                ? {
                  height: sidesSizeAndPosition("Sidelight1A").heightSR,
                  left: sidesSizeAndPosition("Sidelight1A").leftSR,
                  top: sidesSizeAndPosition("Sidelight1A").topSR,
                  width: sidesSizeAndPosition("Sidelight1A").widthSR,
                }
                : {}),
              ...(rightLeftSidelightRight
                ? {
                  height: sidesSizeAndPosition(stringToSearchSide).heightSR,
                  left: sidesSizeAndPosition(stringToSearchSide).leftSR,
                  top: sidesSizeAndPosition(stringToSearchSide).topSR,
                  width: sidesSizeAndPosition(stringToSearchSide).widthSR,
                }
                : {}),
            });

            canvas.uniformScaling = false;

            resolve(rightSidelightDecorativeGlass);
          },
          {
            crossOrigin: "anonymous",
          }
        );
      }
    );

    promises.push(loadRightSidelightDecorativeGlassImage);

    promises.push(
      new Promise((resolve, reject) => {
        fabric.Image.fromURL(url, function (oImg) {

          windowImage = oImg;

          windowImage.set({
            windowItemId: uuid(),
            windowType: tName,
            windowImageURL: windowURL,
            name:"windowImage",
            crossOrigin: "anonymous",
            itemType: "door"
          });
          var filter = new fabric.Image.filters.BlendColor({
            color: dfItemColor,
            mode: "multiply",
            alpha: 0.95,
          });
          // if (!tName.includes("Door")) {
          //   windowImage.scaleToWidth(160);
          // }
          windowImage.filters.push(filter);
          windowImage.applyFilters();
          if (!properties.isAICall) {
            canvas.centerObject(windowImage);
          }
          resolve(windowImage);
        });
      })
    );


    optionUrl = backgroundUrl;

    //add options like grid and decGlass on the doors in the case where there is only one cutout in the door
    // this in conjunction with the object's cutoutSize in doorsSizeAndPosition
    promises.push(
      new Promise((resolve, reject) => {
        fabric.Image.fromURL(
          optionUrl,
          function (oImg) {

            optionImage = oImg;

            if (tName.includes("Door")) {

              optionImage.set({
                optionItemId: uuid(),
                optionType: tName,
                cutoutSize: glassToSearch,
                windowType: tName,
                durty: true,
                name: "optionImage",

                ...(cutoutGlassAt1 // the right side of the double door
                  ? {
                    height: sizeAndPosition(stringToSearch).height,
                    left: sizeAndPosition(stringToSearch).left,
                    top: sizeAndPosition(stringToSearch).top,
                    width: sizeAndPosition(stringToSearch).width,
                  }
                  : {}),
                ...(singleDoorRightSidelight3AGlassAt123 // the right side of the double door
                  ? {
                    height: sizeAndPosition(stringToSearch).height,
                    left: sizeAndPosition(stringToSearch).left,
                    top: sizeAndPosition(stringToSearch).top,
                    width: sizeAndPosition(stringToSearch).width,
                  }
                  : {}),
                ...(singleDoorLeftSidelight3AGlassAt123 // the right side of the double door
                  ? {
                    height: sizeAndPosition(stringToSearch).height,
                    left: sizeAndPosition(stringToSearch).left,
                    top: sizeAndPosition(stringToSearch).top,
                    width: sizeAndPosition(stringToSearch).width,
                  }
                  : {}),

                ...(doubleDoorTypeLeft // the left side door of the double door
                  ? {
                    height: glassProperties.height,
                    left: glassProperties.left,
                    top: glassProperties.top,
                    width: glassProperties.width,
                  }
                  : {}),
                ...(doubleDoorTypeSidelightFull // the right side of the double door
                  ? {
                    height: sizeAndPosition(stringToSearch).height,
                    left: sizeAndPosition(stringToSearch).DDRLLeft,
                    top: sizeAndPosition(stringToSearch).top,
                    width: sizeAndPosition(stringToSearch).width,
                  }
                  : {}),

                ...(singleDoorType // single door
                  ? {
                    height: glassProperties.height,
                    left: glassProperties.left,
                    top: glassProperties.top,
                    width: glassProperties.width,
                  }
                  : {}),
                ...(singleSidelightRLDoorTypeSidelightFull // single door right left sidelight full
                  ? {
                    height: sizeAndPosition(stringToSearch).height,
                    left: sizeAndPosition(stringToSearch).left,
                    top: sizeAndPosition(stringToSearch).top,
                    width: sizeAndPosition(stringToSearch).width,
                  }
                  : {}),
                ...(singleSidelightRLDoorTypeSidelight // single door right left sidelight
                  ? {
                    height: glassProperties.height,
                    left: glassProperties.left,
                    top: glassProperties.top,
                    width: glassProperties.width,
                  }
                  : {}),
                ...(singleSidelightRLDoorTypeSidelightFull3AGlassAt123 // single door right left sidelight full
                  ? {
                    height: sizeAndPosition(stringToSearch).height,
                    left: sizeAndPosition(stringToSearch).left,
                    top: sizeAndPosition(stringToSearch).top,
                    width: sizeAndPosition(stringToSearch).width,
                  }
                  : {}),
                ...(singleSidelightRLDoorTypeSidelight3AGlassAt123 // single door right left sidelight
                  ? {
                    height: sizeAndPosition(stringToSearch).height,
                    left: sizeAndPosition(stringToSearch).left,
                    top: sizeAndPosition(stringToSearch).top,
                    width: sizeAndPosition(stringToSearch).width,
                  }
                  : {}),

                ...(cutoutGlassCorriente4 // corriente4 door
                  ? {
                    height: sizeAndPosition(stringToSearch).height,
                    left: sizeAndPosition(stringToSearch).left,
                    top: sizeAndPosition(stringToSearch).top,
                    width: sizeAndPosition(stringToSearch).width,
                  }
                  : {}),
                ...(cutoutGlassCorriente4SSL // corriente4 door
                  ? {
                    height: sizeAndPosition(stringToSearch).height,
                    left: sizeAndPosition(stringToSearch).left,
                    top: sizeAndPosition(stringToSearch).top,
                    width: sizeAndPosition(stringToSearch).width,
                  }
                  : {}),
                ...(cutoutGlassCorriente4DSL // corriente4 door
                  ? {
                    height: sizeAndPosition(stringToSearch).height,
                    left: sizeAndPosition(stringToSearch).left,
                    top: sizeAndPosition(stringToSearch).top,
                    width: sizeAndPosition(stringToSearch).width,
                  }
                  : {}),
                ...(cutoutGlassCorriente5 // corriente4 door
                  ? {
                    height: sizeAndPosition(stringToSearch).height,
                    left: sizeAndPosition(stringToSearch).left,
                    top: sizeAndPosition(stringToSearch).top,
                    width: sizeAndPosition(stringToSearch).width,
                  }
                  : {}),
                ...(cutoutGlassCorriente5SSL // corriente4 door
                  ? {
                    height: sizeAndPosition(stringToSearch).height,
                    left: sizeAndPosition(stringToSearch).left,
                    top: sizeAndPosition(stringToSearch).top,
                    width: sizeAndPosition(stringToSearch).width,
                  }
                  : {}),
                ...(cutoutGlassCorriente5DSL // corriente4 door
                  ? {
                    height: sizeAndPosition(stringToSearch).height,
                    left: sizeAndPosition(stringToSearch).left,
                    top: sizeAndPosition(stringToSearch).top,
                    width: sizeAndPosition(stringToSearch).width,
                  }
                  : {}),

                ...(singleDoorLeftSidelight // door for left sidelight
                  ? {
                    height: glassProperties.height,
                    left: glassProperties.left,
                    top: glassProperties.top,
                    width: glassProperties.width,
                  }
                  : {}),

                ...(singleDoorRightSidelight && !window.includes("3AGlassAt123") // door for right sidelight
                  ? {
                    height: sizeAndPosition(stringToSearch).height,
                    left: sizeAndPosition(stringToSearch).left,
                    top: sizeAndPosition(stringToSearch).top,
                    width: sizeAndPosition(stringToSearch).width,
                  }
                  : {}),

                ...(singleDoorRightSidelight // door for right sidelight
                  ? {
                    height: sizeAndPosition(stringToSearch).height,
                    left: sizeAndPosition(stringToSearch).left,
                    top: sizeAndPosition(stringToSearch).top,
                    width: sizeAndPosition(stringToSearch).width,
                  }
                  : {}),

                ...(fullSidelightRight // door for right sidelight full
                  ? {
                    height: sizeAndPosition(stringToSearch).height,
                    left: sizeAndPosition(stringToSearch).left,
                    top: sizeAndPosition(stringToSearch).top,
                    width: sizeAndPosition(stringToSearch).width,
                  }
                  : {}),
                ...(fullSidelightRightAt123 // door for right sidelight full 123
                  ? {
                    height: sizeAndPosition(stringToSearch).height,
                    left: sizeAndPosition(stringToSearch).left,
                    top: sizeAndPosition(stringToSearch).top,
                    width: sizeAndPosition(stringToSearch).width,
                  }
                  : {}),
                ...(fullSidelightLeftAt123 // door for right sidelight full 123
                  ? {
                    height: sizeAndPosition(stringToSearch).height,
                    left: sizeAndPosition(stringToSearch).left,
                    top: sizeAndPosition(stringToSearch).top,
                    width: sizeAndPosition(stringToSearch).width,
                  }
                  : {}),

                // ...(rightLeftSidelightRight // door for sidelights left right
                //   ? {
                //     height: sizeAndPosition(stringToSearch).height,
                //     left: sizeAndPosition(stringToSearch).left,
                //     top: sizeAndPosition(stringToSearch).top,
                //     width: sizeAndPosition(stringToSearch).width,
                //   }
                //   : {}),

                ...(fullSidelightLeft // door for left and left right sidelight full
                  ? {
                    height: sizeAndPosition(stringToSearch).height,
                    left: sizeAndPosition(stringToSearch).left,
                    top: sizeAndPosition(stringToSearch).top,
                    width: sizeAndPosition(stringToSearch).width,
                  }
                  : {}),

                crossOrigin: "anonymous",
              });
            } else if (optionUrl !== backgroundUrl) {
              var filter = new fabric.Image.filters.BlendColor({
                color: dfItemColor,
                mode: "multiply",
                alpha: 0.95,
              });
            }

            optionImage.filters.push(filter);
            optionImage.applyFilters();


            canvas.uniformScaling = false;

            if (optionImage) {
              resolve(optionImage);
            } else {
              // If the image fails to load, reject the promise
              reject("Failed to load image");
            }
          },
          {
            crossOrigin: "anonymous",
          }
        );
      })
    );

    // add backgroung Image
    promises.push(
      new Promise((resolve, reject) => {
        fabric.Image.fromURL(
          backgroundUrl,
          function (oImg) {


            let backgroundImage = oImg;
            backgroundImage.set({
              backgroundItemId: uuid(),
              name: "backgroundImage",
              crossOrigin: "anonymous",
            });
            // if (!tName.includes("Door")) {
            //   backgroundImage.scaleToWidth(160);
            // }
            if (!properties.isAICall) {
              canvas.centerObject(backgroundImage);
            }
            canvas.uniformScaling = false;

            if (backgroundImage) {
              resolve(backgroundImage);
            } else {
              // If the image fails to load, reject the promise
              reject("Failed to load image");
            }
          },
          {
            crossOrigin: "anonymous",
          }
        );
      })
    );

    Promise.all(promises)
      .then((images) => {

        // Assuming `properties` include AI detection results
        if (properties.isAICall) {
          // const referenceProperties = {
          //   top: 90, // Original top position
          //   left: 441, // Original left position
          // };

          // // Reference image before scaling but after positioning adjustment
          // const referenceImage = images[16]; // This is your reference object

          // // Adjust position relative to reference image based on its natural size
          // images.forEach((image, index) => {
          //   if (![0, 1, 16, 18].includes(index)) {
          //     const deltaX = referenceProperties.left - referenceImage.left;
          //     const deltaY = referenceProperties.top - referenceImage.top;

          //     // Here, adjust the position before scaling
          //     image.set({
          //       left: image.left + deltaX,
          //       top: image.top + deltaY,
          //     });
          //   }
          // });

          images.forEach((image, index) => {

            const naturalWidth = image.naturalWidth || image.width;
            const naturalHeight = image.naturalHeight || image.height;
            // Check if the current image is the window (door) image
            const isWindowImage = index === 16;

            // Apply scaling if needed based on AI detection
            applyScaleIfNeeded(image, properties, naturalWidth, naturalHeight, isWindowImage);
          });
          const exclusions = [0, 1, 16, 18]; // Indices for sillImage, handleImage, windowImage, and backgroundImage

          // Only scale images not in the exclusions list
          images.forEach((image, index) => {
            if (!exclusions.includes(index)) {
              scaleOptionImage(image);
            }
          });
        }

        let silImage = images[0];
        let handleImage = images[1];
        let glassAt1DD = images[2];
        let glassAt2DD = images[3];
        let glassAt3DD = images[4];
        let glassAt4DD = images[5];
        let glassAt5DD = images[6];
        let glassAt1 = images[7];
        let glassAt2 = images[8];
        let glassAt3 = images[9];
        let glassAt4 = images[10];
        let rightDecorativeGlass = images[11];
        let rightLeftSidelightDecorativeGlassLeft = images[12];
        let leftSidelightDecorativeGlass = images[13];
        let rightLeftSidelightDecorativeGlassRight = images[14];
        let rightSidelightDecorativeGlass = images[15];
        let windowImage = images[16];
        let optionImage = images[17];
        let backgroundImage = images[18];



        if (handleImage) {
          let doorStackImage = new fabric.Group(
            [
              backgroundImage,
              // optionImage,
              ...(!includesNoGlass ? [optionImage] : []),
              ...(cutoutGlassCorriente4At1 ? [glassAt1] : []),
              ...(cutoutGlassCorriente5At1 ? [glassAt1] : []),
              ...(cutoutGlassCorriente4At2 ? [glassAt2] : []),
              ...(cutoutGlassCorriente5At2 ? [glassAt2] : []),
              ...(cutoutGlassCorriente4At1SSL ? [glassAt1] : []),
              ...(cutoutGlassCorriente5At1SSL ? [glassAt1] : []),
              ...(cutoutGlassCorriente4At2SSL ? [glassAt2] : []),
              ...(cutoutGlassCorriente5At2SSL ? [glassAt2] : []),
              ...(fullSidelightLeftAt123 ? [glassAt2] : []),
              ...(fullSidelightLeftAt123 ? [glassAt3] : []),
              ...(cutoutGlassAt2 ? [glassAt2] : []),
              ...(cutoutGlassAt3 ? [glassAt3] : []),
              ...(singleSidelightRLDoorTypeSidelightFull3AGlassAt123Glass1 ? [glassAt2] : []),
              ...(singleSidelightRLDoorTypeSidelightFull3AGlassAt123Glass2 ? [glassAt3] : []),
              ...(singleSidelightRLDoorTypeSidelight3AGlassAt123Glass1 ? [glassAt2] : []),
              ...(singleSidelightRLDoorTypeSidelight3AGlassAt123Glass2 ? [glassAt3] : []),
              ...(singleDoorLeftSidelight3AGlassAt123at1 ? [glassAt2] : []),
              ...(singleDoorLeftSidelight3AGlassAt123at2 ? [glassAt3] : []),
              ...(DoublecutoutGlassSidelightAt2 ? [glassAt2] : []),
              ...(DoublecutoutGlassSidelightAt3 ? [glassAt3] : []),
              ...(fullSidelightRightAt123 ? [glassAt2] : []),
              ...(fullSidelightRightAt123 ? [glassAt3] : []),
              ...(singleDoorRightSidelight3AGlassAt123at1 ? [glassAt2] : []),
              ...(singleDoorRightSidelight3AGlassAt123at2 ? [glassAt3] : []),
              ...(cutoutGlassCorriente4At3 ? [glassAt3] : []),
              ...(cutoutGlassCorriente5At3 ? [glassAt3] : []),
              ...(cutoutGlassCorriente5At4 ? [glassAt4] : []),
              ...(cutoutGlassCorriente4At3SSL ? [glassAt3] : []),
              ...(cutoutGlassCorriente5At3SSL ? [glassAt3] : []),
              ...(cutoutGlassCorriente5At4SSL ? [glassAt4] : []),
              ...(rightLeftSidelightLeft && !window.includes("SidelightFlat") && !window.includes("Sidelight3A")
                ? [rightLeftSidelightDecorativeGlassLeft]
                : []),
              ...(doubleRightLeftSidelightLeft && !window.includes("SidelightFlat") && !window.includes("Sidelight3A")
                ? [rightLeftSidelightDecorativeGlassLeft]
                : []),
              ...(leftSidelight && !window.includes("SidelightFlat") && !window.includes("Sidelight3A") ? [leftSidelightDecorativeGlass] : []),
              ...(doubleDoorTypeRight && !includesNoGlass
                ? [rightDecorativeGlass]
                : []),
              ...(doubleDoorTypeRightSidelightRL && !includesNoGlass
                ? [rightDecorativeGlass]
                : []),
              ...(doubleDoorTypeSidelightFull && !includesNoGlass ? [rightDecorativeGlass] : []),
              ...(DoublecutoutGlassAt1 ? [glassAt1DD] : []),
              ...(DoublecutoutGlassAt2 ? [glassAt2DD] : []),
              ...(DoublecutoutGlassAt3 ? [glassAt3DD] : []),
              ...(DoublecutoutGlassSidelightAt2 ? [glassAt2DD] : []),
              ...(DoublecutoutGlassSidelightAt3 ? [glassAt3DD] : []),
              ...(DoublecutoutGlassCorriente4At1 ? [glassAt2DD] : []),
              ...(DoublecutoutGlassCorriente4At2 ? [glassAt3DD] : []),
              ...(DoublecutoutGlassCorriente4At3 ? [glassAt4DD] : []),
              ...(DoublecutoutGlassCorriente5At1 ? [glassAt2DD] : []),
              ...(DoublecutoutGlassCorriente5At2 ? [glassAt3DD] : []),
              ...(DoublecutoutGlassCorriente5At3 ? [glassAt4DD] : []),
              ...(DoublecutoutGlassCorriente5At4 ? [glassAt5DD] : []),
              ...(cutoutGlassCorriente4At1DSL ? [glassAt2DD] : []),
              ...(cutoutGlassCorriente4At2DSL ? [glassAt3DD] : []),
              ...(cutoutGlassCorriente4At3DSL ? [glassAt4DD] : []),
              ...(cutoutGlassCorriente5At1DSL ? [glassAt2DD] : []),
              ...(cutoutGlassCorriente5At2DSL ? [glassAt3DD] : []),
              ...(cutoutGlassCorriente5At3DSL ? [glassAt4DD] : []),
              ...(cutoutGlassCorriente5At4DSL ? [glassAt5DD] : []),
              ...(rightSidelight && !window.includes("SidelightFlat") && !window.includes("Sidelight3A")
                ? [rightSidelightDecorativeGlass]
                : []),
              ...(rightLeftSidelightRight && !window.includes("SidelightFlat") && !window.includes("Sidelight3A")
                ? [rightLeftSidelightDecorativeGlassRight]
                : []),
              ...(doubleRightLeftSidelightRight && !window.includes("SidelightFlat") && !window.includes("Sidelight3A")
                ? [rightLeftSidelightDecorativeGlassRight]
                : []),
              windowImage,
              handleImage,
              silImage,
            ],
            {
              doorStackImageId: uuid(),
              groupId: uuid(),
              dirty: true,
              objectCaching: false,
              selectable: true,
              itemUrl: window,
              itemColor: dfItemColor,
              windowType: tName,
              preserveObjectStacking: true,

             // Use AI-detected coordinates if applicable
              ...(properties.isAICall ? {} : { originX: "center" }),
              ...(properties.isAICall && properties.top !== undefined ? { top: properties.top } : { top: 100 }),
              ...(properties.isAICall && properties.left !== undefined ? { left: properties.left } : { left: canvas.getWidth() / 2 }),

              // Set width and height based on AI detection if available
              ...(properties.isAICall && properties.width !== undefined ? { width: properties.width } : {}),
              ...(properties.isAICall && properties.height !== undefined ? { height: properties.height } : {}),

              // Set scaleX and scaleY using the calculated scaling factor
              scaleX: properties.isAICall ? 1 : 0.2, // Default scaling factor for non-AI cases
              scaleY: properties.isAICall ? 1 : 0.2, // Maintain proportionality with AI scaling
              usePerspective: true, // Preserve perspective if required
            }
          );

          getPsptFabricObj(canvas, doorStackImage).then((poly) => {
            canvas.discardActiveObject().renderAll();
            canvas.add(poly);
            poly.itemColor = dfItemColor;
            poly.itemUrl = window;
            poly.windowType = tName;
            canvas.bringToFront(poly);
            if (callbackFn) callbackFn(retProps);
            canvas.setActiveObject(poly);
            if (canvas.isPerspective) {
              doorStackImage.visible = false;
              canvas.add(doorStackImage);
            }

          });

          // if (properties.isAICall) {
          //   adjustDecorativeGlassForAi(doorStackImage, properties);
          //   canvas.renderAll();
          // }


          // Properties object contains detected pixel dimensions
          const objectPixelWidth = properties.width;
          const objectPixelHeight = properties.height;


          const realWidthFactor = realScaleFactor;

          const realWidth = (objectPixelWidth / realWidthFactor).toFixed(1);
          const realHeight = (objectPixelHeight / realWidthFactor).toFixed(1);

          const textInfo = `W: ${realWidth} in, H: ${realHeight} in`; // Display in inches

          // Calculate the position for the text and its background
          const textLeft = doorStackImage.left;
          const textTop = doorStackImage.top - doorStackImage.height * doorStackImage.scaleY / 2 - 30; // Adjust as necessary

          // Create a Fabric.js text object for this information
          const textObject = new fabric.Text(textInfo, {
            fontSize: 12,
            fill: 'black',
            fontFamily: 'Arial',
            left: textLeft,
            top: textTop,
          });

          // Measure text to create a background rectangle
          const textWidth = textObject.width + 10; // Adding some padding
          const textHeight = textObject.height + 10; // Adding some padding

          // Create a rectangle to serve as the background
          const backgroundRect = new fabric.Rect({
            width: textWidth,
            height: textHeight,
            fill: 'yellow',
            left: textLeft - 5, // Slightly offset to center behind the text
            top: textTop - 5, // Slightly offset above the text
            rx: 5, // Optional: add rounded corners
            ry: 5, // Optional: add rounded corners
          });

          // Group the text and background so they move together
          const textWithBackground = new fabric.Group([backgroundRect, textObject], {
            left: textLeft,
            top: textTop,
            isDimension: true, // custom attribute to mark dimension objects
            selectable: false, // Make the object unselectable
          });

          // Add the grouped text and background to the canvas
          if (!properties.designerTriggered && properties.isAICall && properties.isDimensionsVisible) { canvas.add(textWithBackground); canvas.bringToFront(textWithBackground);}
          saveCanvasState(canvas);
          canvas.renderAll();
          // The rest of your existing code...
        }


      })
      .catch((error) => {
        console.error("Error loading images:", error);
      });
  }
}


// we add a standard background
function findBackground(tName) {
  const itemName = tName;

  let tempBg = itemsBackgroundsArray.filter((item) =>
    item.name.includes(itemName)
  );
  backgroundUrl = tempBg[0].bgUrl;
}
// we add a handle to the door
function findHandle(tName) {
  let handleToFind = tName.replaceAll("_", " ");

  let tempDoorHandle = handlesAndSilsArray.filter((item) =>
    item.name.includes(handleToFind)
  );
  handleUrl = tempDoorHandle[0].handleUrl;
}

// we add a door sil to the door
function findSil(tName, window) {
  if (!window.includes("SidelightFull")) {
    let silToFind = tName.replaceAll("_", " ");

    let tempDoorSil = handlesAndSilsArray.filter((item) =>
      item.name.includes(silToFind)
    );
    silUrl = tempDoorSil[0].silUrl;
  } else {
    let silToFind = tName.replaceAll("_", " ") + " Full";

    let tempDoorSil = handlesAndSilsArray.filter((item) =>
      item.name.includes(silToFind)
    );
    silUrl = tempDoorSil[0].silUrl;
  }
}
