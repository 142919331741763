
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { storage, db } from "../../base";
import { doc, deleteDoc, collection, query, where, getDocs, orderBy, startAfter, limit } from "firebase/firestore";
import { ref, listAll, deleteObject, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { Modal, Button, Table, Nav, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { saveAs } from 'file-saver';

const AdminModal = () => {
  const [showAdminModal, setShowAdminModal] = useState(false);

  const [oldProjects, setOldProjects] = useState([]);
  const [totalOldProjects, setTotalOldProjects] = useState(0);
  const [deletedProjectIds, setDeletedProjectIds] = useState([]);
  const [lastDoc, setLastDoc] = useState(null); // To track pagination

  const { t } = useTranslation(["translation"]);
  const [orphanedFiles, setOrphanedFiles] = useState([]);
  const [deletedOrphanedFiles, setDeletedOrphanedFiles] = useState([]);
  const [userId, setUserId] = useState('');
const [projectName, setProjectName] = useState('');
const [selectedFile, setSelectedFile] = useState(null);




  // Toggle Admin Modal
  const toggleAdminModal = () => {
    setShowAdminModal(!showAdminModal);
  };


   // Find Orphaned Files in Firebase Storage
   const findOrphanedFiles = async () => {

    try {
      // 1. Get all project entries from Firestore
      const projectsRef = collection(db, "Projects");

      const projectsSnapshot = await getDocs(projectsRef);
      const validImageNames = new Set();

      projectsSnapshot.forEach(doc => {
        const projectData = doc.data();
        const imageName = projectData.imageName;

        if (imageName) {
          validImageNames.add(imageName);
        }
      });

      // 2. List all files in Firebase Storage
      const listAllFiles = async (path) => {
        const folderRef = ref(storage, path);
        const { items, prefixes } = await listAll(folderRef);

        let files = items.map(itemRef => itemRef.fullPath);

        for (const prefix of prefixes) {
          files = files.concat(await listAllFiles(prefix.fullPath));
        }

        return files;
      };

      const allFiles = await listAllFiles('usersProjectsImages');

      // 3. Identify orphaned files
      const orphanedFilesList = allFiles.filter(file => {
        const fileNameWithoutExtension = file.split('/').pop().split(/_(webp|resized|treated)?\.png|\.webp/)[0];
        return !validImageNames.has(fileNameWithoutExtension);
      });

      setOrphanedFiles(orphanedFilesList);

      toast.success(`Found ${orphanedFilesList.length} orphaned files.`);
    } catch (error) {
      console.error("Error finding orphaned files:", error);
      toast.error("Error finding orphaned files");
    }
  };

  // Delete Orphaned Files
  const deleteOrphanedFiles = async () => {
    if (orphanedFiles.length === 0) {
      toast.info("No orphaned files to delete.");
      return;
    }

    const batchToDelete = orphanedFiles.splice(0, 100); // Delete files in batches
    const deletePromises = batchToDelete.map(async (filePath) => {
      const fileRef = ref(storage, filePath);
      await deleteObject(fileRef);
      setDeletedOrphanedFiles(prev => [...prev, filePath]);
    });

    try {
      await Promise.all(deletePromises);

      setOrphanedFiles([...orphanedFiles]); // Update the state with remaining orphaned files

      if (orphanedFiles.length > 0) {
        toast.info(`Deleted batch. ${orphanedFiles.length} orphaned files left to delete.`);
      } else {
        saveDeletedOrphanedFiles();
        toast.success("All orphaned files have been deleted.");
      }
    } catch (error) {
      console.error("Error deleting orphaned files:", error);
      toast.error("Error deleting orphaned files");
    }
  };

  // Save deleted orphaned files to a file
  const saveDeletedOrphanedFiles = () => {
    const blob = new Blob([JSON.stringify(deletedOrphanedFiles, null, 2)], { type: "application/json" });
    saveAs(blob, "deletedOrphanedFiles.json");
    toast.success("Deleted orphaned files saved to file.");
  };

  // Find Projects with null or missing savedTimestamp
  const findProjectsWithNullOrMissingTimestamp = async () => {
    try {
      const projectsRef = collection(db, "Projects");

      let q = query(
        projectsRef,
        where("savedTimestamp", "==", null),
        limit(10)
      );

      if (lastDoc) {
        q = query(q, startAfter(lastDoc)); // Continue after the last document for pagination
      }

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const projectsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setOldProjects((prev) => [...prev, ...projectsList]);
        setTotalOldProjects((prev) => prev + projectsList.length);
        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]); // Update lastDoc for pagination

        toast.success(`Found ${projectsList.length} projects with null or missing savedTimestamp.`);
      } else {
        toast.info("No more projects with null or missing savedTimestamp found.");
      }
    } catch (error) {
      console.error("Error finding projects with null or missing savedTimestamp:", error);
      toast.error("Error finding projects with null or missing savedTimestamp");
    }
  };

  // Delete Project Images and Document
  const deleteProjectImagesAndDocument = async (project) => {
    const { projectName, userId } = project;
    const projectPaths = [
      `usersProjectsImages/${userId}/canvasImages/${projectName}_${userId}`,
      `usersProjectsImages/${userId}/canvasImages/canvasImagesResized/${projectName}_${userId}_resized.png`,
      `usersProjectsImages/${userId}/canvasImages/canvasImagesTreated/${projectName}_${userId}.png`,
      `usersProjectsImages/${userId}/canvasImages/canvasImagesWebP/${projectName}_${userId}_webp.webp`,
    ];

    try {
      // Delete images from storage
      const deleteImagePromises = projectPaths.map((path) => {
        const imageRef = ref(storage, path);
        return deleteObject(imageRef).catch((error) => {
          console.warn(`Error deleting image at ${path}:`, error);
        });
      });
      await Promise.all(deleteImagePromises);

      // Delete project document from Firestore
      const projectDocRef = doc(db, "Projects", project.id);
      await deleteDoc(projectDocRef);

      console.log(`Deleted project: ${project.id}`);
      setDeletedProjectIds((prev) => [...prev, project.id]);
      toast.success(`Deleted project: ${project.id}`);
    } catch (error) {
      console.error("Error deleting project and images:", error);
      toast.error("Error deleting project and images");
    }
  };

  // Batch Delete Projects with Null or Missing Timestamp
  const deleteProjectsBatch = async () => {
    if (oldProjects.length === 0) {
      toast.info("No projects to delete.");
      return;
    }

    const batchToDelete = oldProjects.splice(0, 10); // Take the first 10 projects to delete
    const deletePromises = batchToDelete.map((project) => deleteProjectImagesAndDocument(project));

    try {
      await Promise.all(deletePromises);

      setTotalOldProjects((prev) => prev - batchToDelete.length);
      setOldProjects([...oldProjects]); // Update the state with the remaining projects

      if (oldProjects.length > 0) {
        toast.info(`Deleted batch. ${oldProjects.length} projects left to delete.`);
      } else {
        saveDeletedProjectIds();
        toast.success("All projects with null or missing savedTimestamp have been deleted.");
      }
    } catch (error) {
      console.error("Error deleting projects batch:", error);
      toast.error("Error deleting projects batch");
    }
  };

  // Save deleted project IDs to a file
  const saveDeletedProjectIds = () => {
    const blob = new Blob([JSON.stringify(deletedProjectIds, null, 2)], { type: "application/json" });
    saveAs(blob, "deletedProjectIds.json");
    toast.success("Deleted project IDs saved to file.");
  };

  const uploadFileForUserProject = (file, userId, projectName) => {
    return new Promise((resolve, reject) => {
      if (!file || !userId || !projectName) {
        console.error('File, userId, or projectName is undefined');
        reject(new Error('File, userId, or projectName is undefined'));
        return;
      }
  
      const imageRef = ref(storage, `usersProjectsImages/${userId}/canvasImages/${projectName.replaceAll(" ", "_")}_${userId}`);
      const uploadTask = uploadBytesResumable(imageRef, file);
  
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          if (snapshot.totalBytes > 0) {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            console.log(`Upload is ${progress}% done`);
          } else {
            console.warn('Total bytes is zero');
          }
        },
        (error) => {
          console.error('Error uploading file:', error);
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((url) => {
              console.log('File available at', url);
              resolve(url);
            })
            .catch((error) => {
              console.error('Error getting download URL:', error);
              reject(error);
            });
        }
      );
    });
  };
  


  return (
    <>
      <Button onClick={toggleAdminModal}>{t('admin')}</Button>

      <Modal
        show={showAdminModal}
        onHide={toggleAdminModal}
        dialogClassName="admin-modal"
        contentClassName="admin-modal-content"
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('adminPanel')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container id="admin-tabs" defaultActiveKey="userManagement">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="userManagement">{t('userManagement')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="bannerManagement">{t('bannerManagement')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="projectManagement">{t('projectManagement')}</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>

              {/* Project Management */}
              <Tab.Pane eventKey="projectManagement">
  <Button onClick={findOrphanedFiles} className="mt-3">{t('Find Orphaned Files')}</Button>
  <Button onClick={deleteOrphanedFiles} className="mt-3">{t('Delete Next Batch')}</Button>

  <h5 className="mt-4">{t('Orphaned Files')}</h5>
  {orphanedFiles.length > 0 ? (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>{t('filePath')}</th>
        </tr>
      </thead>
      <tbody>
        {orphanedFiles.map((filePath, index) => (
          <tr key={index}>
            <td>{filePath}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : (
    <p>{t('No orphaned files found.')}</p>
  )}

  {/* New section for uploading files */}
  <h5 className="mt-4">{t('Upload File for Specific User and Project')}</h5>
  <div className="mb-3">
    <label>{t('User ID')}</label>
    <input
      type="text"
      className="form-control"
      value={userId}
      onChange={(e) => setUserId(e.target.value)}
    />
  </div>
  <div className="mb-3">
    <label>{t('Project Name')}</label>
    <input
      type="text"
      className="form-control"
      value={projectName}
      onChange={(e) => setProjectName(e.target.value)}
    />
  </div>
  <div className="mb-3">
    <label>{t('Select File')}</label>
    <input
      type="file"
      className="form-control"
      onChange={(e) => setSelectedFile(e.target.files[0])}
    />
  </div>
  <Button
    onClick={() => {
      if (selectedFile && userId && projectName) {
        uploadFileForUserProject(selectedFile, userId, projectName)
          .then((url) => {
            toast.success('File uploaded successfully!');
            console.log('File URL:', url);
          })
          .catch((error) => {
            toast.error('Error uploading file');
            console.error(error);
          });
      } else {
        toast.error('Please fill in all fields and select a file');
      }
    }}
  >
    {t('Upload File')}
  </Button>
</Tab.Pane>

            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleAdminModal}>
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminModal;
