import React, { useState, useMemo, useEffect } from "react";
import Tippy from "@tippyjs/react";
import { SketchPicker, GithubPicker } from "react-color";
import "tippy.js/dist/tippy.css";
import "../index.css";
import "bootstrap/dist/css/bootstrap.css";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useTranslation } from "react-i18next";
import { useAuth, db } from "../base"; // import your Firebase config file
import { doc, updateDoc, getDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import EyeDropperComponent from "./eyeDropper/EyeDropperComponent";


function ItemColors(props) {
  const { t, i18n } = useTranslation(["translation"]);
  const [pickedColor, setPickedColor] = useState("");
  const [selectedColor, setSelectedColor] = useState('#ffffff');
  const [colorName, setColorName] = useState("");
  const [showNamePrompt, setShowNamePrompt] = useState(false);

  const currentUser = useAuth(); // Get the current logged-in user

  const presetColors = useMemo(() => {
    return [
      { color: "rgba(0, 0, 0, 1)", title: t("itemColor-black") },
      { color: "rgba(255, 255, 255, 1)", title: t("itemColor-brightWhite") },
      { color: "rgba(151, 152, 247, 1)", title: t("itemColor-softWhite") },
      { color: "rgba(225, 233, 236, 1)", title: t("itemColor-iceWhite") },
      { color: "rgba(255, 243, 200, 1)", title: t("itemColor-sandstone") },
      { color: "rgba(239, 216, 168, 1)", title: t("itemColor-cashmere") },
      { color: "rgba(251, 239, 181, 1)", title: t("itemColor-maize") },
      { color: "rgba(219, 199, 164, 1)", title: t("itemColor-antiqueIvory") },
      { color: "rgba(216, 181, 140, 1)", title: t("itemColor-sandalwood") },
      { color: "rgba(248, 231, 157, 1)", title: t("itemColor-almond") },
      { color: "rgba(216, 180, 120, 1)", title: t("itemColor-wicker") },
      { color: "rgba(215, 183, 116, 1)", title: t("itemColor-canyonClay") },
      { color: "rgba(191, 155, 107, 1)", title: t("itemColor-harvestWheat") },
      { color: "rgba(183, 146, 102, 1)", title: t("itemColor-pebble") },
      { color: "rgba(216, 163, 95, 1)", title: t("itemColor-brownstone") },
      { color: "rgba(187, 165, 144, 1)", title: t("itemColor-tan") },
      { color: "rgba(147, 129, 107, 1)", title: t("itemColor-darkDrift") },
      { color: "rgba(139, 113, 96, 1)", title: t("itemColor-saddleBrown") },
      { color: "rgba(136, 71, 51, 1)", title: t("itemColor-venetianRed") },
      { color: "rgba(166, 172, 158, 1)", title: t("itemColor-sage") },
      { color: "rgba(79, 101, 99, 1)", title: t("itemColor-ivyGreen") },
      { color: "rgba(93, 106, 89, 1)", title: t("itemColor-moonlitMoss") },
      { color: "rgba(2, 66, 14, 1)", title: t("itemColor-forestGreen") },
      { color: "rgba(94, 114, 125, 1)", title: t("itemColor-wedgewoodBlue") },
      { color: "rgba(103, 113, 122, 1)", title: t("itemColor-midnightSurf") },
      { color: "rgba(32, 49, 67, 1)", title: t("itemColor-oldWorldBlue") },
      { color: "rgba(167, 169, 168, 1)", title: t("itemColor-doverGray") },
      { color: "rgba(133, 126, 118, 1)", title: t("itemColor-storm") },
      { color: "rgba(118, 102, 76, 1)", title: t("itemColor-slate") },
      { color: "rgba(90, 87, 80, 1)", title: t("itemColor-windsweptSmoke") },
      { color: "rgba(158, 85, 17, 1)", title: t("itemColor-aluCopper") },
      { color: "rgba(77, 45, 24, 1)", title: t("itemColor-nutmeg") },
      { color: "rgba(71, 40, 29, 1)", title: t("itemColor-chestnutBrown") },
      { color: "rgba(75, 54, 32, 1)", title: t("itemColor-commercialBrown") },
      { color: "rgba(118, 40, 37, 1)", title: t("itemColor-burgundy") },
      { color: "rgba(103, 97, 85, 1)", title: t("itemColor-fineSand") },
      { color: "rgba(58, 50, 48, 1)", title: t("itemColor-antiqueBrown") },
      { color: "rgba(131, 122, 105, 1)", title: t("itemColor-windowBronze") },
      { color: "rgba(185, 179, 158, 1)", title: t("itemColor-aluminium") },
      { color: "rgba(63, 59, 47, 1)", title: t("itemColor-ironOre") },
      { color: "rgba(83, 89, 85, 1)", title: t("itemColor-graphite") },
      { color: "rgba(52, 61, 65, 1)", title: t("itemColor-anthracite") },
      { color: "rgba(102, 84, 60, 1)", title: t("itemColor-espresso") },
      { color: "rgba(126, 128, 89, 1)", title: t("itemColor-mountainArbor") },
      { color: "rgba(116, 135, 150, 1)", title: t("itemColor-rockwellBlue") },
      { color: "rgba(56, 94, 115, 1)", title: t("itemColor-coastalBlue") },
    ];
  }, [i18n.language]);

  const fenplastColors = useMemo(() => {
    return [
      { color: "rgba(255, 255, 255, 1)", title: t("itemColor-standardWhite") },
      { color: "rgba(248, 231, 157, 1)", title: t("itemColor-almond") },
      { color: "rgba(215, 183, 116, 1)", title: t("itemColor-canyonClay") },
      { color: "rgba(216, 163, 95, 1)", title: t("itemColor-brownstone") },
      { color: "rgba(118, 40, 37, 1)", title: t("itemColor-burgundy") },
      { color: "rgba(75, 54, 32, 1)", title: t("itemColor-commercialBrown") },
      { color: "rgba(71, 40, 29, 1)", title: t("itemColor-chestnutBrown") },
      { color: "rgba(77, 45, 24, 1)", title: t("itemColor-nutmeg") },
      { color: "rgba(187, 174, 155, 1)", title: t("itemColor-cashmere") },
      { color: "rgba(216, 180, 120, 1)", title: t("itemColor-wicker") },
      { color: "rgba(197, 102, 2, 1)", title: t("itemColor-cedar") },
      { color: "rgba(118, 102, 76, 1)", title: t("itemColor-slate") },
      { color: "rgba(240, 213, 152, 1)", title: t("itemColor-cream") },
      { color: "rgba(185, 179, 158, 1)", title: t("itemColor-anodizedGrey") },
      { color: "rgba(251, 239, 181, 1)", title: t("itemColor-maize") },
      { color: "rgba(183, 146, 102, 1)", title: t("itemColor-pebble") },
      { color: "rgba(63, 59, 47, 1)", title: t("itemColor-ironOre") },
      { color: "rgba(0, 0, 0, 1)", title: t("itemColor-black") },
      { color: "rgba(103, 39, 14, 1)", title: t("itemColor-countryRed") },
      { color: "rgba(156, 49, 54, 1)", title: t("itemColor-brickRed") },
      { color: "rgba(166, 47, 32, 1)", title: t("itemColor-autumnRed") },
      { color: "rgba(196, 30, 58, 1)", title: t("itemColor-classicRed") },
      { color: "rgba(156, 49, 54, 1)", title: t("itemColor-brickRed") },
      { color: "rgba(216, 181, 140, 1)", title: t("itemColor-sandalwood") },
      { color: "rgba(122, 99, 76, 1)", title: t("itemColor-sable") },
      { color: "rgba(138, 92, 54, 1)", title: t("itemColor-woodSierra") },
      { color: "rgba(255, 243, 200, 1)", title: t("itemColor-sandstone") },
      { color: "rgba(2, 66, 14, 1)", title: t("itemColor-forestGreen") },
    ];
  }, [i18n.language]);

  const gitHubColorSelection = [
    "#000000",
    "#ffffff",
    "#9798f7",
    "#e1e9ec",
    "#fff3c8",
    "#efd8a8",
    "#fbefb5",
    "#dbc7a4",
    "#d8b58c",
    "#f8e79d",
    "#d8b478",
    "#d7b774",
    "#bf9b6b",
    "#b79266",
    "#d8a35f",
    "#bba590",
    "#93816b",
    "#8b7160",
    "#884733",
    "#a6ac9e",
    "#4f6563",
    "#5d6a59",
    "#02420e",
    "#5e727d",
    "#67717a",
    "#203143",
    "#a7a9a8",
    "#857e76",
    "#76664c",
    "#5a574d",
    "#9e5511",
    "#4d2d18",
    "#472819",
    "#4b3620",
    "#762825",
    "#676155",
    "#3a322f",
    "#837a69",
    "#b9b39e",
    "#3f3b2f",
    "#535955",
    "#66543c",
    "#7e8059",
    "#748796",
    "#385e73"
  ];

  const [colorSelection, setColorSelection] = useState("");
  // You can use this colorDefinitions array in your code.

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 428);
  const [customColors, setCustomColors] = useState([]);
  const [isCustomColorsVisible, setIsCustomColorsVisible] = useState(false);


  const buttonClassName = props.parent === "EraserDraw" ? "colorButton-eraserDraw" : "btn mt-1 btn-block pb-0 m-0";

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 428);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {

    const fetchCustomColors = async () => {
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          setCustomColors(userDocSnap.data().customColors || []);
        } else {
          console.log("No custom colors found.");
        }
      }
    };

    fetchCustomColors();
    // setSelectedColor(props.pickerColor)
  }, [selectedColor, currentUser]);

  const toggleCustomColorsVisibility = () => {
    setIsCustomColorsVisible(!isCustomColorsVisible);
  };

  const saveColorToFirebase = async (pickedColor) => {
    if (currentUser) {
      // Reference to the user's document in Firestore
      const userDocRef = doc(db, 'users', currentUser.uid);

      try {
        await updateDoc(userDocRef, {
          customColors: arrayUnion(pickedColor) // Append the new color to customColors array
        });
        console.log("Color saved to Firestore");
      } catch (error) {
        console.error("Error saving color to Firestore: ", error);
      }
    } else {
      console.error("No user is logged in.");
    }
  };

  const onEyeDropperColorSelect = (color) => {
    // Assuming 'color' is the color selected by EyeDropper in hex format
    setPickedColor(color);
    // saveColorToFirebase(color);
    // Update the SketchPicker by setting the selected color
    setSelectedColor(color);
    setShowNamePrompt(true);
    setIsCustomColorsVisible(true);
  };

  const handleCustomColorSelect = (colorObject) => {
    setSelectedColor(colorObject.color);
    handleColorChange({ hex: colorObject.color }); // Or structure it as needed
  };

  const handleColorChange = (color) => {
    setColorSelection(color.hex);
    // Call the updateColor function if provided
    if (props.updateColor) {
      props.updateColor(color.hex);
    }

    // Call the generic handleColor function if provided
    if (props.handleColor) {
      props.handleColor(color);
    }
  };



  const handleColorNameSubmit = (e) => {

    e.preventDefault(); // Prevent form submission from reloading the page
    const colorObject = {
      color: pickedColor,
      title: `Custom - ${colorName}` // Make sure to give a title to your color
    };
    saveColorToFirebase(colorObject);
    setSelectedColor(colorObject.color); // Set the selected color
    // Add the new color object to the customColors state array
    setCustomColors([...customColors, colorObject]);
    // Hide the name prompt
    setShowNamePrompt(false);
    setColorName(""); // Reset the color name input
  };

  const deleteColorFromFirebase = async (colorObject) => {
    if (currentUser) {
      // Reference to the user's document in Firestore
      const userDocRef = doc(db, 'users', currentUser.uid);

      try {
        await updateDoc(userDocRef, {
          // Use arrayRemove to delete the color from the customColors array
          customColors: arrayRemove(colorObject)
        });
        // Update local state
        setCustomColors(customColors.filter(item => item.color !== colorObject.color));
        console.log("Color removed from Firestore");
      } catch (error) {
        console.error("Error removing color from Firestore: ", error);
      }
    } else {
      console.error("No user is logged in.");
    }
  };



  return (
    <div>

      <Tippy
        interactive={true}
        placement={"left"}
        trigger="click"
        content={
          <div className="sketch-picker-container">
            <SketchPicker
              disableAlpha={true}
              presetColors={presetColors} // Only default colors
              color={colorSelection}
              onChangeComplete={handleColorChange}
            />

            {showNamePrompt && (
              <div className="customColorName-container">
                <form onSubmit={handleColorNameSubmit} style={{ width: '100%' }}>
                  <input
                    className="customColorName"
                    type="text"
                    value={colorName}
                    onChange={(e) => setColorName(e.target.value)}
                    placeholder={t("itemColor-entercolorname")}
                    required
                  />
                  <button type="submit">{t("itemColor-save")}</button>
                </form>
              </div>
            )}
            <h5 onClick={toggleCustomColorsVisibility}
              style={{ width: 'auto', marginTop: "5px", cursor: 'pointer', fontSize: 'medium', color: "#4CAF50" }}>
              {t("itemColor-customcolors")}
            </h5>
            {isCustomColorsVisible && (
              <div className="custom-colors-section">
                {customColors.map((colorObject, index) => (
                  <div key={index} className="custom-color-entry">
                    <div
                      className="color-swatch"
                      style={{ backgroundColor: colorObject.color }}
                      onClick={() => handleCustomColorSelect(colorObject)}
                      title={colorObject.title}
                    />
                    <span className="color-title">
                      {colorObject.title.replace("Custom - ", "")}
                    </span>
                    <span
                      className="delete-color-x"
                      onClick={() => deleteColorFromFirebase(colorObject)}
                      aria-label={t("itemColor-delete")}
                      style={{ width: 'auto' /* or any specific width */, cursor: 'pointer', color: "white", padding: '0 2px', fontSize: 'larger' /* other styles */ }}
                    >
                      X
                    </span>
                  </div>
                ))}
              </div>
            )}

            <EyeDropperComponent onColorSelected={onEyeDropperColorSelect} />

          </div>
        }
      >
        <DropdownButton
          title={
            <img
              className={"colorButton"}
              src="./icons/colorBucketButton.png"
              height="40px"
              alt="Color"
              title={t("itemColor-color")}
            />
          }
          className={buttonClassName}
          id="dropdown-button-dark"
          variant="secondary"
          size="sm"
          menuVariant="dark"
          drop="end"
          show={false} // Initialize show to false
          onToggle={(isOpen, event) => {
            // If the event is a click, prevent the dropdown from opening
            if (event.type === "click") {
              event.preventDefault();
            }
          }}
        ></DropdownButton>

      </Tippy>

    </div>
  );
}

export default ItemColors;
