import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';  // Import i18n translation hook
import "../homepage/home.css";

const WelcomeVideoModal = ({ isInline }) => {
  const [isModalOpen, setIsModalOpen] = useState(true);  // Control modal visibility
  const videoRef = useRef(null);
  const { i18n } = useTranslation();  // Access i18n instance

  // Determine the welcome video file based on the current language
  const welcomeVideoSrc = i18n.language === 'fr' 
    ? "/howToVideos/Facade_Designer_intro_fr.mp4" 
    : "/howToVideos/Facade_Designer_intro_en.mp4";

  const handleSkipVideo = () => {
    setIsModalOpen(false);
    localStorage.setItem('hasSeenVideo', 'true');  // Set localStorage flag
  };

  if (!isModalOpen) return null;

  return (
    <div className={isInline ? "" : "welcome-video-overlay"}>
      <div className={isInline ? "" : "welcome-video-content"}>
        <video
          ref={videoRef}
          src={welcomeVideoSrc}
          autoPlay
          muted
          controls
          style={{ width: '100%', height: 'auto' }}
        ></video>

        {!isInline && (
          <button 
            className="skip-button" 
            onClick={handleSkipVideo} 
            style={{
              marginTop: '10px',
              padding: '10px 20px',
              backgroundColor: '#4caf50',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Skip
          </button>
        )}
      </div>
    </div>
  );
};

export default WelcomeVideoModal;
